import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { get_data_all, extract_price_detail } from '../components/Fetch_All_data.js';
import { ProductContaner } from '../components/ProductDetail_Comp.jsx';
import FadeIn from "react-lazyload-fadein";
import { Oval } from 'react-loader-spinner';
import productnotfound from '../assets/img/productnotfound.png';
import { sleeves_list, fit_list, color_list, ocacsion_list, pattern_List, frabic_List, price_list } from '../components/product_data.js';
import { other_action } from '../assets/product.js';
class NewArrivals extends Component {
   constructor(props) {
      super(props);
      this.state = {
         error: null,
         prevY: 0,
         loading: false,
         call_product_loader: false,
         products: [],
         main_category: [],
         app_token: localStorage.getItem('app_token'),
         category_isLoaded: false,
         c_p_isLoaded: false,
         newStartFrom: 0,
         startFrom: 0,
         pb1_image: [],
         pb2_image: [],
         products_brand: [],
         pb1_status: false,
         pb2_status: false,
         p_b_isLoaded: false,
         sleeves_lists: [],
         fit_lists: [],
         color_lists: [],
         ocacsion_lists: [],
         pattern_Lists: [],
         frabic_Lists: [],
         price_lists: [],
      };
      this.onChange = this.onChange.bind(this);
      if (props.match.isExact == true) {

      }
   }
   async load_category_product(startFrom) {
      var main_cat = [];
      var sub_cat = [];
      var child_cat = [];
      var color = [];
      var sleeves = [];
      var frabic = [];
      var pattern = [];
      var ocacsion = [];
      var fiting = [];
      var max = 0;
      var min = 0;
      if (startFrom == 0) {
         this.setState({ products: [], c_p_isLoaded: false });
      }
      this.state.main_category.map(item => {
         if (item.checked) {
            main_cat.push(item.id);
         }
         item.sub_category.map(item2 => {
            if (item2.checked) {
               sub_cat.push(item2.id);
            }
            item2.child_category.map(item3 => {
               if (item3.checked) {
                  child_cat.push(item3.id);
               }
            });
         });
      })
      this.state.color_lists.map(item => {
         if (item.checked) {
            color.push(item.id);
         }
      });
      this.state.price_lists.map(item => {
         if (item.checked) {
            min = item.start;
            max = item.end;
         }
      });
      this.state.sleeves_lists.map(item => {
         if (item.checked) {
            sleeves.push(item.id);
         }
      });
      this.state.fit_lists.map(item => {
         if (item.checked) {
            fiting.push(item.id);
         }
      });
      this.state.ocacsion_lists.map(item => {
         if (item.checked) {
            ocacsion.push(item.id);
         }
      });
      this.state.pattern_Lists.map(item => {
         if (item.checked) {
            pattern.push(item.id);
         }
      });
      this.state.frabic_Lists.map(item => {
         if (item.checked) {
            frabic.push(item.id);
         }
      });
      let input_param = {
         'main_cat': main_cat, 'sub_cat': sub_cat, 'child_cat': child_cat, 'color': color, 'sleeves': sleeves,
         'fiting': fiting, 'ocacsion': ocacsion, 'pattern': pattern, 'frabic': frabic, 'min': min, 'max': max,
         'm_u_token': localStorage.getItem('app_token'), 'page': startFrom, 'offer_has': "no"
      };
      await get_data_all('m/fetch_product_variation_list', input_param)
         .then(
            (resolve) => {
               if (resolve['status'] == 1 && startFrom == 0) {
                  this.setState({
                     products: resolve['data'],
                     c_p_isLoaded: true, newStartFrom: resolve['page'],
                     startFrom: startFrom, call_product_loader: true
                  });
               }
               else if (resolve['status'] == 1 && startFrom > 0) {
                  this.setState({
                     products: [...this.state.products, ...resolve['data']],
                     c_p_isLoaded: true, newStartFrom: resolve['page'],
                     startFrom: startFrom, call_product_loader: true
                  });
               }
               else {
                  this.setState({
                     c_p_isnotAvailable: true, call_product_loader: false
                  });
               }
            },
            (reject) => {
               console.log(reject)
            }
         )
   }
   load_all_category() {
      get_data_all("m/product-all-category", [])
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  this.setState({
                     main_category: resolve['data'],
                     category_isLoaded: true,
                  });
               }
            },
            (reject) => {
               console.log(reject)
            }
         )
   }
   get_product_brand = () => {
      get_data_all('m/get_product_brand', {})
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  this.setState({
                     products_brand: resolve['data'],
                     p_b_isLoaded: true,
                  });
               }
               else {
                  this.setState({
                     p_b_isLoaded: false,
                  });
               }
            },
            (reject) => {
               console.log(reject)
            }
         )
   }

   all_category_product = (e, type, group_type) => {
      this.setState({
         c_p_isnotAvailable: false, call_product_loader: false
      });
      if (group_type == 'color' && type == 2) {
         this.setState({ color_lists: this.state.color_lists.map((item) => { return e.target.value === item.id ? { ...item, checked: e.target.checked } : item }) });
      }
      else if (group_type == 'color' && type == 1) {
         this.setState({ color_lists: this.state.color_lists.map((item) => { return { ...item, checked: e.target.checked } }) });
      }
      else if (group_type == 'pattern' && type == 2) {
         this.setState({ pattern_Lists: this.state.pattern_Lists.map((item) => { return e.target.value === item.id ? { ...item, checked: e.target.checked } : item }) });
      }
      else if (group_type == 'pattern' && type == 1) {
         this.setState({ pattern_Lists: this.state.pattern_Lists.map((item) => { return { ...item, checked: e.target.checked } }) });
      }
      else if (group_type == 'frabic' && type == 2) {
         this.setState({ frabic_Lists: this.state.frabic_Lists.map((item) => { return e.target.value === item.id ? { ...item, checked: e.target.checked } : item }) });
      }
      else if (group_type == 'frabic' && type == 1) {
         this.setState({ frabic_Lists: this.state.frabic_Lists.map((item) => { return { ...item, checked: e.target.checked } }) });
      }
      else if (group_type == 'sleeve' && type == 2) {
         this.setState({ sleeves_lists: this.state.sleeves_lists.map((item) => { return e.target.value === item.id ? { ...item, checked: e.target.checked } : item }) });
      }
      else if (group_type == 'sleeve' && type == 1) {
         this.setState({ sleeves_lists: this.state.sleeves_lists.map((item) => { return { ...item, checked: e.target.checked } }) });
      }
      else if (group_type == 'fiting' && type == 2) {
         this.setState({ fit_lists: this.state.fit_lists.map((item) => { return e.target.value === item.id ? { ...item, checked: e.target.checked } : item }) });
      }
      else if (group_type == 'fiting' && type == 1) {
         this.setState({ fit_lists: this.state.fit_lists.map((item) => { return { ...item, checked: e.target.checked } }) });
      }
      else if (group_type == 'ocacsion' && type == 2) {
         this.setState({ ocacsion_lists: this.state.ocacsion_lists.map((item) => { return e.target.value === item.id ? { ...item, checked: e.target.checked } : item }) });
      }
      else if (group_type == 'ocacsion' && type == 1) {
         this.setState({ ocacsion_lists: this.state.ocacsion_lists.map((item) => { return { ...item, checked: e.target.checked } }) });
      }
      else if (group_type == 'price' && type == 2) {
         this.setState({ price_lists: this.state.price_lists.map((item) => { return e.target.value == item.id ? { ...item, checked: true } : { ...item, checked: false } }) });
      }
      else if (group_type == 'main' && type == 2) {
         this.setState({ main_category: this.state.main_category.map((item) => { return e.target.value == item.id ? { ...item, checked: e.target.checked } : item }) });
      }
      else if (group_type == 'main' && type == 1) {
         this.setState({ main_category: this.state.main_category.map((item) => { return { ...item, checked: e.target.checked } }) });
      }
      else if (group_type == 'sub' && type == 2) {
         const sub_category = this.state.main_category[e.target.name]['sub_category'].map((item) => { return e.target.value == item.id ? { ...item, checked: e.target.checked } : item })
         var main_category = this.state.main_category.map((item, index) => { return index == e.target.name ? { ...item, sub_category: sub_category } : item });
         if (e.target.checked == true) {
            main_category = main_category.map((item, index) => { return e.target.name == index ? { ...item, checked: true } : item });
            this.setState({ main_category: main_category });
         }
         else {
            main_category = main_category.map((item, index) => { return e.target.name == index ? { ...item, checked: false } : item });
            this.setState({ main_category: main_category });
         }
      }
      setTimeout(async () => {
         await this.load_category_product(0);
      }, 1000)
   }
   all_child_category = (e, index, index2) => {
      const child_category = this.state.main_category[index]['sub_category'][index2]['child_category'].map((item) => { return e.target.value == item.id ? { ...item, checked: e.target.checked } : item })
      this.setState({ main_category: this.state.main_category.map((item, index3) => { return index == index3 ? { ...item, sub_category: (this.state.main_category[index]['sub_category'].map((item2, index4) => { return index4 == index2 ? { ...item2, child_category: child_category } : item2 })) } : item }) });
      setTimeout(async () => {
         await this.load_category_product(0);
      }, 1000)
   }
   name_order_variation = (type) => {
      var products = this.state.products;
      if (type == 'desc') {
         products.sort((b, a) => { return a.p_name.localeCompare(b.p_name) })
      }
      else {
         products.sort((a, b) => { return a.p_name.localeCompare(b.p_name) })
      }
      this.setState({
         products: products
      });
   }
   onChange(e) {
      this.setState({ [e.target.name]: e.target.value });
   }
   componentDidMount() {
      other_action();
      this.setState({
         sleeves_lists: sleeves_list(), fit_lists: fit_list(), color_lists: color_list(), ocacsion_lists: ocacsion_list(),
         pattern_Lists: pattern_List(), frabic_Lists: frabic_List(), price_lists: price_list()
      })
      this.load_all_category();
      this.load_category_product(0);
      let mythis = this;
      setTimeout(function () {
         //mythis.get_product_brand();
      }, 2000);
      var options = {
         root: null,
         rootMargin: "0px",
         threshold: 1.0
      };
      this.observer = new IntersectionObserver(
         this.handleObserver.bind(this),
         options
      );
      this.observer.observe(this.loadingRef);
   }
   handleObserver(entities, observer) {
      const y = entities[0].boundingClientRect.y;
      if (this.state.prevY > y) {
         if (this.state.call_product_loader) {
            if (this.state.newStartFrom > this.state.startFrom) {
               this.load_category_product(parseInt(this.state.newStartFrom));
            }
         }
      }
      this.setState({ prevY: y });
   }
   render() {
      const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
      const { error, products, main_category, c_p_isLoaded, category_isLoaded, pb1_image, pb2_image } = this.state;
      return (
         <>
            <Helmet>
               <title>Harpra New Arrivals Product</title>
            </Helmet>
            {/* <Header /> */}
            <div className="page-container">
               <section className="pt-3 pb-1 page-info border-bottom bg-white">
                  <div className="container">
                     <div className="row">
                        <div className="col-md-12">
                           <Link to="/"><strong><span className="mdi mdi-home" /> Home</strong></Link> <span className="mdi mdi-chevron-right" /> <a>New Arrivals Product</a>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="shop-list section-padding">
                  <div className="container-fluid">
                     <div className="row">
                        <div className="col-md-3">
                           <div className="shop-filters">
                              <div id="accordion">
                                 {category_isLoaded == true ?
                                    <div className="card">
                                       <div className="card-header" id="headingOne">
                                          <h5 className="mb-0">
                                             <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Category <span className="mdi mdi-chevron-down float-right" />
                                             </button>
                                          </h5>
                                       </div>
                                       <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                          <div className="card-body card-shop-filters">
                                             <div className="custom-control custom-checkbox">
                                                <input type="checkbox" onChange={(e) => this.all_category_product(e, 1, 'main')} className="custom-control-input" id="all_category" />
                                                <label className="custom-control-label" htmlFor="all_category">All Category </label>
                                             </div>
                                             {main_category.map((category, index) => {
                                                let sub_category = category.sub_category;
                                                return (
                                                   <div className="custom-control custom-checkbox" key={"key_main_" + index}>
                                                      <input type="checkbox" checked={category.checked || false} value={category.id} onChange={(e) => this.all_category_product(e, 2, 'main')} name="main_cat_variation[]" className="custom-control-input main_cat_variation" id={"input_main_" + category.id} />
                                                      <label className="custom-control-label" htmlFor={"input_main_" + category.id}> {category.group_name}</label>
                                                      {/* <div>
                                                         {sub_category.map((category2, index2) => {
                                                            let child_category = category2.child_category;
                                                            return (
                                                               <div className="custom-control custom-checkbox" key={"key_sub_" + index2}>
                                                                  <input type="checkbox" checked={category2.checked || false} value={category2.id} onChange={(e) => this.all_category_product(e, 2, 'sub')} name={index} className="custom-control-input main_cat_variation" id={"input_sub_" + category2.id} />
                                                                  <label className="custom-control-label" htmlFor={"input_sub_" + category2.id}> {category2.group_name}</label>
                                                                  <div>
                                                                     {(category2.checked == true && category.checked) && (child_category.map((category3, index3) => {
                                                                        return (
                                                                           <div className="custom-control custom-checkbox" key={"key_child_" + index3}>
                                                                              <input type="checkbox" checked={category3.checked || false} name={index} value={category3.id} onChange={(e) => this.all_child_category(e, index, index2)} className="custom-control-input main_cat_variation" id={"input_child_" + category3.id} />
                                                                              <label className="custom-control-label" htmlFor={"input_child_" + category3.id}> {category3.group_name}</label>
                                                                           </div>
                                                                        )
                                                                     }))}
                                                                  </div>
                                                               </div>
                                                            )
                                                         })}
                                                      </div> */}
                                                   </div>
                                                )
                                             })
                                             }
                                          </div>
                                       </div>
                                    </div>
                                    : <></>}
                                 {main_category.map((category, index) => {
                                    let sub_category = category.sub_category;
                                    return (
                                       sub_category.map((category2, index2) => {
                                          let child_category = category2.child_category;
                                          return (
                                             <div className="card" key={"key_main_" + index + index2}>
                                                <div className="card-header" id={"heading_" + index + index2}>
                                                   <h5 className="mb-0">
                                                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target={"#collapse" + index + index2} aria-expanded="true" aria-controls="collapseTree">
                                                         {category2.group_name} <span className="mdi mdi-chevron-down float-right" />
                                                      </button>
                                                   </h5>
                                                </div>
                                                <div id={"collapse" + index + index2} className="collapse" aria-labelledby={"heading" + index + index2} data-parent="#accordion">
                                                   <div className="card-body card-shop-filters">
                                                      {child_category.map((category3, index3) => {
                                                         return (
                                                            <div className="custom-control custom-checkbox" key={"key_child_" + index3}>
                                                               <input type="checkbox" checked={category3.checked || false} name={index} value={category3.id} onChange={(e) => this.all_child_category(e, index, index2)} className="custom-control-input main_cat_variation" id={"input_child_" + category3.id} />
                                                               <label className="custom-control-label" htmlFor={"input_child_" + category3.id}> {category3.group_name}</label>
                                                            </div>
                                                         )
                                                      })}
                                                   </div>
                                                </div>
                                             </div>
                                          )
                                       }))
                                 })
                                 }
                                 {this.state.p_b_isLoaded == true ?
                                    <div className="card">
                                       <div className="card-header" id="headingFour">
                                          <h5 className="mb-0">
                                             <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseTree">
                                                Product Brand <span className="mdi mdi-chevron-down float-right" />
                                             </button>
                                          </h5>
                                       </div>
                                       <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                          <div className="card-body card-shop-filters">
                                             <div className="custom-control custom-checkbox">
                                                <input type="checkbox" onChange={(e) => this.all_category_product(e, 1, 'brand')} className="custom-control-input" id="all_brand" />
                                                <label className="custom-control-label" htmlFor="all_brand">All Brand </label>
                                             </div>
                                             {
                                                this.state.products_brand.map((brand, index) => {
                                                   return (
                                                      <div className="custom-control custom-checkbox" key={"brand" + index}>
                                                         <input type="checkbox" checked={brand.checked || false} value={brand.brand_id} onChange={(e) => this.all_category_product(e, 2, 'brand')} name="brand_variation[]" className="custom-control-input brand_variation" id={"brand" + brand.brand_id} />
                                                         <label className="custom-control-label" htmlFor={"brand" + brand.brand_id}> {brand.company_name}</label>
                                                      </div>
                                                   )
                                                })}
                                          </div>
                                       </div>
                                    </div>
                                    : ''}
                                 <div className="card">
                                    <div className="card-header" id="heading_color">
                                       <h5 className="mb-0">
                                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse_color" aria-expanded="true" aria-controls="collapse_color">
                                             Color <span className="mdi mdi-chevron-down float-right" />
                                          </button>
                                       </h5>
                                    </div>
                                    <div id="collapse_color" className="collapse" aria-labelledby="heading_color" data-parent="#accordion">
                                       <div className="card-body card-shop-filters">
                                          <div className="custom-control custom-checkbox">
                                             <input type="checkbox" onChange={(e) => this.all_category_product(e, 1, 'color')} className="custom-control-input" id="all_color" />
                                             <label className="custom-control-label" htmlFor="all_color">All Color </label>
                                          </div>
                                          {
                                             this.state.color_lists.map((color, index) => {
                                                return (
                                                   <div className="custom-control custom-checkbox" key={"color_" + index}>
                                                      <input type="checkbox" checked={color.checked || false} value={color.id} onChange={(e) => this.all_category_product(e, 2, 'color')} name="color_lists[]" className="custom-control-input" id={"color_" + color.id} />
                                                      <label className="custom-control-label" htmlFor={"color_" + color.id}> {color.id}</label>
                                                   </div>
                                                )
                                             })}
                                       </div>
                                    </div>
                                 </div>
                                 <div className="card">
                                    <div className="card-header" id="heading_pattern">
                                       <h5 className="mb-0">
                                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse_pattern" aria-expanded="true" aria-controls="collapse_pattern">
                                             Pattern <span className="mdi mdi-chevron-down float-right" />
                                          </button>
                                       </h5>
                                    </div>
                                    <div id="collapse_pattern" className="collapse" aria-labelledby="heading_pattern" data-parent="#accordion">
                                       <div className="card-body card-shop-filters">
                                          <div className="custom-control custom-checkbox">
                                             <input type="checkbox" onChange={(e) => this.all_category_product(e, 1, 'pattern')} className="custom-control-input" id="all_pattern" />
                                             <label className="custom-control-label" htmlFor="all_pattern">All Pattern </label>
                                          </div>
                                          {
                                             this.state.pattern_Lists.map((pattern, index) => {
                                                return (
                                                   <div className="custom-control custom-checkbox" key={"pattern_" + index}>
                                                      <input type="checkbox" checked={pattern.checked || false} value={pattern.id} onChange={(e) => this.all_category_product(e, 2, 'pattern')} name="pattern_Lists[]" className="custom-control-input" id={"pattern_" + pattern.id} />
                                                      <label className="custom-control-label" htmlFor={"pattern_" + pattern.id}> {pattern.id}</label>
                                                   </div>
                                                )
                                             })}
                                       </div>
                                    </div>
                                 </div>
                                 <div className="card">
                                    <div className="card-header" id="heading_frabic">
                                       <h5 className="mb-0">
                                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse_frabic" aria-expanded="true" aria-controls="collapse_frabic">
                                             Frabic <span className="mdi mdi-chevron-down float-right" />
                                          </button>
                                       </h5>
                                    </div>
                                    <div id="collapse_frabic" className="collapse" aria-labelledby="heading_frabic" data-parent="#accordion">
                                       <div className="card-body card-shop-filters">
                                          <div className="custom-control custom-checkbox">
                                             <input type="checkbox" onChange={(e) => this.all_category_product(e, 1, 'frabic')} className="custom-control-input" id="all_frabic" />
                                             <label className="custom-control-label" htmlFor="all_frabic">All Frabic </label>
                                          </div>
                                          {
                                             this.state.frabic_Lists.map((frabic, index) => {
                                                return (
                                                   <div className="custom-control custom-checkbox" key={"frabic_" + index}>
                                                      <input type="checkbox" checked={frabic.checked || false} value={frabic.id} onChange={(e) => this.all_category_product(e, 2, 'frabic')} name="frabic_Lists[]" className="custom-control-input" id={"frabic_" + frabic.id} />
                                                      <label className="custom-control-label" htmlFor={"frabic_" + frabic.id}> {frabic.id}</label>
                                                   </div>
                                                )
                                             })}
                                       </div>
                                    </div>
                                 </div>
                                 <div className="card">
                                    <div className="card-header" id="heading_sleeve">
                                       <h5 className="mb-0">
                                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse_sleeve" aria-expanded="true" aria-controls="collapse_sleeve">
                                             Sleeve Length <span className="mdi mdi-chevron-down float-right" />
                                          </button>
                                       </h5>
                                    </div>
                                    <div id="collapse_sleeve" className="collapse" aria-labelledby="heading_sleeve" data-parent="#accordion">
                                       <div className="card-body card-shop-filters">
                                          <div className="custom-control custom-checkbox">
                                             <input type="checkbox" onChange={(e) => this.all_category_product(e, 1, 'sleeve')} className="custom-control-input" id="all_sleeve" />
                                             <label className="custom-control-label" htmlFor="all_sleeve">All Sleeve Length </label>
                                          </div>
                                          {
                                             this.state.sleeves_lists.map((sleeve, index) => {
                                                return (
                                                   <div className="custom-control custom-checkbox" key={"sleeve_" + index}>
                                                      <input type="checkbox" checked={sleeve.checked || false} value={sleeve.id} onChange={(e) => this.all_category_product(e, 2, 'sleeve')} name="sleeves_lists[]" className="custom-control-input" id={"sleeve_" + sleeve.id} />
                                                      <label className="custom-control-label" htmlFor={"sleeve_" + sleeve.id}> {sleeve.id}</label>
                                                   </div>
                                                )
                                             })}
                                       </div>
                                    </div>
                                 </div>
                                 <div className="card">
                                    <div className="card-header" id="heading_fiting">
                                       <h5 className="mb-0">
                                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse_fiting" aria-expanded="true" aria-controls="collapse_fiting">
                                             Fit <span className="mdi mdi-chevron-down float-right" />
                                          </button>
                                       </h5>
                                    </div>
                                    <div id="collapse_fiting" className="collapse" aria-labelledby="heading_fiting" data-parent="#accordion">
                                       <div className="card-body card-shop-filters">
                                          <div className="custom-control custom-checkbox">
                                             <input type="checkbox" onChange={(e) => this.all_category_product(e, 1, 'fiting')} className="custom-control-input" id="all_fiting" />
                                             <label className="custom-control-label" htmlFor="all_fiting">All Fit </label>
                                          </div>
                                          {
                                             this.state.fit_lists.map((fiting, index) => {
                                                return (
                                                   <div className="custom-control custom-checkbox" key={"fiting_" + index}>
                                                      <input type="checkbox" checked={fiting.checked || false} value={fiting.id} onChange={(e) => this.all_category_product(e, 2, 'fiting')} name="fit_lists[]" className="custom-control-input" id={"fiting_" + fiting.id} />
                                                      <label className="custom-control-label" htmlFor={"fiting_" + fiting.id}> {fiting.id}</label>
                                                   </div>
                                                )
                                             })}
                                       </div>
                                    </div>
                                 </div>
                                 <div className="card">
                                    <div className="card-header" id="heading_ocacsion">
                                       <h5 className="mb-0">
                                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse_ocacsion" aria-expanded="true" aria-controls="collapse_ocacsion">
                                             Occasion <span className="mdi mdi-chevron-down float-right" />
                                          </button>
                                       </h5>
                                    </div>
                                    <div id="collapse_ocacsion" className="collapse" aria-labelledby="heading_ocacsion" data-parent="#accordion">
                                       <div className="card-body card-shop-filters">
                                          <div className="custom-control custom-checkbox">
                                             <input type="checkbox" onChange={(e) => this.all_category_product(e, 1, 'ocacsion')} className="custom-control-input" id="all_ocacsion" />
                                             <label className="custom-control-label" htmlFor="all_ocacsion">All Occasions </label>
                                          </div>
                                          {
                                             this.state.ocacsion_lists.map((ocacsion, index) => {
                                                return (
                                                   <div className="custom-control custom-checkbox" key={"ocacsion_" + index}>
                                                      <input type="checkbox" checked={ocacsion.checked || false} value={ocacsion.id} onChange={(e) => this.all_category_product(e, 2, 'ocacsion')} name="ocacsion_lists[]" className="custom-control-input" id={"ocacsion_" + ocacsion.id} />
                                                      <label className="custom-control-label" htmlFor={"ocacsion_" + ocacsion.id}> {ocacsion.id}</label>
                                                   </div>
                                                )
                                             })}
                                       </div>
                                    </div>
                                 </div>
                                 <div className="card">
                                    <div className="card-header" id="headingTwo">
                                       <h5 className="mb-0">
                                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                             Price <span className="mdi mdi-chevron-down float-right" />
                                          </button>
                                       </h5>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                       <div className="card-body card-shop-filters">
                                          {
                                             this.state.price_lists.map((price, index) => {
                                                return (
                                                   <div className="custom-control custom-radio" key={"price_" + index}>
                                                      <input type="radio" name="price_variation" value={price.id} checked={price.checked || false} onChange={(e) => this.all_category_product(e, 2, 'price')} className="custom-control-input" id={"price_" + index} />
                                                      <label className="custom-control-label" htmlFor={"price_" + index}>Rs{price.start} to Rs{price.end}</label>
                                                   </div>
                                                )
                                             })}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="left-ad mt-4">
                              {
                                 this.state.pb2_status == true && products.length > 4 ?
                                    pb2_image.slice(0, 1).map((list, index) => {
                                       return (
                                          <img key={"pb2" + index} className="img-fluid" src={list.image} />
                                       )
                                    }
                                    )
                                    : ''
                              }
                           </div>
                        </div>
                        <div className="col-md-9">
                           {
                              this.state.pb1_status == true ?
                                 pb1_image.slice(0, 1).map((list, index) => {
                                    return (
                                       <img key={"pb1" + index} className="img-fluid mb-3" src={list.image} />
                                    )
                                 }
                                 )
                                 : ''
                           }
                           <div className="row">
                              <div className="shop-head">
                                 <div className="col-sm-12">
                                    {/* <div className="filter-header"><Link to="/"><span className="mdi mdi-home" /> Home</Link> <span className="mdi mdi-chevron-right" /> <a>New Arrivals Product</a></div> */}
                                    <div className="btn-group float-right">
                                       <button type="button" filter-show='false' className="btn btn-primary mr-1 filterBtn">
                                          Filter
                                       </button>
                                       <button type="button" className="btn btn-dark dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          Sort by Products &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                       </button>
                                       <div className="dropdown-menu dropdown-menu-right">
                                          <button className="dropdown-item">Price (Low to High)</button>
                                          <button className="dropdown-item">Price (High to Low)</button>
                                          <button className="dropdown-item" onClick={() => this.name_order_variation('asc')}>Name (A to Z)</button>
                                          <button className="dropdown-item" onClick={() => this.name_order_variation('desc')}>Name (Z to A)</button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="row no-gutters mt-3">
                              {c_p_isLoaded == true ?
                                 products.map((product, index) => {
                                    let price = extract_price_detail(product.price_detail);
                                    return (
                                       <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6" key={"c_pro_" + index}>
                                          <div className="product">
                                             <Link to={"/product/" + product.product_slug + "/" + product.id + "?variant=" + price[0]['product_price_id']}>
                                                <div className="product-header">
                                                   <>
                                                      {price[0]['unit_mrp'] != price[0]['unit_price'] ?
                                                         <span className={"badge offerText_" + product.id}>
                                                            Upto {price[0].disc_type == 'flat' ?
                                                               price[0].unit_discount + ' ₹ ' : price[0].discount_percent + ' % '}
                                                            OFF</span>
                                                         :
                                                         <>
                                                         </>
                                                      }
                                                      <FadeIn easing={'ease-out'}>
                                                         {onload => (
                                                            <img className="img-fluid" src={product.img_paths} alt={product.p_name} onLoad={onload} />
                                                         )}
                                                      </FadeIn>
                                                      {/* <span className="veg text-success mdi mdi-circle" /> */}
                                                   </>
                                                </div>
                                             </Link>
                                             <ProductContaner product={product} price_detail={price} />
                                          </div>
                                       </div>
                                    )
                                 })
                                 : this.state.c_p_isnotAvailable == true ?
                                    <div className="mx-auto text-center pt-4 pb-5">
                                       <h1><img className="img-fluid" src={productnotfound} alt="404" /></h1>
                                       <h1>Sorry! Product not found.</h1>
                                       <p className="land">Unfortunately the Product you are looking for has been empty.</p>
                                    </div>
                                    : <div className="text-center mx-auto pt-4 pb-5">
                                       {
                                          <Oval arialLabel="loading-indicator" height={150} color="blue" secondaryColor="white" strokeWidth={5} strokeWidthSecondary={1} wrapperStyle={{ marginTop: "2%", display: "inline-block" }} />
                                       }
                                    </div>
                              }
                              <div ref={loadingRef => (this.loadingRef = loadingRef)}>
                                 <span style={loadingTextCSS}>Loading...</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
            {/* <Footer /> */}
         </>
      );
   }
}
export default NewArrivals;