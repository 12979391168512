export function color_list() {
    return ([
        {
            'id':
                'Blue'
        },
        {
            'id':
                'White'
        },
        {
            'id':
                'Navy Blue'
        },
        {
            'id':
                'Pink'
        },
        {
            'id':
                'Green'
        },
        {
            'id':
                'Black'
        },
        {
            'id':
                'Grey'
        },
        {
            'id':
                'Maroon'
        },
        {
            'id':
                'Yellow'
        },
        {
            'id':
                'Red'
        },
        {
            'id':
                'Beige'
        },
        {
            'id':
                'Olive'
        },
        {
            'id':
                'Off White'
        },
        {
            'id':
                'Charcoal'
        },
        {
            'id':
                'Cream'
        },
        {
            'id':
                'Brown'
        },
        {
            'id':
                'Lavender'
        },
        {
            'id':
                'Burgundy'
        },
        {
            'id':
                'Rust'
        },
        {
            'id':
                'Purple'
        },
        {
            'id':
                'Orange'
        },
        {
            'id':
                'Mustard'
        },
        {
            'id':
                'Peach'
        },
        {
            'id':
                'Multi'
        },
        {
            'id':
                'Sea Green'
        },
        {
            'id':
                'Khaki'
        },
        {
            'id':
                'Teal'
        },
        {
            'id':
                'Mauve'
        },
        {
            'id':
                'Grey Melange'
        },
        {
            'id':
                'Tan'
        },
    ])
}

export function fit_list() {
    return ([
        {
            'id':
                'SLIM FIT'
        },
        {
            'id':
                'REGULAR FIT (MORE COMFORT)'
        },
        {
            'id':
                'COMFORT FIT'
        },
        {
            'id':
                'MUSCLE FIT'
        },
        {
            'id':
                'BAGGY FIT'
        },
    ])
}
export function sleeves_list() {
    return ([
        {
            'id':
                'FULL SLEEVES'
        },
        {
            'id':
                'SLEEVELESS'
        },
        {
            'id':
                'CAP SLEEVES'
        },
        {
            'id':
                '3/4TH  SLEEVES'
        },
        {
            'id':
                'REGLAN SLEEVES'
        },
        {
            'id':
                'HALF SLEEVES'
        },
    ])
}
export function ocacsion_list() {
    return ([
        {
            'id':
                'CASUAL'
        },
    ])
}
export function price_list() {
    return ([
        {
            'start':
                0
            , 'end':
                100
            , 'id':
                1
        },
        {
            'start':
                100
            , 'end':
                250
            , 'id':
                2
        },
        {
            'start':
                250
            , 'end':
                500
            , 'id':
                3
        },
        {
            'start':
                500
            , 'end':
                1000
            , 'id':
                4
        },
        {
            'start':
                1000
            , 'end':
                2000
            , 'id':
                5
        },
    ])
}
export function pattern_List() {
    return ([
        {
            'id':
                'SOLID'
        },
        {
            'id':
                'DOTS'
        },
        {
            'id':
                'TEXTURE'
        },
        {
            'id':
                'STRIPS'
        },
        {
            'id':
                'PRINTS'
        },
        {
            'id':
                'CHECK'
        },
        {
            'id':
                'HORIZONATAL STRIPS'
        },
        {
            'id':
                'VERTICAL STRIPS'
        },
        {
            'id':
                'COTTON'
        },
    ])
}
export function frabic_List() {
    return ([
        {
            'id':
                'COTTON'
        },
        {
            'id':
                'COTTON LYCRA'
        },
        {
            'id':
                'ACRYLIC'
        },
        {
            'id':
                'BLEND KNIT'
        },
        {
            'id':
                'COTTON LINEN'
        },
        {
            'id':
                'INDIGO'
        },
    ])
}