import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import { fetch_profile, fetch_state_by_pincode, fetch_country_state_city_option } from '../components/Fetch_All_data.js';
import { update_profile_action } from '../components/Update_all_data.js';
import { only_number } from "../components/General_data";
import Profile_Nav from './Profile_Nav.js';
class Myprofile extends Component {
   constructor(props) {
      super(props);
      this.state = {
         loading: true,
         client_name_Is: false,
         client_contact_Is: false,
         client_alternate_contact_Is: false,
         client_email_Is: false,
         country_Is: false,
         pincode_Is: false,
         states_Is: false,
         city_Is: false,
         alert: null,
         client_name: '',
         client_contact: '',
         user_dob: '',
         country_state_city: [],
         client_alternate_contact: '',
         client_email: '',
         muser_address: '',
         pincode: '',
         country: '0',
         states: '',
         city: ''
      };
      this.onChange = this.onChange.bind(this);

   }
   onChange(e) {
      this.setState({ [e.target.name]: e.target.value });
   }
   update_profile = () => {
      this.setState({ loading: true })
      this.setState({ client_name_Is: false })
      this.setState({ client_contact_Is: false })
      this.setState({ client_alternate_contact_Is: false })
      this.setState({ user_dob_Is: false })
      this.setState({ client_email_Is: false })
      this.setState({ pincode_Is: false })
      this.setState({ country_Is: false })
      this.setState({ states_Is: false })
      this.setState({ city_Is: false })
      let form_data = this.state
      let formIsValid = true;
      if (form_data.client_name == '') {
         formIsValid = false;
         this.setState({ client_name_Is: true })
      }
      if ((form_data.client_contact).length != '10' || !(form_data.client_contact).match(/^[0-9]+$/)) {
         formIsValid = false;
         this.setState({ client_contact_Is: true })
      }
      /* if ((form_data.client_alternate_contact) == null || (form_data.client_alternate_contact).length != '10' || !(form_data.client_alternate_contact).match(/^[0-9]+$/)) {
         formIsValid = false;
         this.setState({ client_alternate_contact_Is: true })
      } */
      if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(form_data.client_email) == false) {
         formIsValid = false;
         this.setState({ client_email_Is: true })
      }
      if (form_data.country == '0') {
         formIsValid = false;
         this.setState({ country_Is: true })
      }
      if ((form_data.pincode) == null || (form_data.pincode) == '' || (form_data.pincode).length != 6 || !(form_data.pincode).match(/^[0-9]+$/)) {
         formIsValid = false;
         this.setState({ pincode_Is: true })
      }
      if (form_data.states == '') {
         formIsValid = false;
         this.setState({ states_Is: true })
      }
      if (form_data.city.length == '') {
         formIsValid = false;
         this.setState({ city_Is: true })
      }
      if (formIsValid == true) {
         let option_data = {
            'muser_name': form_data.client_name, 'muser_mobno': form_data.client_contact,
            'user_dob': form_data.user_dob,
            'muser_alt_mobno': form_data.client_alternate_contact, 'muser_email': form_data.client_email,
            'muser_country': form_data.country, 'pincode': form_data.pincode, 'state_id': form_data.states,
            'city_id': form_data.city, 'muser_id': localStorage.getItem('client_id'), 'muser_address': form_data.muser_address
         };
         update_profile_action(option_data)
            .then(
               (resolve) => {
                  if (resolve['status'] == '1') {
                     localStorage.setItem('client_contact', (resolve.data['muser_mobno']));
                     localStorage.setItem('client_name', (resolve.data['muser_name']));
                     const getAlert2 = () => (
                        <SweetAlert success title={resolve['message']} onConfirm={() => { this.setState({ alert: null }); }} />
                     );
                     this.setState({ alert: getAlert2() });
                  }
                  else {
                     const getAlert2 = () => (
                        <SweetAlert warning title={resolve['message']} onConfirm={() => { this.setState({ alert: null }); }} />
                     );
                     this.setState({ alert: getAlert2() });
                  }

               },
               (reject) => {
                  console.log(reject);
               }
            )
      }
      this.setState({ loading: false })
   }
   componentDidMount() {

      fetch_country_state_city_option()
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  this.setState({ country_state_city: resolve['data'] });
               }
            },
            (reject) => {
               console.log(reject);
            }
         )

      fetch_profile()
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  this.setState({ client_name: resolve.data['muser_name'] });
                  this.setState({ client_contact: resolve.data['muser_mobno'] });
                  this.setState({ client_alternate_contact: resolve.data['muser_alt_mobno'] || '' });
                  this.setState({ client_email: resolve.data['muser_email'] || '' });
                  this.setState({ pincode: resolve.data['pind_code'] });
                  this.setState({ muser_address: resolve.data['muser_address'] });
                  this.setState({ states: resolve.data['state_id'] || '' });
                  this.setState({ user_dob: resolve.data['user_dob'] || '' });
                  this.setState({ city: resolve.data['city_id'] || '' });
                  this.setState({ country: resolve.data['muser_country'] || '' });
                  this.setState({ loading: false });
               }
               else {
                  window.localStorage.removeItem("login_token");
                  window.localStorage.removeItem("client_contact");
                  window.localStorage.removeItem("client_name");
                  window.localStorage.removeItem("client_id");
                  return <div>{<Redirect to={{ pathname: '/', state: { page_reload: true } }}></Redirect>}</div>
               }
            },
            (reject) => {
               console.log(reject);
            }
         )
   }
   handleKeyUp = (event) => {
      let pincode = event.target.value;
      if (pincode.length == 6) {
         fetch_state_by_pincode(pincode)
            .then(
               (resolve) => {
                  if (resolve['Status'] == 1) {
                     this.setState({ area_isLoaded: true, states: resolve.state, city: resolve.district });
                  }
                  else {
                     const getAlert2 = () => (
                        <SweetAlert warning title={resolve['Message']} onConfirm={() => { this.setState({ alert: null }); }} />
                     );
                     this.setState({ alert: getAlert2() });
                  }
               },
               (reject) => {
                  console.log(reject);
               })
      }

   }
   render() {
      return (
         <div>
            {/* <Header /> */}
            <div className="page-container">
               {this.state.alert}
               <section className="account-page section-padding">
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-10 mx-auto">
                           <div className="row no-gutters">
                              <div className="col-md-4">
                                 <Profile_Nav current_page={'myprofile'} />
                              </div>
                              <div className="col-md-8">
                                 <div className="card card-body account-right">
                                    <div className="widget">
                                       <div className="section-header">
                                          <h5 className="heading-design-h5">
                                             My Profile
                                          </h5>
                                       </div>
                                       <div className="row">
                                          <div className="col-sm-6">
                                             <div className="form-group">
                                                <label className="control-label">Name <span className="required">*</span></label>
                                                <input className="form-control border-form-control" name="client_name" value={this.state.client_name || ''} onChange={this.onChange} type="text" />
                                                {this.state.client_name_Is == true ? <span style={{ color: 'red' }}>User Name not be empty</span> : ''}
                                             </div>
                                          </div>
                                          <div className="col-sm-6">
                                             <div className="form-group">
                                                <label className="control-label">Date of Birth </label>
                                                <input className="form-control border-form-control" name="user_dob" value={this.state.user_dob || ''} onChange={this.onChange} type="date" />
                                                {this.state.user_dob_Is == true ? <span style={{ color: 'red' }}>Date of Birth not be empty</span> : ''}
                                             </div>
                                          </div>
                                          <div className="col-sm-6">
                                             <div className="form-group">
                                                <label className="control-label">Mobile <span className="required">*</span></label>
                                                <input className="form-control border-form-control" onInput={(e) => only_number(e)} name="client_contact" value={this.state.client_contact || ''} onChange={this.onChange} type="text" minLength={10} maxLength={10} />
                                                {this.state.client_contact_Is == true ? <span style={{ color: 'red' }}>Mobile number required</span> : ''}
                                             </div>
                                          </div>
                                          <div className="col-sm-6">
                                             <div className="form-group">
                                                <label className="control-label">Alternate Mobile</label>
                                                <input className="form-control border-form-control" onInput={(e) => only_number(e)} type="text" minLength={10} maxLength={10} name="client_alternate_contact" value={this.state.client_alternate_contact || ''} onChange={this.onChange} />
                                                {this.state.client_alternate_contact_Is == true ? <span style={{ color: 'red' }}>Alternate contact required </span> : ''}
                                             </div>
                                          </div>
                                          <div className="col-sm-6">
                                             <div className="form-group">
                                                <label className="control-label">Email Address <span className="required">*</span></label>
                                                <input className="form-control border-form-control " name="client_email" value={this.state.client_email || ''} onChange={this.onChange} type="email" />
                                                {this.state.client_email_Is == true ? <span style={{ color: 'red' }}>Email id required</span> : ''}
                                             </div>
                                          </div>
                                          <div className="col-sm-6">
                                             <div className="form-group">
                                                <label className="control-label">Country <span className="required">*</span></label>
                                                <select value={this.state.country || 0} name="country" onChange={this.onChange} className="select2 form-control border-form-control">
                                                   <option value="0">Select Country</option>
                                                   {this.state.country_state_city.map((country, index) => {
                                                      return (
                                                         (country.l_master_type == 1) ?
                                                            <option key={'c' + index} value={country.id}>{country.name}</option>
                                                            : ''
                                                      )
                                                   }
                                                   )
                                                   }
                                                </select>
                                                {this.state.country_Is == true ? <span style={{ color: 'red' }}>Select Country</span> : ''}
                                             </div>
                                          </div>
                                          <div className="col-sm-6">
                                             <div className="form-group">
                                                <label className="control-label">State <span className="required" >*</span></label>
                                                <select value={this.state.states || 0} name="states" onChange={this.onChange} className="select2 form-control border-form-control">
                                                   <option value="0">Select State</option>
                                                   {this.state.country_state_city.map((state, index) => {
                                                      return (
                                                         (state.l_master_type == 2 && this.state.country == state.l3) ?
                                                            <option key={'c' + index} value={state.l1}>{state.name}</option>
                                                            : ''
                                                      )
                                                   }
                                                   )
                                                   }
                                                </select>
                                                {this.state.states_Is == true ? <span style={{ color: 'red' }}>State required</span> : ''}
                                             </div>
                                          </div>
                                          <div className="col-sm-6">
                                             <div className="form-group">
                                                <label className="control-label">City <span className="required">*</span></label>
                                                <select value={this.state.city || 0} name="city" onChange={this.onChange} className="select2 form-control border-form-control">
                                                   <option value="0">Select City</option>
                                                   {this.state.country_state_city.map((city, index) => {
                                                      return (
                                                         (city.l_master_type == 3 && this.state.states == city.l3) ?
                                                            <option key={'c' + index} value={city.id}>{city.name}</option>
                                                            : ''
                                                      )
                                                   }
                                                   )
                                                   }
                                                </select>
                                                {this.state.city_Is == true ? <span style={{ color: 'red' }}>City required</span> : ''}
                                             </div>
                                          </div>
                                          <div className="col-sm-6">
                                             <div className="form-group">
                                                <label className="control-label">Pin Code <span className="required">*</span></label>
                                                <input type="text" minLength={6} onInput={(e) => only_number(e)} maxLength={6} className="form-control border-form-control" name="pincode" value={this.state.pincode || ''} onChange={this.onChange} />
                                                {this.state.pincode_Is == true ? <span style={{ color: 'red' }}>Pincode required</span> : ''}
                                             </div>
                                          </div>
                                          <div className="col-sm-12">
                                             <div className="form-group">
                                                <label className="control-label">Full Address <span className="required">*</span></label>
                                                <textarea className="form-control border-form-control" name="muser_address" onChange={this.onChange} value={this.state.muser_address || ''} />
                                             </div>
                                          </div>
                                       </div>
                                       <div className="row">
                                          <div className="col-sm-12 text-right">
                                             <button type="button" disabled={this.state.loading} onClick={() => this.update_profile()} className="btn btn-success btn-lg">{this.state.loading && <i className="mdi mdi-refresh mdi-spin" aria-hidden="true"></i>} Save Changes </button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
            {/* <Footer /> */}
         </div>
      );
   }
}
export default Myprofile;
