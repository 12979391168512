import React, { Component } from 'react';
import Update_Address from './Update_Address.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import { store_order_address, fetch_order_address, pincode_wise_search, fetch_state_option, fetch_cities_in_option } from '../components/Fetch_All_data.js';
class DeliveryAddress extends Component {
   constructor(props) {
      super(props);
      this.state = {
         error: '',
         first_name: '',
         last_name: '',
         userarea: '',
         contact: localStorage.getItem('client_contact'),
         address_1: '',
         pincode: '',
         city: '',
         states: '',
         area_id: '',
         city_id: '',
         pincode_id: '',
         state_id: '',
         address_id: '',
         offfice_type: '1',
         address_nickname: '',
         addresh_list_display: true,
         addresh_add_display: false,
         addresh_edit_display: false,
         address_list: [],
         area_list: [],
         state_list: [],
         city_list: [],
         loading: false,
         formIsValid: false,
         alert: null,
         address_isLoaded: false
      };
      this.onChange = this.onChange.bind(this);
   }
   add_address_display() {
      this.setState({ addresh_list_display: false, addresh_edit_display: false, addresh_add_display: true });
   }
   edit_address = (address) => {
      this.setState({ address: address, addresh_list_display: false, addresh_edit_display: true, addresh_add_display: false });
   }
   address_list_display() {
      this.setState({ addresh_list_display: true, addresh_edit_display: false, addresh_add_display: false });
   }
   save_new_address() {
      this.setState({ loading: true })
      this.setState({ name1IsValid: false })
      this.setState({ name2IsValid: false })
      this.setState({ mobileIsValid: false })
      this.setState({ adrs1IsValid: false })
      this.setState({ pincodeIsValid: false })
      this.setState({ cityIsValid: false })
      this.setState({ stateIsValid: false })
      this.setState({ area_idIsValid: false })
      this.setState({ userareaIsValid: false })
      let form_data = this.state;
      this.setState({ error_message: '' })
      let formIsValid = true;
      if (form_data.first_name == '') {
         formIsValid = false;
         this.setState({ name1IsValid: true })
         this.setState({ loading: false })
      }
      if (form_data.last_name == '') {
         formIsValid = false;
         this.setState({ name2IsValid: true })
         this.setState({ loading: false })
      }
      if (form_data.address_1 == '') {
         formIsValid = false;
         this.setState({ adrs1IsValid: true })
         this.setState({ loading: false })
      }
      if (form_data.pincode == '') {
         formIsValid = false;
         this.setState({ pincodeIsValid: true })
         this.setState({ loading: false })
      }
      if (form_data.city_id == '') {
         formIsValid = false;
         this.setState({ cityIsValid: true })
         this.setState({ loading: false })
      }
      if (form_data.userarea == '') {
         formIsValid = false;
         this.setState({ userareaIsValid: true })
         this.setState({ loading: false })
      }
      /* if (form_data.area_id == '0') {
         formIsValid = false;
         this.setState({ area_idIsValid: true })
         this.setState({ loading: false })
      } */
      if (form_data.state_id == '') {
         formIsValid = false;
         this.setState({ stateIsValid: true })
         this.setState({ loading: false })
      }
      if ((form_data.contact) == null || (form_data.contact).length != '10' || !(form_data.contact).match(/^[0-9]+$/)) {
         formIsValid = false;
         this.setState({ mobileIsValid: true })
         this.setState({ loading: false })
      }
      if (formIsValid == true) {
         store_order_address({
            'muser_id': localStorage.getItem('client_id'), 'firstname': form_data.first_name,
            'lastname': form_data.last_name, 'addressline': form_data.address_1, 'addr_type': form_data.offfice_type,
            'pincode': form_data.pincode, 'mobile': form_data.contact, 'area_id': form_data.area_id, 'userarea': form_data.userarea,
            'city_id': form_data.city_id, 'state_id': form_data.state_id, 'pincode_id': form_data.pincode_id
         })
            .then((resolve) => {
               if (resolve['status'] == '1') {
                  this.setState({ error: resolve['message'] });
                  this.get_address_list();
               }
               else {
                  const getAlert2 = () => (
                     <SweetAlert warning title={resolve['message']} onConfirm={() => { this.setState({ alert: null }); }} />
                  );
                  this.setState({ alert: getAlert2() });
               }
            },
               (reject) => {
                  console.log(reject);
               })
      }
   }
   only_number = (e) => {
      e.target.value = e.target.value.replace(/[^0-9.]/g, ''); e.target.value = e.target.value.replace(/(\..*)\./g, '$1');
   }

   pincode_function(pincode) {
      pincode_wise_search(pincode)
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  //this.setState({ area_list: resolve['data'] });
                  this.setState({ city: resolve['data'][0].city_name });
                  this.setState({ pincode: pincode });
                  this.setState({ city_id: resolve['data'][0].city_id });
                  this.setState({ states: resolve['data'][0].state });
                  this.setState({ state_id: resolve['data'][0].state_id });
                  this.setState({ pincode_id: resolve['data'][0].pincode_id });
                  //this.setState({ userarea: resolve['data'] });
                  //this.setState({ area_isLoaded: true, area_id: localStorage.getItem('actual_area_id') });
               }
               else {
                  const getAlert2 = () => (
                     <SweetAlert warning title={resolve['message']} onConfirm={() => { this.setState({ alert: null }); }} />
                  );
                  this.setState({ alert: getAlert2() });
                  //this.setState({ area_isLoaded: false });
                  this.setState({ area_id: '0' });
               }
            },
            (reject) => {
               console.log(reject);
            })
   }
   handleKeyUp = (event) => {
      let pincode = event.target.value;
      if (pincode.length == 6) {
         //this.pincode_function(pincode);
      }
   }
   selectDeliveryLocation = () => {
      let address_id = this.state.address_id;
      if (address_id) {
         this.props.parentCallback({ address_id: address_id });
         localStorage.setItem('address_id', address_id);
      }
      else {
         const getAlert2 = () => (
            <SweetAlert warning title={"Select Delivery Address"} onConfirm={() => { this.setState({ alert: null }); }} />
         );
         this.setState({ alert: getAlert2() });
      }
   }
   handleCallback = () => {
      this.address_list_display();
   }
   onChange(e) {
      if (e.target.name == 'area_id') {
         this.setState({ userarea: e.target.options[e.target.selectedIndex].text });
      }
      else if (e.target.name == 'state_id') {
         this.get_city_list(e.target.value);
      }
      this.setState({ [e.target.name]: e.target.value });
   }
   onCancel = () => {
      this.setState({ alert: null });
   }
   get_city_list(state_id) {
      fetch_cities_in_option(state_id).then((resolve) => {
         if (resolve['status'] == 1) {
            this.setState({ city_list: resolve['data'] });
         }
         else {
            this.setState({ city_list: [] });
         }
      }, (reject) => {
         console.log(reject);
      })
   }
   componentDidMount() {
      //var pincode = localStorage.getItem('product_pincode');
      //this.pincode_function(pincode);
      this.get_address_list();
      fetch_state_option().then((resolve) => {
         if (resolve['status'] == 1) {
            this.setState({ state_list: resolve['data'] });
         }
      }, (reject) => {
         console.log(reject);
      });
      var address_id = localStorage.getItem('address_id');
      if (address_id) {
         this.setState({ address_id: address_id })
         this.props.parentCallback({ address_id: address_id });
      }
   }
   get_address_list() {
      fetch_order_address()
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  this.setState({ address_list: resolve['data'] });
                  this.setState({ address_isLoaded: true });
               }
            },
            (reject) => {
               console.log(reject);
            }
         );
   }
   render() {
      const { error, addresh_list_display, addresh_add_display, address_list, address_isLoaded } = this.state;
      return (
         <>
            {this.state.alert}
            <div className="row no-gutters">
               <div className="col-md-12">
                  {addresh_list_display == true ?
                     <>
                        <div className="row">
                           <div className="col-sm-12">
                              {address_isLoaded == true ?
                                 address_list.map((address, index) => {
                                    return (
                                       <div key={"add_" + index}>
                                          <input type={'radio'} checked={(this.state.address_id == address.id) ? true : false} name="address_id" value={address.id} id={"address" + address.id} onChange={this.onChange} style={{ fontSize: '16px' }} />
                                          <label htmlFor={"address" + address.id} className="ml-4 mb-0">{address.fir_name + " " + address.lastname} <span className="addressType">{(address.addr_type == '1') ? 'Home' : (address.addr_type == '2' ? 'Office' : 'Other')}</span></label>
                                          <p style={{ color: '#909092' }} className="ml-3">
                                             <span>{address.address_line1} {address.userarea} {address.city_name} </span>
                                             <span data-name="city">{address.state_name} </span>  - <span>{address.pinno}</span>
                                          </p>
                                       </div>
                                    )
                                 })
                                 : ''}
                           </div>
                        </div>
                        <br />
                        <div className="row">
                           <div className="col-sm-12 text-center">
                              <button type="button" onClick={() => this.add_address_display()} className="btn btn-warning mr-5 btn-lg"> Add New Address </button>
                              <button type="button" onClick={() => this.selectDeliveryLocation()} className="btn btn-success btn-lg"> Next </button>
                           </div>
                        </div>
                     </>
                     : ''}
                  {addresh_add_display == true ?
                     <div className="card card-body account-right">
                        <div className="widget">
                           <div className="section-header">
                              <h5 className="heading-design-h5">
                                 Add New Address
                              </h5>
                           </div>
                           <div className="text-left">
                              <p style={{ cursor: 'pointer', 'color': 'red' }} onClick={() => this.address_list_display()}><span className="mdi mdi-chevron-left"></span> Back to Address List</p>
                           </div>
                           <div className="row">
                              <div className="col-sm-6">
                                 <div className="form-group">
                                    <label className="control-label">First Name <span className="required">*</span></label>
                                    <input className="form-control border-form-control" name="first_name" placeholder="First Name" onChange={this.onChange} type="text" />
                                    {this.state.name1IsValid == true ? <span style={{ color: 'red' }}>First Name Required</span> : ''}
                                 </div>
                              </div>
                              <div className="col-sm-6">
                                 <div className="form-group">
                                    <label className="control-label">Last Name <span className="required">*</span></label>
                                    <input className="form-control border-form-control" name="last_name" placeholder="Last Name" onChange={this.onChange} type="text" />
                                    {this.state.name2IsValid == true ? <span style={{ color: 'red' }}>Last Name Required</span> : ''}
                                 </div>
                              </div>
                              <div className="col-sm-6">
                                 <div className="form-group">
                                    <label className="control-label">Contact<span className="required">*</span></label>
                                    <input className="form-control border-form-control" maxLength={10} onInput={(e) => this.only_number(e)} name="contact" value={this.state.contact} placeholder="Contact" onChange={this.onChange} type="text" />
                                    {this.state.mobileIsValid == true ? <span style={{ color: 'red' }}>Contact Number Required</span> : ''}
                                 </div>
                              </div>
                              <div className="col-sm-6">
                                 <div className="form-group">
                                    <label className="control-label">Address Type <span className="required" >*</span></label>
                                    <select name="offfice_type" defaultValue={this.state.offfice_type} onChange={this.onChange} className="select2 form-control border-form-control">
                                       <option value="1">HOME</option>
                                       <option value="2">OFFICE</option>
                                       <option value="3">OTHER</option>
                                    </select>
                                 </div>
                              </div>
                              {/* <div className="col-sm-6">
                                 <div className="form-group">
                                    <label className="control-label">City<span className="required">*</span></label>
                                    <input className="form-control border-form-control" value={this.state.city} name="city" placeholder="City" onChange={this.onChange} type="text" />
                                    {this.state.cityIsValid == true ? <span style={{ color: 'red' }}>City Required</span> : ''}
                                 </div>
                              </div> */}
                              {/* <div className="col-sm-6">
                                 <div className="form-group">
                                    <label className="control-label">State<span className="required">*</span></label>
                                    <input className="form-control border-form-control" value={this.state.states} name="states" placeholder="State" onChange={this.onChange} type="text" />
                                    {this.state.stateIsValid == true ? <span style={{ color: 'red' }}>State Required</span> : ''}
                                 </div>
                              </div> */}
                              <div className="col-sm-6">
                                 <div className="form-group">
                                    <label className="control-label">Select State <span className="required" >*</span></label>
                                    <select name="state_id" value={this.state.state_id} onChange={this.onChange} className="select2 form-control border-form-control">
                                       <option key={"state_id_12"} value="">Select State</option>
                                       {
                                          this.state.state_list.map((state, index) => {
                                             return (
                                                <option key={"state_" + state.state_code_id} value={state.state_code_id}>{state.state_name}</option>
                                             )
                                          })}
                                    </select>
                                 </div>
                              </div>
                              <div className="col-sm-6">
                                 <div className="form-group">
                                    <label className="control-label">Select City <span className="required" >*</span></label>
                                    <select name="city_id" value={this.state.city_id} onChange={this.onChange} className="select2 form-control border-form-control">
                                       <option key={"city_id_12"} value="">Select City</option>
                                       {
                                          this.state.city_list.map((city, index) => {
                                             return (
                                                <option key={"city_" + city.city_id} value={city.city_id}>{city.city_name}</option>
                                             )
                                          })}
                                    </select>
                                 </div>
                              </div>
                              <div className="col-sm-6">
                                 <div className="form-group">
                                    <label className="control-label">Pincode<span className="required">*</span></label>
                                    <input className="form-control border-form-control" maxLength={6} onInput={(e) => this.only_number(e)} value={this.state.pincode} name="pincode" placeholder="Pincode" onChange={this.onChange} type="text" />
                                    {this.state.pincodeIsValid == true ? <span style={{ color: 'red' }}>Pincode Required</span> : ''}
                                 </div>
                              </div>
                              {/* <div className="col-sm-6">
                                 <div className="form-group">
                                    <label className="control-label">Select Area <span className="required" >*</span></label>
                                    {this.state.area_isLoaded == true ?
                                       <select name="area_id" value={this.state.area_id} onChange={this.onChange} className="select2 form-control border-form-control">
                                          <option key={"area_0012"} value="0">Select Area</option>
                                          {
                                             this.state.area_list.map((area, index) => {
                                                return (
                                                   <option key={"area_" + area.a_id} value={area.a_id}>{area.area_name}</option>
                                                )
                                             })}
                                       </select>
                                       :
                                       <select name="area_id" value="0" onChange={this.onChange} className="select2 form-control border-form-control">
                                          <option key={"area_0012"} value="0">Select Area</option>
                                       </select>}
                                    {this.state.area_idIsValid == true ? <span style={{ color: 'red' }}>Area Required</span> : ''}
                                 </div>
                              </div> */}
                              <div className="col-sm-6">
                                 <div className="form-group">
                                    <label className="control-label">Area/NearBy<span className="required">*</span></label>
                                    <input className="form-control border-form-control" value={this.state.userarea} name="userarea" placeholder="Area/NearBy" onChange={this.onChange} type="text" />
                                    {this.state.userareaIsValid == true ? <span style={{ color: 'red' }}>Area/NearBy Required</span> : ''}
                                 </div>
                              </div>
                              <div className="col-sm-12">
                                 <div className="form-group">
                                    <label className="control-label">Full Address <span className="required">*</span></label>
                                    <textarea className="form-control border-form-control" name="address_1" placeholder="Full Address" onChange={this.onChange} />
                                    {this.state.adrs1IsValid == true ? <span style={{ color: 'red' }}>Full Address Required</span> : ''}
                                 </div>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-sm-12 text-center">
                                 <button type="button" disabled={this.state.loading} onClick={() => this.save_new_address()} className="btn btn-success btn-lg"> {this.state.loading && <i className="mdi mdi-refresh mdi-spin" aria-hidden="true"></i>} Save New Address </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     : ''}
                  {this.state.addresh_edit_display == true ?
                     <Update_Address parentCallback={this.handleCallback} address={this.state.address} />
                     : ''
                  }
               </div>
            </div>
         </>
      );
   }
}
export default DeliveryAddress;
