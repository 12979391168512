import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { login_user, fetch_login_signup_slider, check_user_registration, get_data_all, chk_user_signUp_otp } from './Fetch_All_data.js';
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            forget_contact: '',
            client_contact: '',
            client_password: '',
            reg_name: '',
            reg_mobile: '',
            reg_password: '',
            reg_re_password: '',
            loginImg: '',
            exactimage: '',
            signupImg: '',
            exactimage_status: false,
            loading: false,
            forgateTab: false,
            login_tab: true,
            app_token: localStorage.getItem('app_token'),
            register_tab: false,
            error_message: '',
            alert: null,
            forget_contactValid: false,
            mobileIsValid: false,
            nameIsValid: false,
            mobile2IsValid: false,
            passwordIsValid: false,
            password1IsValid: false,
            password2IsValid: false
        };
    }
    componentDidMount() {
        var login_token = localStorage.getItem('login_token');
        var client_token = localStorage.getItem('client_contact');
        if (client_token && login_token) {

        }
        else {
            this.login_signup_slider();
        }
    }
    only_number = (e) => {
        e.target.value = e.target.value.replace(/[^0-9.]/g, ''); e.target.value = e.target.value.replace(/(\..*)\./g, '$1');
    }
    login_signup_slider = () => {
        fetch_login_signup_slider()
            .then(
                (resolve) => {
                    if (resolve['loginImg_status'] == 1) {
                        this.setState({ exactimage: resolve['loginImg'], loginImg: resolve['loginImg'] })
                    }
                    if (resolve['signupImg_status'] == 1) {
                        if (resolve['loginImg_status'] == 0) {
                            this.setState({ loginImg: resolve['signupImg'] })
                        }
                        else {
                            this.setState({ signupImg: resolve['signupImg'] })
                        }
                    }
                    this.setState({ exactimage_status: true })
                },
                (reject) => {
                    console.log(reject);
                }
            )
    }
    login() {
        this.setState({ loading: true })
        let client_contact = this.state.client_contact;
        let client_password = this.state.client_password;
        this.setState({ error_message: '' })
        let formIsValid = true;
        if (client_contact == '') {
            formIsValid = false;
            this.setState({ mobileIsValid: true })
            this.setState({ loading: false })
            return;
        }
        else {
            this.setState({ mobileIsValid: false })
        }
        if (client_password == '') {
            formIsValid = false;
            this.setState({ passwordIsValid: true })
            this.setState({ loading: false })
            return;
        }
        else {
            this.setState({ passwordIsValid: false })
        }
        if (formIsValid == true) {

            let options = {
                'user_mobile': this.state.client_contact, 'device_token': '',
                'user_pass': this.state.client_password, 'm_u_token': this.state.app_token
            }
            login_user(options)
                .then(
                    (resolve) => {
                        if (resolve['status'] == '1') {
                            localStorage.setItem('app_token', (resolve.data['m_u_token']));
                            localStorage.setItem('login_token', (resolve.data['m_u_token']));
                            localStorage.setItem('client_id', (resolve.data['muser_id']));
                            localStorage.setItem('client_contact', (resolve.data['muser_mobno']));
                            localStorage.setItem('client_name', (resolve.data['muser_name']));
                            localStorage.setItem('profileImg', (localStorage.getItem('base_url') + resolve.data['profile_img']));
                            this.setState({ userLogin: true });
                            //this.props.history.push("/");
                            //<Redirect to="/"></Redirect>
                            window.location.reload(false);
                        }
                        else {
                            const getAlert1 = () => (
                                <SweetAlert warning title={resolve.message} onConfirm={() => { this.setState({ alert: null }); }} />
                            );
                            this.setState({ loading: false, alert: getAlert1() });
                        }
                    },
                    (reject) => {
                        console.log(reject);
                    }
                )
        }
    }
    forget = () => {
        let forget_contact = this.state.forget_contact;
        if (forget_contact.length != '10' || !forget_contact.match(/^[0-9]+$/)) {
            this.setState({ forget_contactValid: true })
        }
        else {
            this.setState({ loading: true })
            get_data_all('m/chk_user_send_otp_forget_pass', { 'user_mobile': forget_contact })
                .then(
                    (resolve) => {
                        if (resolve['status'] == 1) {
                            let getAlert3 = () => (
                                <SweetAlert type={'controlled'} showCancel onConfirm={() => { this.user_password_verify(forget_contact, this.state.forgetPassword, this.state.forgetPassword2, this.state.verificationOtp) }} closeOnClickOutside={false}
                                    onCancel={() => { this.setState({ alert: null }); }} title={"Reset Your Login Password"}
                                    dependencies={[this.state.forgetPassword, this.state.forgetPassword2, this.state.verificationOtp]}>
                                    {(renderProps: SweetAlertRenderProps) => (
                                        <form>
                                            <div className="login-modal-right">
                                                <fieldset className="form-group text-left">
                                                    <label>Enter Password</label>
                                                    <input type={"password"} className="form-control" onKeyDown={renderProps.onEnterKeyDownConfirm}
                                                        placeholder={"Enter New Password"} name={"forgetPassword"}
                                                        onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                                                    <p className="text-danger" id="p_text1"></p>
                                                </fieldset>
                                                <fieldset className="form-group text-left">
                                                    <label>Enter Confirm Password</label>
                                                    <input type="password" className="form-control" onKeyDown={renderProps.onEnterKeyDownConfirm}
                                                        placeholder="Enter Confirm Password" name={"forgetPassword2"}
                                                        onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                                                    <p className="text-danger" id="p_text2"></p>
                                                </fieldset>
                                                <fieldset className="form-group text-left">
                                                    <label>Enter Otp</label>
                                                    <input type="text" className="form-control" onKeyDown={renderProps.onEnterKeyDownConfirm}
                                                        placeholder="Enter verification otp" name={"verificationOtp"}
                                                        onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                                                    <p className="text-danger" id="p_text3"></p>
                                                </fieldset>
                                                <p>{resolve['message']}</p>
                                            </div>
                                        </form>
                                    )}
                                </SweetAlert>
                            );
                            this.setState({ loading: false, alert: getAlert3() });
                        }
                        else {
                            let getAlert4 = () => (
                                <SweetAlert warning title={resolve.message} onConfirm={() => { this.setState({ alert: null }); }} />
                            );
                            this.setState({ loading: false, alert: getAlert4() });
                        }
                    },
                    (reject) => {
                        console.log(reject)
                    }
                )
        }
    }
    register = () => {
        let reg_name = this.state.reg_name;
        let reg_mobile = this.state.reg_mobile;
        let reg_password = this.state.reg_password;
        let reg_re_password = this.state.reg_re_password;
        this.setState({ error_message: '' })
        let formIsValid = true;
        if (reg_name == '') {
            formIsValid = false;
            this.setState({ nameIsValid: true })
        }
        else {
            this.setState({ nameIsValid: false })
        }
        if (reg_mobile.length != '10' || !reg_mobile.match(/^[0-9]+$/)) {
            formIsValid = false;
            this.setState({ mobile2IsValid: true })
        }
        else {
            this.setState({ mobile2IsValid: false })
        }
        if (reg_password == '') {
            formIsValid = false;
            this.setState({ password1IsValid: true })
        }
        else {
            this.setState({ password1IsValid: false })
        }
        if (reg_re_password == '') {
            formIsValid = false;
            this.setState({ password2IsValid: true })
        }
        else {
            this.setState({ password2IsValid: false })
        }
        if (reg_re_password != reg_password || reg_re_password == '') {
            formIsValid = false;
            this.setState({ password2IsValid: true })
        }
        else {
            this.setState({ password2IsValid: false })
        }
        if (formIsValid == true) {
            this.setState({ loading: true })
            let options = {
                'user_mobile': this.state.reg_mobile, 'm_u_token': this.state.app_token
            }
            check_user_registration(options)
                .then(
                    (resolve) => {
                        if (resolve['status'] == '1') {
                            let getAlert2 = () => (
                                <SweetAlert input showCancel required cancelBtnBsStyle="light" inputType="number"
                                    validationMsg="Please enter otp" title={resolve.message} placeHolder={resolve.message}
                                    onConfirm={(response) => { this.user_registration_verify(response) }}
                                    closeOnClickOutside={false}
                                    onCancel={() => { this.setState({ alert: null }); }} />
                            );
                            this.setState({ loading: false, alert: getAlert2() });
                        }
                        else {
                            let getAlert1 = () => (
                                <SweetAlert warning title={resolve.message} onConfirm={() => { this.setState({ alert: null }); }} />
                            );
                            this.setState({ loading: false, alert: getAlert1() });
                        }
                    },
                    (reject) => {
                        console.log(reject);
                    }
                )
        }
    }
    user_registration_verify = (responsoe) => {
        let reg_name = this.state.reg_name;
        let reg_mobile = this.state.reg_mobile;
        let reg_password = this.state.reg_password;
        let reg_re_password = this.state.reg_re_password;
        let options = {
            'user_mobile': this.state.reg_mobile, 'm_u_token': this.state.app_token
            , 'input_otp': responsoe, 'plateform': 'WEB', 'user_pass': reg_password, 'user_name': reg_name
        }
        chk_user_signUp_otp(options)
            .then(
                (resolve) => {
                    if (resolve['status'] == '2') {
                        let getAlert2 = () => (
                            <SweetAlert input showCancel required cancelBtnBsStyle="light" inputType="number"
                                validationMsg="Please enter otp" title={resolve.message} placeHolder={resolve.message}
                                onConfirm={(response) => { this.user_registration_verify(response) }}
                                closeOnClickOutside={false}
                                onCancel={() => { this.setState({ alert: null }); }} />
                        );
                        this.setState({ loading: false, alert: getAlert2() });
                    }
                    else if (resolve['status'] == '1') {
                        let getAlert1 = () => (
                            <SweetAlert success title={resolve.message}
                                onConfirm={() => {
                                    localStorage.setItem('app_token', (resolve.data['m_u_token']));
                                    localStorage.setItem('login_token', (resolve.data['m_u_token']));
                                    localStorage.setItem('client_id', (resolve.data['muser_id']));
                                    localStorage.setItem('client_contact', (resolve.data['muser_mobno']));
                                    localStorage.setItem('client_name', (resolve.data['muser_name']));
                                    localStorage.setItem('profileImg', (localStorage.getItem('base_url') + resolve.data['profile_img']));
                                    this.setState({ userLogin: true });
                                    this.setState({ alert: null }); window.location.reload(false);
                                }} />
                        );
                        this.setState({ loading: false, alert: getAlert1() });
                    }
                    else {
                        let getAlert1 = () => (
                            <SweetAlert warning title={resolve.message} onConfirm={() => { this.setState({ alert: null }); }} />
                        );
                        this.setState({ loading: false, alert: getAlert1() });
                    }
                },
                (reject) => {
                    console.log(reject);
                }
            )
    }
    user_password_verify = (forget_contact, newPassword, newPassword2, newOtp) => {

        let options = {
            'user_mobile': forget_contact, 'm_u_token': this.state.app_token
            , 'input_otp': newOtp, 'plateform': 'WEB', 'device_token': '', 'user_new_pass': newPassword
        }
        if (newPassword == '' || String(newPassword).length < 6) {
            document.getElementById("p_text1").innerHTML = "Password length must be 6 or greater then 6";
        }
        else if (newPassword != newPassword2) {
            document.getElementById("p_text2").innerHTML = "Both password not match";
        }
        else if (newOtp == '') {
            document.getElementById("p_text3").innerHTML = "Otp not be empty";
        }
        else {
            get_data_all('m/update_forget_pass', options)
                .then(
                    (resolve) => {
                        if (resolve['status'] == '2') {
                            let getAlert2 = () => (
                                <SweetAlert type={'controlled'} showCancel onConfirm={() => { this.user_password_verify(forget_contact, this.state.forgetPassword, this.state.forgetPassword2, this.state.verificationOtp) }} closeOnClickOutside={false}
                                    onCancel={() => { this.setState({ alert: null }); }} title={"Reset Your Login Password"}
                                    dependencies={[this.state.forgetPassword, this.state.forgetPassword2, this.state.verificationOtp]}>
                                    {(renderProps: SweetAlertRenderProps) => (
                                        <form>
                                            <div className="login-modal-right">
                                                <fieldset className="form-group text-left">
                                                    <label>Enter Password</label>
                                                    <input type={"password"} className="form-control" onKeyDown={renderProps.onEnterKeyDownConfirm}
                                                        placeholder={"Enter New Password"} name={"forgetPassword"}
                                                        onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                                                    <p className="text-danger" id="p_text1"></p>
                                                </fieldset>
                                                <fieldset className="form-group text-left">
                                                    <label>Enter Confirm Password</label>
                                                    <input type="password" className="form-control" onKeyDown={renderProps.onEnterKeyDownConfirm}
                                                        placeholder="Enter Confirm Password" name={"forgetPassword2"}
                                                        onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                                                    <p className="text-danger" id="p_text2"></p>
                                                </fieldset>
                                                <fieldset className="form-group text-left">
                                                    <label>Enter Otp</label>
                                                    <input type="text" className="form-control" onKeyDown={renderProps.onEnterKeyDownConfirm}
                                                        placeholder="Enter verification otp" name={"verificationOtp"}
                                                        onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                                                    <p className="text-danger" id="p_text3"></p>
                                                </fieldset>
                                                <p>{resolve['message']}</p>
                                            </div>
                                        </form>
                                    )}
                                </SweetAlert>
                            );
                            this.setState({ loading: false, alert: getAlert2() });
                        }
                        else if (resolve['status'] == '1') {
                            let getAlert1 = () => (
                                <SweetAlert success title={resolve.message}
                                    onConfirm={() => {
                                        localStorage.setItem('app_token', (resolve.data['m_u_token']));
                                        localStorage.setItem('login_token', (resolve.data['m_u_token']));
                                        localStorage.setItem('client_id', (resolve.data['muser_id']));
                                        localStorage.setItem('client_contact', (resolve.data['muser_mobno']));
                                        localStorage.setItem('client_name', (resolve.data['muser_name']));
                                        this.setState({ userLogin: true });
                                        this.setState({ alert: null }); window.location.reload(false);
                                    }} />
                            );
                            this.setState({ loading: false, alert: getAlert1() });
                        }
                        else {
                            let getAlert1 = () => (
                                <SweetAlert warning title={resolve.message} onConfirm={() => { this.setState({ alert: null }); }} />
                            );
                            this.setState({ loading: false, alert: getAlert1() });
                        }
                    },
                    (reject) => {
                        console.log(reject);
                    }
                )
        }
    }
    render() {
        return (
            <div>
                {this.state.alert}
                <div className="modal fade login-modal-main" id="bd-example-modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="login-modal">
                                    <div className="row">
                                        <div className="col-lg-6 pad-right-0">
                                            <div className="login-modal-left">
                                                {
                                                    (this.state.exactimage_status == true)
                                                        ?
                                                        <img className="" src={this.state.exactimage} />
                                                        :
                                                        ""
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6 pad-left-0">
                                            <button type="button" className="close close-top-right" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true"><i className="mdi mdi-close"></i></span>
                                                <span className="sr-only">Close</span>
                                            </button>
                                            <div className="login-modal-right">
                                                <div className="tab-content">
                                                    {this.state.login_tab == true ?
                                                        <div className="tab-pane active">
                                                            <h5 className="heading-design-h5">Login to your account</h5>
                                                            <fieldset className="form-group">
                                                                <label>Enter Mobile No./Email id</label>
                                                                <input type="text" className="form-control" onChange={(e) => this.setState({ client_contact: e.target.value })} />
                                                                {this.state.mobileIsValid == true ?
                                                                    <span className="login-error">Enter Valid Mobile No./Email</span>
                                                                    : ''
                                                                }
                                                            </fieldset>
                                                            <fieldset className="form-group">
                                                                <label>Enter Password</label>
                                                                <input type="password" className="form-control" onChange={(e) => this.setState({ client_password: e.target.value })} />
                                                                {this.state.passwordIsValid == true ?
                                                                    <span className="login-error">Enter Valid Password</span>
                                                                    : ''
                                                                }
                                                            </fieldset>
                                                            {this.state.error_message != '' ? <span className="login-error-message">{this.state.error_message}</span> : ''}
                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                                                <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                                                            </div>
                                                            <fieldset className="form-group">
                                                                <button className="btn btn-md btn-info btn-block" disabled={this.state.loading} onClick={() => this.login()}>{this.state.loading && <i className="mdi mdi-refresh mdi-spin" aria-hidden="true"></i>} Login</button>
                                                            </fieldset>
                                                            <center style={{ marginTop: '-12px' }}>Or</center>
                                                            {/* <p>Have you forgotten your password ?? <span onClick={() => this.setState({ register_tab: false, login_tab: false, forgateTab: true })} className="cursor text-danger">click here</span></p> */}
                                                            <fieldset className="form-group">
                                                                <button type="button" className="btn btn-sm btn-warning btn-block" onClick={() => this.setState({ register_tab: false, login_tab: false, forgateTab: true })}>Forget Password</button>
                                                            </fieldset>
                                                            {/* <div className="login-with-sites text-center">
                                                                <p>or Login with your social profile:</p>
                                                                <button className="btn-facebook login-icons btn-lg"><i className="mdi mdi-facebook"></i> Facebook</button>
                                                                <button className="btn-google login-icons btn-lg"><i className="mdi mdi-google"></i> Google</button>
                                                                <button className="btn-twitter login-icons btn-lg"><i className="mdi mdi-twitter"></i> Twitter</button>
                                                            </div> */}

                                                        </div>
                                                        : ''}
                                                    {this.state.register_tab == true ?
                                                        <div className="tab-pane active">
                                                            <h5 className="heading-design-h5">Register Now!</h5>
                                                            <fieldset className="form-group">
                                                                <label>Enter Full Name</label>
                                                                <input type="text" className="form-control" onChange={(e) => this.setState({ reg_name: e.target.value })} placeholder="Name" />
                                                                {this.state.nameIsValid == true ?
                                                                    <span className="login-error">Enter User Name</span>
                                                                    : ''
                                                                }
                                                            </fieldset>
                                                            <fieldset className="form-group">
                                                                <label>Enter Mobile number</label>
                                                                <input type="text" minLength={10} onInput={(e) => this.only_number(e)} maxLength={10} className="form-control" onChange={(e) => this.setState({ reg_mobile: e.target.value })} />
                                                                {this.state.mobile2IsValid == true ?
                                                                    <span className="login-error">Enter Mobile Number</span>
                                                                    : ''
                                                                }
                                                            </fieldset>
                                                            <fieldset className="form-group">
                                                                <label>Enter Password</label>
                                                                <input type="password" className="form-control" onChange={(e) => this.setState({ reg_password: e.target.value })} />
                                                                {this.state.password1IsValid == true ?
                                                                    <span className="login-error">Enter Valid Password</span>
                                                                    : ''
                                                                }
                                                            </fieldset>
                                                            <fieldset className="form-group">
                                                                <label>Again Enter Password </label>
                                                                <input type="password" className="form-control" onChange={(e) => this.setState({ reg_re_password: e.target.value })} />
                                                                {this.state.password2IsValid == true ?
                                                                    <span className="login-error">Enter Valid Confirm Password</span>
                                                                    : ''
                                                                }
                                                            </fieldset>
                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" id="customCheck2" />
                                                                <label className="custom-control-label" htmlFor="customCheck2">I Agree with <a href="/terms-condition">Term and Conditions</a></label>
                                                            </div>
                                                            <fieldset className="form-group">
                                                                <button type="button" className="btn btn-lg btn-secondary btn-block" disabled={this.state.loading} onClick={() => this.register()}>{this.state.loading && <i className="mdi mdi-refresh mdi-spin" aria-hidden="true"></i>} Create Your Account</button>
                                                            </fieldset>
                                                        </div>
                                                        : ''}
                                                    {this.state.forgateTab == true ?
                                                        <div className="tab-pane active">
                                                            <h5 className="heading-design-h5">Recover your forget login password</h5>
                                                            <fieldset className="form-group">
                                                                <label>Enter Mobile No.</label>
                                                                <input type="text" maxLength={10} className="form-control" onInput={(e) => this.only_number(e)} onChange={(e) => this.setState({ forget_contact: e.target.value })} />
                                                                {this.state.forget_contactValid == true ?
                                                                    <span className="login-error">Enter Valid Mobile Number</span>
                                                                    : ''
                                                                }
                                                            </fieldset>
                                                            <fieldset className="form-group">
                                                                <button className="btn btn-sm btn-warning btn-block" style={{ fontSize: '20px' }} disabled={this.state.loading} onClick={() => this.forget()}> {this.state.loading && <i className="mdi mdi-refresh mdi-spin" aria-hidden="true"></i>} Submit</button>
                                                            </fieldset>
                                                        </div>
                                                        : ''}
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className="text-center login-footer-tab">
                                                    <ul className="nav nav-tabs" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link" disabled={this.state.register_tab} onClick={() => this.setState({ register_tab: false, login_tab: true, forgateTab: false, exactimage: this.state.loginImg })}><i className="mdi mdi-lock"></i> LOGIN</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" disabled={this.state.login_tab} onClick={() => this.setState({ register_tab: true, login_tab: false, forgateTab: false, exactimage: this.state.signupImg })}><i className="mdi mdi-pencil"></i> REGISTER</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Login);