import React, { Component, memo } from 'react';
import { Link } from 'react-router-dom';
import logo2 from '../assets/img/logo2.png';
import Login from './Login.js';
import { fetch_cart_data, remove_cart_product } from './Cart_Action.js';
import { fetch_product_main_group, get_data_all } from './Fetch_All_data.js';
//import Location_mdl from './Location_mdl.js';
import user from '../assets/img/user.jpg';
import { cart_service } from '../_services/cart.service.js';
import { cart_toggle, remove_btn_from_html, header_cart_plus_minus } from '../assets/custom.js';
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userLogin: false,
            cat_isLoaded: false,
            cart_isLoaded: false,
            show_popup: false,
            profileImg: localStorage.getItem('profileImg') || '',
            product_pincode: '',
            actual_area_name: '',
            product_search_value: '',
            cityname: '',
            display_pincode: false,
            product_search_count: 0,
            cart_product_count: 0,
            wish_count: 0,
            category: [],
            search_product: [],
            cart_product: []
        };
        fetch_product_main_group()
            .then(
                (resolve) => {
                    if (resolve['status'] == 1) {
                        this.setState({
                            category: resolve['data'],
                            cat_isLoaded: true
                        });
                    }
                },
                (reject) => {
                    console.log(reject)
                }
            )
    }
    sel_loaction = () => {
        this.setState({ show_popup: true })
    }
    get_header_user_profile() {
        const login_token = localStorage.getItem('login_token');
        const client_contact = localStorage.getItem('client_contact');
        const actual_product_pincode = localStorage.getItem('product_pincode');
        const actual_area_name = localStorage.getItem('area_name');
        const client_name = localStorage.getItem('client_name');
        const cityname = localStorage.getItem('cityname');
        if (actual_product_pincode) {
            //this.setState({ display_pincode: true, cityname: cityname, product_pincode: actual_product_pincode, actual_area_name: actual_area_name, show_popup: false });
        }
        (login_token && client_contact) ?

            this.setState({ userLogin: true, client_name: client_name })

            : this.setState({ userLogin: false })
    }
    componentDidUpdate(prevProps) {
        //this.count_cart_product();
    }
    componentWillUnmount() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
    }
    count_cart_product = () => {
        const actual_area_id = localStorage.getItem('actual_area_id') ? localStorage.getItem('actual_area_id') : 0;
        get_data_all('m/count_cart_product', { 'm_u_token': localStorage.getItem('app_token'), 'area_id': actual_area_id })
            .then(
                (result) => {
                    console.log(result.count)
                    this.setState({ cart_product_count: result.count });
                },
                (error) => {
                    console.log(error);
                }
            )
    }
    get_user_cart = () => {
        const actual_area_id = localStorage.getItem('actual_area_id') ? localStorage.getItem('actual_area_id') : 0;
        fetch_cart_data(actual_area_id)
            .then(
                (resolve) => {
                    if (resolve['status'] == 1) {
                        this.setState({
                            cart_isLoaded: true,
                            cart_product: resolve['data'],
                            cart_product_count: resolve['data'].length,
                            wish_count: resolve['wish_count']
                        });
                    }
                    else {
                        this.setState({
                            cart_isLoaded: false,
                            cart_product: []
                        });
                    }
                },
                (reject) => {
                    console.log(reject)
                }
            )
    }
    removecart = (product_price_id, cart_id, productId) => {
        remove_cart_product(product_price_id, cart_id)
            .then(
                (resolve) => {
                    if (resolve['status'] == 1) {
                        remove_btn_from_html(productId, cart_id, resolve['cart_value'])
                        //const items = this.state.cart_product.filter(item => item.c_id !== cart_id);
                        this.count_cart_product();
                        const items = resolve['data'] || [];
                        this.setState({
                            cart_isLoaded: true,
                            cart_product: items
                        });
                    }
                },
                (reject) => {
                    console.log(reject)
                }
            )
    }
    componentDidMount() {
        cart_toggle()
        header_cart_plus_minus()
        this.get_header_user_profile()
        let mythis = this;
        setTimeout(function () {
            mythis.get_user_cart();
        }, 2000);
        this.subscription = cart_service.getData().subscribe(data => {
            if (data) {
                if (data) {
                    this.count_cart_product();
                }
            }
        })
    }
    search_product = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.search_product_action(e.target.value);
    }
    search_product_action = (searchKey) => {
        let data = { 'search_key': searchKey, 'm_u_token': localStorage.getItem('app_token'), 'plateform': 'WEB' };
        if (searchKey == '') {
            this.setState({
                search_product: []
            });
            document.getElementsByClassName("searchdiv")[0].style.display = "none";
        }
        else {
            get_data_all('m/product_search', data)
                .then(
                    (resolve) => {
                        if (resolve['status'] == 1) {
                            this.setState({
                                search_product: resolve['data']
                            });
                            document.getElementsByClassName("searchdiv")[0].style.display = "block";
                        }
                        else {
                            this.setState({
                                search_product: []
                            });
                            document.getElementsByClassName("searchdiv")[0].style.display = "none";
                        }
                    },
                    (reject) => {
                        console.log(reject)
                    }
                )
        }
    }
    click_search_input = () => {
        let search_product = this.state.search_product;
        if (search_product.length > 0) {
            document.getElementsByClassName("searchdiv")[0].style.display = "block";
        }
        else {
            //let category = this.state.category;
            document.getElementsByClassName("searchdiv")[0].style.display = "none";
        }
    }
    handleCallback = () => {
        this.setState({ show_popup: false });
    }
    render() {
        const { userLogin, cat_isLoaded, cityname, category, display_pincode, product_pincode, show_popup, actual_area_name, cart_product_count, cart_isLoaded, cart_product } = this.state;
        var payable_total = 0;
        var total_p_discount = 0;
        var total_actual_price = 0;
        return (
            <>
                {/* <Location_mdl parentCallback={this.handleCallback} display_popup={show_popup} /> */}
                <Login />
                {/* <div className="navbar-top bg-custom1 pt-2 pb-2">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <Link to="" onClick={e => e.preventDefault()} className="mb-0 text-white">
                                    20% cashback for new users | Code: <strong><span className="text-light">A1Mart <span className="mdi mdi-tag-faces"></span></span> </strong>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="loader-container">
                    <span className="loader"></span>
                </div>
                <nav className="navbar navbar-light navbar-expand-lg bg-bsquar-custom bg-faded osahan-menu">
                    <div className="container-fluid justify-content-start">
                        <button className="navbar-toggler navbar-toggler-white" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <Link className="navbar-brand" to="/" refresh="true"> <img src={logo2} alt="Harpra Fashion Store" /> </Link>
                        {/* {display_pincode == true ?
                            <a type="button" className="location-top" onClick={() => this.sel_loaction()}><i className="mdi mdi-map-marker-circle" aria-hidden="true"></i> {cityname} - {actual_area_name}</a>
                            : ''} */}
                        <div className="navbar-collapse" id="navbarNavDropdown">
                            <div className="navbar-nav mr-auto mt-2 mt-lg-0 margin-auto top-categories-search-main">
                                <div className="top-categories-search">
                                    <div className="input-group">
                                        <input className="form-control" name="product_search_value" value={this.state.product_search_value} onClick={() => this.click_search_input()} onChange={(e) => this.search_product(e)} placeholder="Search product" aria-label="Search product" type="text" />
                                        <span className="input-group-btn">
                                            <button className="btn" type="button"><i className="mdi mdi-magnify"></i></button>
                                        </span>
                                    </div>
                                    <div className="row">
                                        <div className="searchcontent">
                                            <div className="searchdiv">
                                                <div className="thedata bg-white mt-2">
                                                    <div className="row">
                                                        {this.state.search_product.map((product, index) => {
                                                            return (
                                                                <div className="col-12 mx-0" key={"search" + index}>
                                                                    <article className="product-modern text-sm-left">
                                                                        <div className="unit unit-spacing-0 flex-column flex-sm-row">
                                                                            <div className="row">
                                                                                <div className="col-lg-2 col-md-2 col-xs-3 mx-0 col-sm-3 text-center">
                                                                                    <a className="product-modern-figure"
                                                                                        href={(product.search_type == 'Product') ?
                                                                                            ("product/" + product.url_slug + '/' + product.search_id)
                                                                                            :
                                                                                            (product.search_type == 'Group' && product.parent_id == '0') ?
                                                                                                ("/category/main/" + product.url_slug + '/' + product.search_id)
                                                                                                :
                                                                                                (product.search_type == 'Group' && product.parent_id != '0') ?
                                                                                                    ("/category/sub/" + product.url_slug + '/' + product.search_id)
                                                                                                    :
                                                                                                    ("/category/brand/" + product.url_slug + '/' + product.search_id)
                                                                                        }>
                                                                                        <img style={{ height: '60px', width: 'auto' }} src={product.img_paths} alt={product.serach_name} />
                                                                                    </a>
                                                                                </div>
                                                                                <div className="col-lg-10 col-md-10 col-xs-9 col-sm-9 mx-0" id="fontSrchStg">
                                                                                    <h6>
                                                                                        <a href={(product.search_type == 'Product') ?
                                                                                            ("product/" + product.url_slug + '/' + product.search_id)
                                                                                            :
                                                                                            (product.search_type == 'Group' && product.parent_id == '0') ?
                                                                                                ("/category/main/" + product.url_slug + '/' + product.search_id)
                                                                                                :
                                                                                                (product.search_type == 'Group' && product.parent_id != '0') ?
                                                                                                    ("/category/sub/" + product.url_slug + '/' + product.search_id)
                                                                                                    :
                                                                                                    ("/category/brand/" + product.url_slug + '/' + product.search_id)
                                                                                        }>
                                                                                            {product.serach_name}
                                                                                        </a>
                                                                                    </h6>
                                                                                    <a className="btn btn-sm buyBtn"
                                                                                        href={(product.search_type == 'Product') ?
                                                                                            ("product/" + product.url_slug + '/' + product.search_id)
                                                                                            :
                                                                                            (product.search_type == 'Group' && product.parent_id == '0') ?
                                                                                                ("/category/main/" + product.url_slug + '/' + product.search_id)
                                                                                                :
                                                                                                (product.search_type == 'Group' && product.parent_id != '0') ?
                                                                                                    ("/category/sub/" + product.url_slug + '/' + product.search_id)
                                                                                                    :
                                                                                                    ("/category/brand/" + product.url_slug + '/' + product.search_id)
                                                                                        }><i className="mdi mdi-flash-outline"></i> Buy Now
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </article>
                                                                    <hr id="lineHgtCls" />
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-2 my-lg-0">
                                <ul className="list-inline main-nav-right d-flex align-items-center">
                                    {userLogin == false ?
                                        <li className="list-inline-item user-account">
                                            <Link to="/" type="button" onClick={e => e.preventDefault()} data-target="#bd-example-modal" data-toggle="modal" className="btn btn-link"><i className="mdi mdi-account-circle"></i> Login/Sign Up</Link>
                                        </li>
                                        :
                                        <li className="list-inline-item dropdown osahan-top-dropdown user-account">
                                            <Link className="btn dropdown-toggle dropdown-toggle-top-user" to="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <img alt={this.state.client_name || ''} onError={(e) => { e.target.onerror = null; e.target.src = user }} src={this.state.profileImg} /><strong>Hi</strong> {this.state.client_name || ''}
                                            </Link>
                                            <div className="dropdown-menu dropdown-menu-right dropdown-list-design">
                                                <Link to="/myprofile" className="dropdown-item"><i aria-hidden="true" className="mdi mdi-account-outline" />  My Profile</Link>
                                                <Link to="/myaddress" className="dropdown-item"><i aria-hidden="true" className="mdi mdi-map-marker-circle" />  Manage Address</Link>
                                                {/* <Link to="/wishlist" className="dropdown-item"><i aria-hidden="true" className="mdi mdi-heart-outline" />  Wish List </Link> */}
                                                <Link to="/orderlist" className="dropdown-item"><i aria-hidden="true" className="mdi mdi-format-list-bulleted" />  My Order</Link>
                                                <div className="dropdown-divider" />
                                                <Link className="dropdown-item" to="/logout"><i className="mdi mdi-lock" /> Logout</Link>
                                            </div>
                                        </li>
                                    }
                                    <li className="list-inline-item cart-btn">
                                        <button type="button" onClick={(e) => this.get_user_cart()} data-toggle="offcanvas" className="btn btn-link border-none"><i className="mdi mdi-cart"></i> My Cart <small className="cart-value">{cart_product_count}</small></button>
                                    </li>
                                    {/* <li className="list-inline-item cart-btn">
                                        <Link to ="/wishlist" className="btn btn-link border-none"><i className="mdi mdi-heart"></i> My Wish List <small className="wishList-value">{this.state.wish_count}</small></Link>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                <nav className="navbar navbar-expand-lg navbar-light osahan-menu-2 pad-none-mobile">
                    <div className="container-fluid">
                        <div className="collapse navbar-collapse" id="navbarText">
                            <ul className="navbar-nav mr-auto mt-2 mt-lg-0 margin-auto">
                                {/* <li className="nav-item dropdown">
                                    <Link className="nav-link shop dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" onClick={e => e.preventDefault()} to="/" refresh="true"><span className="mdi mdi-store"></span> All Category</Link>
                                    <div className="dropdown-menu">
                                        {cat_isLoaded == true ?
                                            category.slice(0, 8).map((categorys, index) => {
                                                return (
                                                    <a key={'header' + index} className="dropdown-item" href={"/category/main/" + ((categorys.group_name + "/" + categorys.id}><i className="mdi mdi-chevron-right" aria-hidden="true"> </i>  {categorys.group_name}</a>
                                                )
                                            })
                                            : ''}
                                    </div>
                                </li> */}
                                <li className="nav-item">
                                    <Link to="/" className="nav-link" refresh="true">Home</Link>
                                </li>
                                {cat_isLoaded == true ?
                                    category.slice(0, 2).map((categorys, index) => {
                                        return (
                                            <li className="nav-item" key={'header' + index}>
                                                <Link to={"/category/main/" + categorys.group_slug + "/" + categorys.id} className="nav-link" refresh="true">{categorys.group_name}</Link>
                                            </li>
                                        )
                                    })
                                    : ''}
                                <li className="nav-item">
                                    <Link to="/new-arrivals" className="nav-link" refresh="true">New Arrivals</Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link to="/our-team" className="nav-link" refresh="true">Our Team</Link>
                                </li> */}
                                {/* <li className="nav-item">
                                    <Link to="/our-certificate" className="nav-link" refresh="true">Our Certificate</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/quality-policy" className="nav-link" refresh="true">Quality Policy</Link>
                                </li> */}
                                {/* <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" onClick={e => e.preventDefault()} to="/" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        My Account
                                    </Link>
                                    <div className="dropdown-menu">
                                        {userLogin == true ?
                                            <div>
                                                <Link className="dropdown-item" to="/myprofile"><i className="mdi mdi-chevron-right" aria-hidden="true"> </i>  My Profile</Link>
                                                <Link className="dropdown-item" to="/myaddress"><i className="mdi mdi-chevron-right" aria-hidden="true"> </i>  Manage Address</Link>
                                                {/* <Link className="dropdown-item" to="/wishlist"><i className="mdi mdi-chevron-right" aria-hidden="true"> </i>  Wish List </Link> */}
                                {/* <Link className="dropdown-item" to="/orderlist"><i className="mdi mdi-chevron-right" aria-hidden="true"> </i> My Order</Link>
                                                <Link className="dropdown-item" to="/logout"><i className="mdi mdi-lock" aria-hidden="true"> </i>  Logout</Link>
                                            </div>
                                            :
                                            <a href="#" type="button" data-target="#bd-example-modal" data-toggle="modal" className="btn btn-link"><i className="mdi mdi-account-circle"></i> Login/Sign Up</a>
                                        }
                                    </div>
                                </li> */}
                                <li className="nav-item">
                                    <Link className="nav-link" to="/sale" refresh="true" target="_self">Sale</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/our-blog" className="nav-link" refresh="true">Our Blog</Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link className="nav-link" to="/about" refresh="true" target="_self">About Us</Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact" refresh="true" target="_self">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="cart-sidebar">
                    <div className="cart-sidebar-header">
                        <h5>
                            My Cart <span className="text-green">({cart_product_count} item)</span>
                            <Link data-toggle="offcanvas" className="float-right" to="#">
                                <i className="mdi mdi-close"></i>
                            </Link>
                        </h5>
                    </div>
                    <div className="cart-sidebar-body">
                        {cart_isLoaded == true && cart_product.length > 0 ?
                            cart_product.map((cart, index) => {
                                payable_total = payable_total + parseFloat(cart.total_price);
                                total_p_discount = total_p_discount + parseFloat(cart.p_discount);
                                total_actual_price = total_actual_price + parseFloat(cart.actual_price * cart.p_qty);
                                return (
                                    <div className="cart-list-product" key={"cart_" + cart.c_id + "_" + cart.price_id}>
                                        <Link to={"/product/" + cart.product_slug + "/" + cart.product_id} target="_blank">
                                            <img className="img-fluid" src={cart.img_paths} />
                                        </Link>
                                        {cart.p_discount && cart.p_discount > 0 && <span className="badge text-green">₹{cart.p_discount} OFF</span>}
                                        <h5>{cart.p_name}</h5>
                                        {/* <span>{cart.p_unit_qty}{cart.p_unit}</span> */}
                                        <div className='mt-3'>
                                            <div className="table-cart-stepper float-left">
                                                <div className="number-style text-center">
                                                    <span data-product-id={(cart.product_id)} data-product-operater="-" data-price-id={cart.price_id} className="number-minus headerchangeproductQty"></span>
                                                    <input type="number" readOnly className={"product_" + cart.product_id} value={cart.p_qty} />
                                                    <span data-product-id={(cart.product_id)} data-product-operater="+" data-price-id={cart.price_id} className="number-plus headerchangeproductQty"></span>
                                                </div>
                                            </div>
                                            x  <span className="offer-price">   ₹{cart.sale_price} </span>
                                            <span className="ml-1"></span>
                                            {cart.p_discount && cart.p_discount > 0 && <span className="regular-price">   ₹<span id={"cart_total_mrp_price_" + cart.price_id}>{parseInt(cart.p_qty) * parseFloat(cart.actual_price)}</span>  </span>}
                                            <span className="total-right-price float-right">₹<span id={"cart_total_price_" + cart.price_id}>{cart.total_price}</span>  </span>
                                        </div>
                                        <Link className="remove-cart" title="Remove from cart" onClick={() => this.removecart(cart.price_id, cart.c_id, cart.product_id)} to="#"><i className="mdi mdi-delete"></i></Link>
                                    </div>
                                )
                            })
                            :
                            <div className="text-center mt-3">
                                <h6>Your Cart is Empty</h6>
                            </div>
                        }
                    </div>
                    <div className="cart-sidebar-footer">
                        <div className="cart-store-details">
                            <p>Cart Subtotal<strong className="float-right">₹<span id="total_cart_price">{total_actual_price}</span></strong></p>
                            <p>Saving Amount <strong className="float-right text-danger">- ₹<span id="total_cart_discount">{total_p_discount} </span></strong></p>
                            <p>Total Amount<strong className="float-right">₹<span id="total_cart_payble">{payable_total}</span></strong></p>
                        </div>
                        <a href="/payment-continue"><button className="btn btn-primary btn-lg btn-block text-left" target="_self"><span className="float-left"><i className="mdi mdi-cart-outline"></i> Proceed to Checkout </span><span className="float-right"><strong>₹<span id="total_payble_price">{payable_total}</span></strong> <span className="mdi mdi-chevron-right"></span></span></button></a>
                    </div>
                </div>
            </>
        );
    }
}
export default memo(Header);