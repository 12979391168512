import $ from 'jquery';
export { cart_plus_minus, add_cart_plus,update_cart_value_text }
function update_cart_value_text()
{
  var old_cart_value = parseInt($('.cart-value').text());
  $('.cart-value').text(old_cart_value-1);
}
function cart_plus_minus(cart_add_quantity, price_id, cart_id, product_id, quantity,data) {
  $(document).ready(function() {
  cart_add_quantity(price_id, product_id, quantity)
    .then(
      (resolve) => {
        if (resolve['status'] == 1) {
          var product_price = "₹ " + parseInt(quantity) * parseFloat(data.sale_price);
          //$("#product_mrp_price_" + cart_id).html("₹ " + parseFloat(data.actual_price));
         // $("#product_price_" + cart_id).html(product_price);
          $("#cart_quantity_" + cart_id).val(quantity);
          var cart_value = resolve['cart_value'];
          $("#total_cart_price").text(cart_value.total_mrp_amount||0);
          $("#total_cart_discount").text(cart_value.discount||0);
          $("#total_cart_payble").text(cart_value.total_amount||0);
          $("#total_payble_price").text(cart_value.total_amount||0);
          var plus_btn = $("#plus_btn_" + cart_id);
          var minus_btn = $("#minus_btn_" + cart_id);
          if (quantity == 1) {
            minus_btn.addClass('disabled');
            plus_btn.removeClass('disabled');
          }
          else if (quantity == data.max_capacity) {
            minus_btn.removeClass('disabled');
            plus_btn.addClass('disabled');
          }
          else {
            minus_btn.removeClass('disabled');
            plus_btn.removeClass('disabled');
          }
        }
      },
      (reject) => {
        console.log(reject)
      }
    )
  })
}
function add_cart_plus(cart_add_quantity, price_id, product_id, quantity) {
  cart_add_quantity(price_id, product_id, quantity)
    .then(
      (resolve) => {
        if (resolve['status'] == 1) 
        {
          const data = resolve['data'];
          var content1 ="<p class='offer-price mb-0'><span id='product_price_" + (resolve['cart_id'])+"'>₹ "+data['unit_price']+"</span>";
          var content2='';
          if(data['discount'] > 0)
          {
            content2="<span class='regular-price' id='product_mrp_price_"+(resolve['cart_id'])+"'>  ₹ "+(data['product_mrp_price'])+"</span>";
          }
          var content3="</p><div class='table-cart-stepper'><div class='number-style cat-number-style text-center'>";
          
          var content4="<span id='minus_btn_" + resolve['cart_id']+"' onClick='"+this.change_quantity(resolve['cart_id'], data['product_price_id'], data['product_id'], '-')+"' class='number-minus'></span>";
          var content5="<input value='1' type='number' id='cart_quantity_" + resolve['cart_id']+"' readOnly />";
          var content6="<span id='plus_btn_"+ resolve['cart_id']+"' onClick='"+this.change_quantity(resolve['cart_id'], data['product_price_id'], data['product_id'], '+')+"' class='number-plus'></span>"; 
          var content7="</div></div>";
          $("#grid_cart_has_" + product_id).replaceWith(content1 + content2 + content3 + content4 + content5+content6+content7);
        }
      },
      (reject) => {
        console.log(reject)
      }
    )
}