import React from 'react';
import {Redirect} from 'react-router-dom';
function Login_check(props)
{
    const Cmp = props.cmp;
    const refer = props.refer;
    var login_token  =  localStorage.getItem('login_token');
    var client_token =  localStorage.getItem('client_contact');
    return <div>{client_token&&login_token ? <Cmp /> : <Redirect to={"login?refer="+refer}></Redirect>}</div>
}
export default Login_check;