import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import { get_data_all } from '../components/Fetch_All_data.js';
import { NotificationContainer, NotificationManager } from 'react-notifications';
class Contact extends Component {
   constructor(props) {
      super(props);
      this.state = {
         contents: [],
         data_load: false,
         alert: null,
         loading: false
      };
      this.onChange = this.onChange.bind(this);
   }
   onChange(e) {
      this.setState({ [e.target.name]: e.target.value });
   }
   only_number = (e) => {
      e.target.value = e.target.value.replace(/[^0-9.]/g, ''); e.target.value = e.target.value.replace(/(\..*)\./g, '$1');
   }
   componentDidMount() {
      this.get_page_data();
   }
   get_page_data = () => {
      let options = { 'page_type': 'about' };
      get_data_all('m/contact-page', options)
         .then(
            (resolve) => {
               this.setState({ contents: resolve, data_load: true })
            },
            (reject) => {
               console.log(reject);
            }
         )
   }
   update_password() {
      let form_data = this.state;
      if (form_data.name == '') {
         NotificationManager.warning('Warning', "Enter Name");
      }
      else if (form_data.phone == '') {
         NotificationManager.warning('Warning', "Enter Phone");
      }
      else if (form_data.email == '') {
         NotificationManager.warning('Warning', "Enter Email");
      }
      else if (form_data.subject == '') {
         NotificationManager.warning('Warning', "Enter Subject");
      }
      else if (form_data.message == '') {
         NotificationManager.warning('Warning', "Message");
      }
      else {
         this.setState({ loading: true });
         get_data_all('m/submit-contact-us', { name: form_data.name, phone: form_data.phone, email: form_data.email, subject: form_data.subject, message: form_data.message })
            .then((resolve) => {
               if (resolve['status'] == 'success') {
                  const getAlert1 = () => (
                     <SweetAlert success title={resolve['message']} onConfirm={() => { this.setState({ alert: null, loading: false }); window.location.reload() }} />
                  );
                  this.setState({ alert: getAlert1() });
               }
               else {
                  const getAlert1 = () => (
                     <SweetAlert warning title={resolve['message']} onConfirm={() => { this.setState({ alert: null, loading: false }); }} />
                  );
                  this.setState({ alert: getAlert1() });
               }
            },
               (reject) => {
                  console.log(reject);
                  const getAlert1 = () => (
                     <SweetAlert warning title={"Server not responding"} onConfirm={() => { this.setState({ alert: null, loading: false }); }} />
                  );
                  this.setState({ alert: getAlert1() });
               })
      }
   }
   render() {
      const { contents, data_load } = this.state;
      return (
         <div>
            <Helmet>
               <title>Contact Us | Harpra</title>
            </Helmet>
            <NotificationContainer />
            <div className="page-container">
               {this.state.alert}
               <section className="inner-header">
                  <div className="container">
                     <div className="row">
                        <div className="col-md-12 text-center">
                           <h1 className="mt-3 mb-2 text-black">Contact Us</h1>
                           <div className="breadcrumbs">
                              <p className="mb-2 text-black"><Link className="text-black" to="/">Home</Link>  /  <span className="text-success">Contact Us</span></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="contactUs bg-white">
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-5 col-md-5">
                           <h3 className="mt-2 mb-2">Get In Touch</h3>
                           {contents.address || contents.address2 ?
                              <>
                                 <h6 className="text-dark"><i className="mdi mdi-home-map-marker"></i> Address :</h6>
                                 <p> {contents.address || ''}<br/> {contents.address2 || ''}</p>
                              </>
                              : <></>}
                           {/* <h6 className="text-dark"><i className="mdi mdi-phone"></i> Phone :</h6>
                        <p>{contents.p || ''}</p> */}
                           <h6 className="text-dark"><i className="mdi mdi-deskphone"></i> Mobile :</h6>
                           <p>{contents.mobile_number || ''}</p>
                           <h6 className="text-dark"><i className="mdi mdi-whatsapp"></i> whatsapp :</h6>
                           <p>{contents.whatsup_number || ''}</p>
                           <h6 className="text-dark"><i className="mdi mdi-email"></i> Email :</h6>
                           <p>{contents.email_id || ''}{(contents && contents.email_id2) ? ', ' + contents.email_id2 : ''}</p>
                           <div className="footer-social"><span>Follow : </span>
                              <a href={contents.facebook_url || ''} target="_blank"><i className="mdi mdi-facebook"></i></a>
                              <a href={contents.twitter_url || ''} target="_blank"><i className="mdi mdi-twitter"></i></a>
                              <a href={contents.instagram_url || ''} target="_blank"><i className="mdi mdi-instagram"></i></a>
                              <a href={contents.whatsup_url || ''} target="_blank"><i className="mdi mdi-whatsapp"></i></a>
                           </div>
                        </div>
                        <div className="col-lg-7 col-md-7">
                           <div className="mt-2">
                              {data_load == true ?
                                 <iframe src={contents.google_map_web || ''} width="100%" height="450" style={{ border: "0" }} allowFullScreen="" loading="lazy"></iframe>
                                 : ''}
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="section-padding  bg-white">
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-12 col-md-12 section-title text-left mb-4">
                           <h2>Contact Us</h2>
                        </div>
                        <div className="col-lg-12 col-md-12">
                           <form name="sentMessage">
                              <div className="row">
                                 <div className="col-md-6">
                                    <div className="form-group">
                                       <label>Full Name <span className="text-danger">*</span></label>
                                       <input type="text" placeholder="Full Name" onChange={this.onChange} className="form-control" name="name" />
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="form-group">
                                       <label>Phone Number <span className="text-danger">*</span></label>
                                       <input type="text" placeholder="Phone Number" minLength={10} maxLength={10} onInput={(e) => this.only_number(e)} onChange={this.onChange} className="form-control" name="phone" />
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="form-group">
                                       <label>Email Address <span className="text-danger">*</span></label>
                                       <input type="email" placeholder="Email Address" onChange={this.onChange} className="form-control" name="email" />
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="form-group">
                                       <label>Subject <span className="text-danger">*</span></label>
                                       <input type="text" placeholder="Subject" onChange={this.onChange} className="form-control" name="subject" />
                                    </div>
                                 </div>
                                 <div className="col-md-12">
                                    <div className="form-group">
                                       <label>Message <span className="text-danger">*</span></label>
                                       <textarea rows="4" cols="100" placeholder="Message" onChange={this.onChange} className="form-control" name="message" maxLength="999" style={{ resize: "none" }}></textarea>
                                    </div>
                                 </div>
                              </div>
                              <button type="button" disabled={this.state.loading} className="btn btn-success" onClick={(e) => this.update_password()}>{this.state.loading && <i className="mdi mdi-refresh mdi-spin" aria-hidden="true"></i>}  Send Message</button>
                           </form>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
         </div>
      );
   }
}
export default Contact;
