import React from 'react';
import {Redirect} from 'react-router-dom';
import { get_data_all } from '../components/Fetch_All_data.js';
function Logout()
{
    window.localStorage.removeItem("app_token");
    window.localStorage.removeItem("login_token");
    window.localStorage.removeItem("client_contact");
    window.localStorage.removeItem("client_name");
    window.localStorage.removeItem("client_id");
    window.localStorage.removeItem("profileImg");
    get_data_all('m/generate_u_token',{})
		.then(res => res.json())
        .then(
          (result) => {
			localStorage.setItem('app_token',result['user_token']);
          },
          (error) => {
            console.log(error);
          }
        )
    //return <div>{<Redirect to={{pathname: '/',state: { page_reload: true }}}></Redirect>}</div>
    return window.location.href='/';
}
export default Logout;