import React, { Component } from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import Moment from 'react-moment';
import { get_data_all } from '../components/Fetch_All_data.js';
import Profile_Nav from './Profile_Nav.js';
class LoyaltyPoint extends Component {
   constructor(props) {
      super(props);
      this.state = {
         loyalty_point_list: [],
         loyalty_point: 0,
      };

   }
   componentDidMount() {
      get_data_all("m/get-loyalty-point", { 'muser_id': localStorage.getItem('client_id') })
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  this.setState({ loyalty_point_list: resolve['data'] });
               }
               this.setState({ loyalty_point: resolve['loyalty_point'] || 0 });
            },
            (reject) => {
               console.log(reject);
            }
         )
   }
   render() {
      return (
         <div>
            {/* <Header /> */}
            <div className="page-container">
               <section className="account-page section-padding">
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-10 mx-auto">
                           <div className="row no-gutters">
                              <div className="col-md-4">
                                 <Profile_Nav current_page={"loyaltypoint"} />
                              </div>
                              <div className="col-md-8">
                                 <div className="card card-body account-right">
                                    <div className="widget">
                                       <div className="section-header">
                                          <h5 className="heading-design-h5">
                                             Loyalty Point (<span style={{ color: 'green' }}>{this.state.loyalty_point}</span>)
                                          </h5>
                                       </div>
                                       <div className="row">
                                          <div className="col-sm-12">
                                             <div className="order-list-tabel-main table-responsive">
                                                <table className="datatabel table table-striped table-bordered order-list-tabel" width="100%" cellSpacing={0}>
                                                   <thead>
                                                      <tr>
                                                         <th>Date</th>
                                                         <th>Point</th>
                                                         <th>Description</th>
                                                         <th>Status</th>
                                                      </tr>
                                                   </thead>
                                                   <tbody>
                                                      {this.state.loyalty_point_list.length > 0 ?
                                                         this.state.loyalty_point_list.map(list => (
                                                            <tr key={"order_" + list.id + "_" + list.referrence_id}>
                                                               <td><Moment format="DD-MM-YYYY">
                                                                  {list.created_at}
                                                               </Moment></td>
                                                               <td>{list.loyalty_point}</td>
                                                               <td>{list.description}</td>
                                                               <td>{list.credit_debit}</td>
                                                            </tr>
                                                         ))
                                                         :
                                                         <tr>
                                                            <td colSpan="4" align={"center"}>Record not found</td>
                                                         </tr>
                                                      }
                                                   </tbody>
                                                </table>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
            {/* <Footer /> */}
         </div>
      );
   }
}
export default LoyaltyPoint;
