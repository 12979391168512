import { apiUrl } from '../Base_page.js';
export { delete_order_address }
function delete_order_address(address_id) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/remove_muser_address', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({'muser_adr_id':address_id,'muser_id': localStorage.getItem('client_id')})
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}