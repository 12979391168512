import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { get_data_all } from '../components/Fetch_All_data.js';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
class CouponMdl extends Component {
   constructor(props) {
      super(props);
      this.state = {
         offer_status: false,
         reload_status: false,
         price_id: '',
         offer: []
      };
   }

   check_coupon_avaibility = (coupon_id, coupon_type) => {
      this.setState({ loader_status: true })
      let options = {
         'm_u_token': localStorage.getItem('app_token'), 'coupon_type': coupon_type, price_id: this.state.price_id,
         'plateform': 'WEB', 'coupon_id': coupon_id, 'area_id': localStorage.getItem('actual_area_id')
      };
      get_data_all('m/check-coupon-avaibility', options)
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  this.get_offer_detail();
                  this.setState({ reload_status: true })
               }
               else {
                  this.setState({ loader_status: false })
                  let getAlert = () => (
                     <SweetAlert warning title={resolve['message']} onConfirm={() => { this.setState({ alert: null }) }} />
                  );
                  this.setState({ alert: getAlert() });
               }
            },
            (reject) => {
               console.log(reject)
            }
         )
   }
   componentWillReceiveProps(nextProps) {
      let offer_status = nextProps.offer_status;
      let price_id = nextProps.price_id || "";
      if (offer_status == true) {
         this.setState({ offer_status: true, price_id: price_id, offer: [] });
         this.get_offer_detail();
      }
      else {
         this.setState({ offer_status: false, })
      }
   }
   get_offer_detail = () => {
      this.setState({ loader_status: true })
      let options = { 'm_u_token': localStorage.getItem('app_token'), 'plateform': 'WEB' };
      get_data_all('m/get-coupon-offer', options)
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  this.setState({
                     offer: resolve.data, loader_status: false,
                     new_order: resolve.new_order, user_Exist: resolve.user_Exist, promocode: ''
                  })
               }
               else {
                  this.setState({ loader_status: false, promocode: '', offer: [] })
               }
            },
            (reject) => {
               console.log(reject)
            }
         )
   }
   check_coupon_code = (e) => {
      let value = e.target.value;
      this.setState({ [e.target.name]: value })
      if (value.length == 6) {
         this.check_coupon_avaibility(value, 'code');
      }
   }
   remove_coupon_code = (coupon_id) => {
      this.setState({ loader_status: true })
      let options = {
         'm_u_token': localStorage.getItem('app_token'),
         'coupon_id': coupon_id, 'area_id': localStorage.getItem('actual_area_id')
      };
      get_data_all('m/remove-coupon', options)
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  this.get_offer_detail();
                  this.setState({ reload_status: true })
               }
               else {
                  this.setState({ loader_status: false })
               }
            },
            (reject) => {
               console.log(reject)
            }
         )
   }

   componentDidMount() {

   }

   render() {
      const { offer_status, offer } = this.state;
      return (
         <>
            {this.state.alert}
            <Modal size="md" fade={false} isOpen={offer_status} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
               <ModalHeader close={<button type="button" className="close" onClick={() => { this.props.parentCallback(false, this.state.reload_status); }} aria-label="Close">
                  <span aria-hidden="true">×</span>
               </button>}>
                  <CardText className="modal-title">
                     Available Offers
                  </CardText>
               </ModalHeader>
               <ModalBody>
                  <div>
                     <div className="row">
                        <div className="col-sm-12">
                           <div className="form-group">
                              <label className="control-label">Have a promocode?</label>
                              <input type="text" className="form-control border-form-control" value={this.state.promocode || ''} name="promocode" onChange={(e) => this.check_coupon_code(e)} />
                           </div>
                        </div>
                     </div>
                     <p className="mb-2">Today's Best Offer</p>
                     <div className="row">
                        {offer.map((list, index) => {
                           return (
                              <div key={"ol" + index} className="col-sm-12">
                                 <div className="coupon-container" style={list.total_uses_by_me == 0 ? [] : { cursor: 'not-allowed', 'background': '#f1f1f1', 'opacity': '0.7', pointerEvents: 'none' }}>
                                    <div className="ml-2">
                                       <label style={{ color: '#d98688' }}>{list.coupon_code}</label>
                                       {list.coupon_applied == 0 ?
                                          ''
                                          :
                                          <span className="copon-text-remove cursor text-danger" onClick={() => this.remove_coupon_code(list.coupon_id)}>Remove <i className="mdi mdi-close"></i></span>
                                       }
                                    </div>
                                    <div>
                                       <p className="coupon-detail">{list.coupon_name}</p>
                                       {list.coupon_applied == 0 ?
                                          <span className="copon-apply-arrow cursor" onClick={(e) => this.check_coupon_avaibility(list.coupon_id, 'id')}> Apply  <i className="mdi mdi-chevron-right"></i> </span>
                                          :
                                          <span className="copon-text-applied text-success">Applied</span>
                                       }
                                    </div>
                                 </div>
                              </div>
                           )
                        })}

                     </div>
                  </div>
               </ModalBody>
               <ModalFooter>
                  <></>
               </ModalFooter>
            </Modal>
         </>
      );
   }
}
export default CouponMdl;
