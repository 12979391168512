import { apiUrl } from '../Base_page.js';
export {
  chk_user_signUp_otp, check_user_registration, fetch_product_variation_list, sort_price_list, fetch_state_option, fetch_cities_in_option,
  fetch_login_signup_slider, home_page_all_section_product, extract_price_detail, pincode_wise_search, fetch_country_state_city_option, fetch_state_by_pincode, updtae_order_address, store_order_address, fetch_order_address, fetch_sub_group_product, fetch_profile, login_user, fetch_topsaver_product, fetch_home_slider, fetch_product_main_group, get_data_all, get_product_details
}
function fetch_profile() {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/fetch_muser_profile', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'muser_id': localStorage.getItem('client_id'), 'muser_mobno': localStorage.getItem('client_contact') })
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function fetch_order_address() {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/fetch_muser_address', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'muser_id': localStorage.getItem('client_id') })
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function fetch_state_by_pincode(pincode) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'fetch_state_by_pincode', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'pincode': pincode })
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function pincode_wise_search(pincode) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/get_pincode', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'pin_code': pincode })
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function fetch_product_variation_list(input_param) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/fetch_product_variation_list', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify(input_param)
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function get_product_details(product_id, variant = '',actual_pincode='') {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/get_product_detail_by_id', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'm_u_token': localStorage.getItem('app_token'), 'actual_pincode':actual_pincode,'area_id': localStorage.getItem('actual_area_id'), 'product_id': product_id, 'variant': variant })
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function extract_price_detail(price_detail, key='') {
  if (key == 'discount') {
    return JSON.parse(price_detail).sort((b, a) => a.unit_discount - b.unit_discount)
  }
  else if (key == 'unit_price') {
    //return JSON.parse(price_detail).sort((b, a) => a.CartQty - b.CartQty)
    return JSON.parse(price_detail).sort((b, a) => b.unit_price - a.unit_price)
  }
  else {
    //return JSON.parse(price_detail).sort((b, a) => a.CartQty - b.CartQty)
    return JSON.parse(price_detail);
  }
}
function sort_price_list(price_detail, key) {
  if (key == 'unit_discount') {
    return price_detail.sort((b, a) => a.unit_discount - b.unit_discount)
  }
 else  if (key == 'unit_stock') {
    return price_detail.sort((b, a) => a.unit_stock - b.unit_stock)
  }
  else {
    return price_detail.sort((a, b) => a.unit_price - b.unit_price)
  }
}
function fetch_topsaver_product(page) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/fetch_random_product', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'm_u_token': localStorage.getItem('app_token'), 'page': page, 'area_id': localStorage.getItem('actual_area_id') })
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function home_page_all_section_product() {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/home_page_all_section_product', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'm_u_token': localStorage.getItem('app_token'), 'area_id': localStorage.getItem('actual_area_id') })
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function fetch_product_main_group() {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/fetch_product_main_group', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'm_u_token': localStorage.getItem('app_token') })
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function fetch_sub_group_product(cat_id, subcat_id, startFrom) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/fetch_sub_group_product', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({
        'm_u_token': localStorage.getItem('app_token'), 'pincode': localStorage.getItem('pincode'),
        'area_id': localStorage.getItem('actual_area_id'), 'group_id': cat_id, 'sub_group_id': subcat_id, 'page': startFrom
      })
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function fetch_home_slider() {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/mobile_banner', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'm_u_token': localStorage.getItem('app_token'), 'plateform': 'WEB' })
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function fetch_login_signup_slider() {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/login_signup_slider', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'm_u_token': localStorage.getItem('app_token'), 'plateform': 'WEB' })
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function login_user(data) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/check_user_login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify(data)
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function check_user_registration(data) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/chk_user_send_otp_signup', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify(data)
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function chk_user_signUp_otp(data) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/store_verified_new_user_data', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify(data)
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function get_data_all(url, data) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify(data)
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function store_order_address(options) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/save_muser_address', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify(options)
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function updtae_order_address(options) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/update_user_address', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify(options)
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function fetch_state_option() {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/fetch_state_option', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'muser_id': localStorage.getItem('client_id') })
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function fetch_cities_in_option(state_id) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/fetch_cities_in_option', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'muser_id': localStorage.getItem('client_id'), 'state_id': state_id })
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function fetch_country_state_city_option() {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/fetch_country_state_city_option', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'muser_id': localStorage.getItem('client_id') })
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
