import React, { useState, useEffect } from 'react';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import '../assets/slider.css';
import FadeIn from "react-lazyload-fadein";
const ImageSlider = ({ slides, title, img_url, updateCurrentSlide }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;
  useEffect(() => {
    updateCurrentSlide(current)
  }, [current]);

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }
  return (
    <section className='slider'>
      {/*       <FaArrowAltCircleLeft className='left-arrow' onClick={prevSlide} />
      <FaArrowAltCircleRight className='right-arrow' onClick={nextSlide} /> */}
      {slides.map((slide, index) => {
        return (
          <div className={index === current ? 'slide active' : 'slide'} key={index}>
            <FadeIn easing={'ease-out'} >
              {onload => (
                <img onClick={() => setCurrent(index)} src={img_url + slide.images} alt={title} onLoad={onload} className='image' />
              )}
            </FadeIn>
          </div>
        )
      })}
    </section >
  );
};

export default ImageSlider;