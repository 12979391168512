import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import { get_order_details } from '../components/Fetch_Order.js';
import { fetch_status_string, fetch_payment_mode_string } from '../components/General_data.js';
class Order_success extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order_remark: '0',
            order_id: '0',
            order_details: [],
            carts: [],
            pro_isLoaded: false,
            cancel_loader: false
        };
        if (props.match.isExact == true) {
            const order_id = props.match.params.order_id;
            const inoice_number = props.match.params.inoice_number;
            get_order_details(order_id, inoice_number)
                .then(
                    (resolve) => {
                        if (resolve['status'] == 1) {
                            this.setState({
                                order_details: resolve['order'],
                                pro_isLoaded: true
                            });
                        }
                    },
                    (reject) => {
                        console.log(reject)
                    }
                )
        }
        else {
            this.props.history.push("/");
        }
    }
    render() {
        const { order_details, pro_isLoaded } = this.state;
        return (
            <div>
               {/*  <Header /> */}
                <div className="page-container">
                    {pro_isLoaded == true ?
                        <section className="checkout-page section-padding">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="my-1 justify-content-center">
                                            <div className="card card-1">
                                                <div className="">
                                                    <div className="text-center">
                                                        <div className="col-lg-10 col-md-10 mx-auto order-done">
                                                            {
                                                                (order_details.order_status >= 1) ?
                                                                    <>
                                                                        <i className="mdi mdi-check-circle-outline text-success"></i>
                                                                        <h4 className="text-success">Congrats! Your Order has been Accepted..</h4>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <i className="mdi mdi-close-circle-outline text-secondary"></i>
                                                                        <h4 className="text-success">Your Order is Failed..</h4>
                                                                    </>
                                                            }
                                                            {/*  <p>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lobortis tincidunt est, et euismod purus suscipit quis. Etiam euismod ornare elementum. Sed ex est, Sed ex est, consectetur eget consectetur, Lorem ipsum dolor sit amet...
                                                        </p> */}
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Order Id</td>
                                                                        <td>{order_details.invoiceNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Order Date</td>
                                                                        <td>{order_details.order_date}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Payment Mode</td>
                                                                        <td>{fetch_payment_mode_string(order_details.p_mode)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Order Status</td>
                                                                        <td>{fetch_status_string(order_details.order_status)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Order Amount</td>
                                                                        <td>₹{order_details.order_amount}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Shipping Charge</td>
                                                                        <td>₹{order_details.shipping_amount}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Payable Amount</td>
                                                                        <td>₹{order_details.final_amount}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="text-center">
                                                            <a href="/"><button type="button" className="btn btn-secondary mr-2 btn-lg">Countinue Shopping</button></a>
                                                            {
                                                                (order_details.order_status > 0)
                                                                    ?
                                                                    <>
                                                                        <Link to={"/order-details/" + order_details.invoiceNumber + "/" + order_details.order_id}><button type="button" className="btn btn-secondary ml-2 btn-lg">Order Detail</button></Link>
                                                                    </>
                                                                    : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        : ''}
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
export default withRouter(Order_success);
