import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import { get_data_all, extract_price_detail } from '../components/Fetch_All_data.js';
//import { update_cart_value_text } from '../assets/custom2.js';
class Wishlist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wish_isLoaded: false,
            wish_list_product: []
        };
    }
    get_user_wish_list() {
        const actual_area_id = localStorage.getItem('actual_area_id') ? localStorage.getItem('actual_area_id') : null;
        get_data_all('m/wish-list', { 'm_u_token': localStorage.getItem('app_token'), 'area_id': actual_area_id })
            .then(
                (resolve) => {
                    if (resolve['status'] == 1) {
                        this.setState({
                            wish_isLoaded: true,
                            wish_list_product: resolve['data']
                        });
                    }
                    else {
                        this.setState({
                            wish_isLoaded: false,
                            wish_list_product: []
                        });
                    }
                },
                (reject) => {
                    console.log(reject)
                }
            )
    }
    componentDidMount() {
        this.get_user_wish_list()
    }
    render() {
        const { wish_isLoaded, wish_list_product } = this.state;
        return (
            <div>
                <Helmet>
                    <title>My Wish List | A1Mart</title>
                </Helmet>
                {/* <Header /> */}
                <div className="page-container">
                    <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <a href="/"><strong><span className="mdi mdi-home" /> Home</strong></a> <span className="mdi mdi-chevron-right" /> <a href="#">Wish List</a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="checkout-page section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <h5 className="card-header">My Wish List <span className="text-secondary float-right">({wish_list_product.length} item)</span></h5>
                                        <div className="card-body pt-0 pr-0 pl-0 pb-0">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {wish_isLoaded == true ?
                                                        wish_list_product.map((product, index) => {

                                                            return (
                                                                <div className="cart-list-product" id={"checkout_" + product.product_id + "_" + product.c_id} key={"product_" + index}>
                                                                    <Link to={"/product/" + ((product.p_name).replace(/[^a-z0-9\s]/gi, '').replace(/[-\s]/g, '-')) + "/" + product.product_id} target="_blank">
                                                                        <img className="img-fluid" src={product.img_paths} />
                                                                    </Link>
                                                                    {/* <span className="badge badge-success">50% OFF</span> */}
                                                                    <h5>{product.p_name}</h5>
                                                                    {
                                                                        (product.price_detail != null) ?
                                                                            <div className="product-footer">
                                                                                <p className="offer-price mt-2 mb-2">
                                                                                    Sale <span className={"sale_" + product.id}>
                                                                                        {extract_price_detail(product.price_detail)[0]['unit_price'] || 0}
                                                                                    </span>
                                                                                    ₹
                                                                                    <span className="ml-3 regular-price">
                                                                                        Mrp - <span className={"mrp_" + product.id}>
                                                                                            {extract_price_detail(product.price_detail)[0]['unit_mrp'] || 0}
                                                                                        </span> ₹
                                                                                    </span>
                                                                                </p>
                                                                                <div className="row justify-content-center">
                                                                                    <select id={"grid1_" + product.id} data-wish-added={product.wish_added} className={"form-control mb-3 mt-1 product_price_id grid1_" + product.id}>
                                                                                        {extract_price_detail(product.price_detail).map(price => (
                                                                                            (price) ?

                                                                                                <option key={"price" + price['product_price_id'] + product.id} value={price['product_price_id']}>{price['unit_quantity']}&nbsp;{price['unit']} - RS {price['unit_price']}</option>

                                                                                                : ''
                                                                                        ))}
                                                                                    </select>
                                                                                </div>
                                                                                <div className="row">
                                                                                    {
                                                                                        (extract_price_detail(product.price_detail)[0]['CartQty'] > 0) ?

                                                                                            <div className={"product-grid-" + product.id}>
                                                                                                <div className="table-cart-stepper float-left">
                                                                                                    <div className="number-style text-center">
                                                                                                        <span data-product-id={(product.id)} data-product-operater="-" data-price-id={extract_price_detail(product.price_detail)[0]['product_price_id']} className="number-minus changeproductQty"></span>
                                                                                                        <input type="number" readOnly className={"product_" + product.id} value={extract_price_detail(product.price_detail)[0]['CartQty']} />
                                                                                                        <span data-product-id={(product.id)} data-product-operater="+" data-price-id={extract_price_detail(product.price_detail)[0]['product_price_id']} className="number-plus changeproductQty"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <button type="button" data-wish-added={product.wish_added} data-price-id={extract_price_detail(product.price_detail)[0]['product_price_id']} data-product-id={product.id} className="btn btn-secondary removeCartProduct btn-sm float-left"><i className="mdi mdi-close"></i> remove</button>
                                                                                            </div>
                                                                                            :
                                                                                            <div className={"product-grid-" + product.id}>
                                                                                                <button type="button" data-wish-added={product.wish_added} data-product_id={product.id} id={"p1_" + product.id} className="addTocartBtn btn btn-primary btn-sm float-left"><i className="mdi mdi-cart-outline"></i> Add To Cart</button>
                                                                                            </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        <div className="text-center mt-3">
                                                            <h6>Your wish list is Empty</h6>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
export default Wishlist;
