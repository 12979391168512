import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, CardText } from 'reactstrap';
import ImageSlider from './ImageSlider';
import '../assets/slider.css';
import FadeIn from "react-lazyload-fadein";
const ImagePopUpMdl = ({ index, title, img_url, images, model_handler }) => {
    const [current, setCurrent] = useState(index);
    const length = images.length;
    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };
    const updateCurrentSlides = (index) => {
        setCurrent(index);
    }
    return (
        <Modal isOpen={true} size="lg" centered={true} fade={false} role="dialog" backdrop="static" keyboard={false}>
            <ModalHeader close={<span className="close cursor" onClick={() => { model_handler() }}>X</span>}>
                <CardText className="modal-title">
                    {title}
                </CardText>
            </ModalHeader>
            <ModalBody>
                <div className="login-modal text-center">
                    <i className='mdi mdi-chevron-left left-arrow' onClick={prevSlide} ></i>
                    <i className='mdi mdi-chevron-right right-arrow' onClick={nextSlide} ></i>
                    <FadeIn easing={'ease-out'}>
                        {onload => (
                            <img className="" alt={title} src={img_url + images[current].images} onLoad={onload} />
                        )}
                    </FadeIn>
                    <ImageSlider updateCurrentSlide={updateCurrentSlides} slides={images} img_url={img_url} title={title} />
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ImagePopUpMdl;