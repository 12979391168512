import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, CardText } from 'reactstrap';
import parse from 'html-react-parser';
const ProductDescriptionMdl = ({ products_details, model_handler }) => {
    return (
        <Modal isOpen={true} size="lg" centered={true} fade={false} role="dialog" backdrop="static" keyboard={false}>
            <ModalHeader close={<span className="close cursor" onClick={() => { model_handler() }}>X</span>}>
                <CardText className="modal-title">
                    {products_details.p_name}
                </CardText>
            </ModalHeader>
            <ModalBody>
                <div className="product-description">
                    {parse(products_details.description)}
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ProductDescriptionMdl;