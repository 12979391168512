import rootReducer from '../reducer'
import { createStore, applyMiddleware, compose } from 'redux'
//import {configureStore,applyMiddleware, compose} from '@reduxjs/toolkit'
import thunk from 'redux-thunk'

const initialState = {}
const middlewares = [thunk]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(
    applyMiddleware(...middlewares)
  )
)

export default store