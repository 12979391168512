import React, { Component } from 'react';
import OrderTrackMdl from '../components/OrderTrackMdl.js';
import Moment from 'react-moment';
import { fetch_order_list } from '../components/Fetch_Order.js';
import { fetch_status_string, fetch_status_color, fetch_payment_mode_string } from '../components/General_data.js';
import Profile_Nav from './Profile_Nav.js';
class Orderlist extends Component {
   constructor(props) {
      super(props);
      this.state = {
         order_list: [],
         order_hash: false,
         inoice_number: '',
         order_id: '',
         model_status: false
      };
      this.onChange = this.onChange.bind(this);
   }
   onChange(e) {
      this.setState({ [e.target.name]: e.target.value });
   }
   componentDidMount() {
      fetch_order_list()
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  this.setState({ order_list: resolve['data'], order_hash: true });
               }
            },
            (reject) => {
               console.log(reject);
            }
         )
   }
   order_track = (invoiceNumber, order_id) => {
      this.setState({ model_status: true, inoice_number: invoiceNumber, order_id: order_id })
   }
   handleCallback = () => {
      this.setState({ model_status: false, inoice_number: '', order_id: '' })
   }
   render() {
      const { model_status, inoice_number, order_id } = this.state;
      return (
         <div>
            {/* <Header /> */}
            <OrderTrackMdl model_status={model_status} parentCallback={this.handleCallback} inoice_number={inoice_number} order_id={order_id} />
            <div className="page-container">
               <section className="account-page section-padding">
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-10 mx-auto">
                           <div className="row no-gutters">
                              <div className="col-md-4">
                                 <Profile_Nav current_page={"orderlist"} />
                              </div>
                              <div className="col-md-8">
                                 <div className="card card-body account-right">
                                    <div className="widget">
                                       <div className="section-header">
                                          <h5 className="heading-design-h5">
                                             My Order (<span style={{ color: 'red' }}>{(this.state.order_list).length}</span>)
                                          </h5>
                                       </div>
                                       <div className="row">
                                          <div className="col-sm-12">
                                             <div className="order-list-tabel-main table-responsive">
                                                <table className="datatabel table table-striped table-bordered order-list-tabel" width="100%" cellSpacing={0}>
                                                   <thead>
                                                      <tr>
                                                         <th>Order ID</th>
                                                         <th>Date</th>
                                                         <th>P Mode</th>
                                                         <th>Status</th>
                                                         <th>Total</th>
                                                         <th>Detail</th>
                                                      </tr>
                                                   </thead>
                                                   <tbody>
                                                      {this.state.order_hash == true ?
                                                         this.state.order_list.map(list => (
                                                            <tr key={"order_" + list.order_invoice + "_" + list.order_id}>
                                                               <td>#{list.invoiceNumber}</td>
                                                               <td><Moment format="DD-MM-YYYY">
                                                                  {list.order_date}
                                                               </Moment></td>
                                                               <td>{fetch_payment_mode_string(list.p_mode)}</td>
                                                               <td><span className={"badge " + fetch_status_color(list.order_status)}>{fetch_status_string(list.order_status)}</span></td>
                                                               <td>{list.final_amount}</td>
                                                               <td>
                                                                  <a href={"/order-details/" + list.invoiceNumber + "/" + list.order_id} data-original-title="View Detail" className="btn btn-success btn-sm mr-2">View</a>
                                                                  {list.order_status != 0 && list.order_status != 1 && list.order_status != 7 && list.order_status != 11 && list.order_status != 10 ?
                                                                     <button onClick={() => this.order_track(list.invoiceNumber, list.order_id)} data-original-title="Track Order Detail" className="btn btn-info btn-sm">Track</button>
                                                                     : <></>}
                                                               </td>
                                                            </tr>
                                                         ))
                                                         :
                                                         <tr>
                                                            <td colSpan="6" align={"center"}>Order not found</td>
                                                         </tr>
                                                      }
                                                   </tbody>
                                                </table>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
            {/* <Footer /> */}
         </div>
      );
   }
}
export default Orderlist;
