import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import { get_data_all } from '../components/Fetch_All_data.js';
class OurBlog extends Component {
   constructor(props) {
      super(props);
      this.state = {
         data_status: false,
         blog_list: [],
      };
   }
   componentDidMount() {
      this.get_page_data();
   }
   login_signup_slider = () => {
   }
   get_page_data = () => {
      get_data_all('m/our-blog', {})
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  this.setState({ blog_list: resolve['blog_list'], data_status: true })
               }
               else {
                  this.setState({ blog_list: [], data_status: true })
               }
            },
            (reject) => {
               console.log(reject);
            }
         )
   }
   render() {
      return (
         <>
            <Helmet>
               <title>Our Blog | Harpra</title>
            </Helmet>
           {/*  <Header /> */}
            <div className="page-container">
               <section className="inner-header">
                  <div className="container">
                     <div className="row">
                        <div className="col-md-12 text-center">
                           <h1 className="mt-3 mb-2 text-black">Our Blog</h1>
                           <div className="breadcrumbs">
                              <p className="mb-2 text-black"><Link className="text-black" to="/">Home</Link>  /  <span className="text-success">Our Blog</span></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="bg-white">
                  <div className="container pt-5">
                     <div className='row'>
                        {this.state.data_status == true ?
                           this.state.blog_list.map((item,index) => {
                              return (
                                 <div className="col-sm-12 col-xs-12 col-lg-6 mt-2 mb-2 pl-3 pr-3" key={index}>
                                    <h5>{item.blog_title}</h5>
                                    <img src={item.image} style={{ height: '220px',width: '100%' }} />
                                 </div>
                              )
                           })
                           : ''
                        }
                     </div>
                  </div>
               </section>
            </div>
            {/* <Footer /> */}
         </>
      );
   }
}
export default OurBlog;
