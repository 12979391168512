import React, { Component, memo } from 'react';
import { Link } from 'react-router-dom';
import { apiUrl } from '../Base_page.js';
import logo2 from '../assets/img/logo2.png';
import payment_methods from '../assets/img/payment_methods.png';
//import apple from '../assets/img/apple.png';
//import google from '../assets/img/google.png';
class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            m_cat: [],
            contents: [],
            collection_list: [],
            s_cat: []
        };
    }
    componentDidMount() {
        let mythis = this;
        this.get_page_data();
        setTimeout(function () {
            //mythis.fetch_category_list();
            //mythis.fetch_collection_list();
        }, 1000);
    }
    get_page_data = () => {
        let options = { 'page_type': 'about' };
        fetch(apiUrl + 'm/contact-page', options)
            .then(res2 => res2.json())
            .then(
                (resolve) => {
                    this.setState({ contents: resolve, data_load: true })

                },
                (error) => {
                    console.log(error);
                }
            )
    }
    fetch_category_list() {
        fetch(apiUrl + "m/fetch_category_list")
            .then(res2 => res2.json())
            .then(
                (result2) => {
                    if (result2['status'] == 1) {
                        this.setState({
                            m_cat: result2['m_cat'], s_cat: result2['s_cat']
                        });
                    }
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }
    fetch_collection_list() {
        fetch(apiUrl + "m/fetch_collection_list")
            .then(res2 => res2.json())
            .then(
                (result2) => {
                    if (result2['status'] == 1) {
                        this.setState({
                            collection_list: result2['collection_list']
                        });
                    }
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }
    render() {
        const { error, contents, s_cat, m_cat, collection_list } = this.state;
        return (
            <>
                <section className="section-padding footer border-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 contact">
                                <h4 className="mb-2 mt-0"><Link className="logo" to="/"><img src={logo2} alt="shopping" style={{ width: '160px' }} /></Link></h4>
                                {(contents.address || contents.address2) ?
                                    <p className="mb-0"><Link to="#"><i className="mdi mdi-map-marker"></i>{contents.address || ''}<br/> {contents.address2 || ''}</Link></p>
                                    : <></>}
                                <p className="mb-0"><Link to="#"><i className="mdi mdi-phone"></i>{contents.mobile_number || ''}</Link></p>
                                <p className="mb-0"><Link to="#"><i className="mdi mdi-whatsapp"></i>{contents.whatsup_number || ''}</Link></p>
                                {/* <p className="mb-0"><Link to="#"><i className="mdi mdi-cellphone-iphone"></i> +91 0771-4915969</Link></p> */}
                                {contents.email_id && contents.email_id != '' && contents.email_id != null ?
                                    <>
                                        <p className="mb-0"><a href={"mailto:" + contents.email_id || ''}><i className="mdi mdi-email"></i>{contents.email_id || ''}</a></p>
                                    </>
                                    : ''}
                                {contents.email_id2 && contents.email_id2 != '' && contents.email_id2 != null ?
                                    <>
                                        <p className="mb-0"><a href={"mailto:" + contents.email_id2 || ''}><i className="mdi mdi-email"></i>{contents.email_id2 || ''}</a></p>
                                    </>
                                    : ''}
                            </div>
                            {/* <div className="col-lg-2 col-sm-6">
                                <h6 className="mb-4 footer-title">OUR COLLECTION</h6>
                                <ul>
                                    {collection_list.slice(0, 6).map(category => (
                                        <li key={'m_cat' + category.section_id}>
                                            <a href={"/category/sub/" + category.section_slug + "/" + category.section_id}>{category.section_header}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div> */}
                            {/* <div className="col-lg-2 col-md-2">
                                <h6 className="mb-4">SUB CATEGORIES</h6>
                                <ul>
                                    {s_cat.slice(0, 6).map(category => (
                                        (category.main_grp_state == 2) ?

                                            <li key={'c_cat' + category.group_id}>
                                                <a href={"/category/sub/" + ((category.group_name).replace(/[^a-z0-9\s]/gi, '').replace(/[-\s]/g, '-')) + "/" + category.group_id}>{category.group_name}</a>
                                            </li>
                                            : ''
                                    ))}
                                </ul>
                            </div> */}
                            <div className="col-lg-2 col-sm-6">
                                <h6 className="mb-4 footer-title">About Us</h6>
                                <ul>
                                    <li><Link to="/about">About Us</Link></li>
                                    <li><Link to="/faq">FAQ</Link></li>
                                    <li><Link to="/contact">Contact Us</Link></li>
                                    <li><Link to="/our-blog">Our Blog</Link></li>
                                    <li><Link to="/work-with-us">Work With Us</Link></li>
                                    {/* <li><Link to="/our-certificate">Our Certificate</Link></li> */}
                                </ul>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <h6 className="mb-4 footer-title">Important Link</h6>
                                <ul>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="/terms-condition">Terms & Condition</Link></li>
                                    <li><Link to="/refund-policy">Return & Refund Policy</Link></li>
                                    <li><Link to="/quality-policy">Quality Policy</Link></li>
                                    <li><Link to="/shipping-policy">Shipping Policy</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-sm-12">
                                {/* <h6 className="mb-4">Download App</h6>
                                <div className="app">
                                    <Link to="/" onClick={e => e.preventDefault()}><img src={google} /></Link>
                                    <Link to="/" onClick={e => e.preventDefault()} ><img src={apple} /></Link>
                                </div> */}
                                <h6 className="mb-3 footer-title">GET IN TOUCH</h6>
                                <div className="footer-social">
                                    <a href={contents.facebook_url || ''} target="_blank"><i className="mdi mdi-facebook"></i></a>
                                    <a href={contents.twitter_url || ''} target="_blank"><i className="mdi mdi-twitter"></i></a>
                                    <a href={contents.instagram_url || ''} target="_blank"><i className="mdi mdi-instagram"></i></a>
                                    <a href={contents.whatsup_url || ''} target="_blank"><i className="mdi mdi-whatsapp"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="pt-4 footer pb-4 footer-bottom">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="col-lg-6 col-sm-12">
                                <p className="mt-1 mb-0 text-black">&copy; Copyright 2024 <strong>Harpra Fashion Retailers</strong>. All Rights Reserved<br />
                                    <small className="mt-0 mb-0">Designed & Maintained By <a href="https://shlokitsolution.com" rel="noopener noreferrer" target="_blank">Shlok It Solution</a>
                                    </small>
                                </p>
                            </div>
                            <div className="col-lg-6 col-sm-12 text-right">
                                <img alt="Payment Method" src={payment_methods} />
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default memo(Footer);