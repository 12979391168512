import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import DeliveryAddress from './DeliveryAddress.js';
import CouponMdl from './CouponMdl.js';
import { get_product_details, get_data_all } from '../components/Fetch_All_data.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import uniqid from 'uniqid'
class ProductCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            p_d_isLoaded: false,
            userLogin: false,
            offer_status: false,
            free_shipping: false,
            products_details: [],
            p_qty: 1,
            alert: null,
            address_id: '',
            invoiceNumber: '',
            order_id: '',
            checkouttab: 'active',
            paymenttab: '',
            logintab: '',
            donetab: '',
            freight_charge: 0,
            used_loyalty_point: 0,
            shipping_charge: 0,
            final_shipping_charge: 0,
            loyalty_point: 0,
            coupon_amount: 0,
            save_order_btn: false,
            payment_method: "",
            discount_amount: 0,
            total_mrp_amount: 0,
            payble_amount_tax: 0,
            payble_amount: 0,
            price: []
        };
        if (props.match.isExact == true) {
            const product_id = props.match.params.id;
            const params = new URLSearchParams(props.location.search);
            const variant = params.get('variant');
            if (product_id) {
                this.fetch_product_detial(product_id, variant);
            }
        }
    }
    init_payment() {

    }
    fetch_product_detial(product_id, variant) {
        get_product_details(product_id, variant)
            .then(
                (resolve) => {
                    if (resolve['status'] == 1) {
                        let price = resolve['price'] || [];
                        this.setState({
                            products_details: resolve['data'],
                            price: price,
                            p_d_isLoaded: true,
                            discount_amount: price.unit_mrp - price.unit_price,
                            total_mrp_amount: price.unit_mrp,
                            payble_amount: price.unit_price,
                        });
                        var address_id = localStorage.getItem('address_id');
                        var pincode = localStorage.getItem('pincode');
                        this.get_coupon_loyelty_amount();
                        if (!address_id && pincode)
                            this.check_delivery_pincode(pincode, address_id);
                    }
                },
                (reject) => {
                    console.log(reject)
                }
            )
    }
    check_delivery_pincode = (pincode, address_id) => {
        let price_id = this.state.price['product_price_id'];
        let options = { 'pincode': pincode, 'item_id': this.state.products_details.id, 'price_id': price_id, 'address_id': address_id };
        get_data_all('m/check-delivery-pincode', options)
            .then(
                (resolve) => {
                    if (resolve['status'] == 1) {
                        this.setState({ etd_delivery: resolve['etd_delivery'], freight_charge: resolve['freight_charge'], free_shipping: resolve['free_shipping'], shipping_charge: resolve['shipping_charge'] });

                        if (resolve['free_shipping'] == true) {
                            if (resolve['shipping_charge'] > 0) {
                                this.setState({ final_shipping_charge: resolve['freight_charge'] });
                            }
                        }
                        else {
                            this.setState({ final_shipping_charge: resolve['freight_charge'] });
                        }
                        //final_shipping_charge
                    }
                },
                (reject) => {
                    console.log(reject);
                }
            )
    }
    get_coupon_loyelty_amount = () => {
        var app_token = localStorage.getItem('app_token');
        let options = { 'm_u_token': app_token };
        get_data_all('m/coupon-loyalty-amount', options)
            .then(
                (resolve) => {
                    if (resolve['status'] == 1) {
                        this.setState({ coupon_amount: resolve['coupon_amount'], loyalty_point: resolve['loyalty_point'] });
                    }
                    else {

                    }
                },
                (reject) => {
                    console.log(reject);
                }
            )
    }
    change_quantity = (product_id, operater) => {
        let p_qty = this.state.p_qty;
        let price = this.state.price;
        const quantity = operater == '+' ? parseInt(p_qty) + 1 : parseInt(p_qty) - 1;
        if (quantity > 0) {
            this.setState({
                p_qty: quantity,
                discount_amount: (price.unit_mrp * quantity) - (price.unit_price * quantity),
                total_mrp_amount: (price.unit_mrp * quantity),
                payble_amount: (price.unit_price * quantity),
            });
        }
    }

    save_new_order = () => {
        let address_id = this.state.address_id;
        let payment_method = this.state.payment_method;
        var login_token = localStorage.getItem('login_token');
        var client_token = localStorage.getItem('client_contact');
        if (!client_token && !login_token) {
            let getAlert5 = () => (
                <SweetAlert warning title={"Please login then countinue"} onConfirm={() => {
                    this.setState({ alert: null });
                }} />
            );
            this.setState({ alert: getAlert5() });
        }
        else if (address_id == 0 || address_id == '') {
            let getAlert1 = () => (
                <SweetAlert warning title={"Please Select Delivery Address"} onConfirm={() => { this.setState({ alert: null }); }} />
            );
            this.setState({ alert: getAlert1() });
        }
        else if (payment_method == 0 || payment_method == '') {
            let getAlert1 = () => (
                <SweetAlert warning title={"Please Select Payment Method"} onConfirm={() => { this.setState({ alert: null }); }} />
            );
            this.setState({ alert: getAlert1() });
        }
        else {
            this.setState({ save_order_btn: true });
            let options = {
                'm_u_token': localStorage.getItem('app_token'),
                'area_id': localStorage.getItem('actual_area_id'),
                'p_mode': payment_method,
                'addressId': address_id,
                'product_id': this.state.products_details.id,
                'variant_id': this.state.price.product_price_id,
                'p_qty': this.state.p_qty,
                'used_loyalty_point': this.state.used_loyalty_point,
                'coupon_amount': this.state.coupon_amount,
                'discount_amount': this.state.discount_amount,
                'plateform': "WEB",
                'muser_id': localStorage.getItem('client_id')
            }
            document.body.classList.add('loader-active');
            get_data_all('m/save-product-buy', options)
                .then(
                    (resolve) => {
                        document.body.classList.remove('loader-active');
                        if (resolve['status'] == 1) {
                            let invoiceNumber = resolve['invoiceNumber'];
                            let order_id = resolve['order_id'];
                            let getAlert = () => (
                                <SweetAlert success title={resolve['message']} onConfirm={() => { this.order_success(invoiceNumber, order_id) }} />
                            );
                            this.setState({ alert: getAlert() });
                        }
                        else if (resolve['status'] == 3) {
                            let payment = resolve['payment'];
                            let gateway = resolve['gateway'];
                            if (gateway == 'razorpay') {
                                this.make_payment(payment, resolve['invoiceNumber'], resolve['order_id'], resolve['user']);
                            }
                            else {
                                window.location.href = resolve['payment_url'];
                            }
                        }
                        else {
                            let getAlert1 = () => (
                                <SweetAlert warning title={resolve['message']} onConfirm={() => { this.setState({ alert: null }); }} />
                            );
                            this.setState({ save_order_btn: false, alert: getAlert1() });
                        }
                    },
                    (reject) => {
                        console.log(reject);
                        this.setState({ save_order_btn: false });
                        document.body.classList.remove('loader-active');
                    }
                )
        }
    }
    make_payment = (payment, invoice_number, order_id, user) => {
        var options = {
            "key": payment.key_id, // Enter the Key ID generated from the Dashboard
            "amount": payment.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": user.muser_name,
            "description": "Product order",
            "image": payment.image,
            "order_id": payment.OrderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": (response) => {
                response['muser_id'] = localStorage.getItem('client_id');
                response['invoice_number'] = invoice_number;
                get_data_all('m/razorpay_order_success', response)
                    .then(
                        (resolve) => {
                            if (resolve['status'] == '1') {
                                this.setState({ logintab: '', paymenttab: '', donetab: 'active', checkouttab: '', invoiceNumber: invoice_number, order_id: order_id });
                            }
                            else {
                                let getAlert1 = () => (
                                    <SweetAlert warning title={resolve['message']} onConfirm={() => { this.setState({ alert: null }); }} />
                                );
                                this.setState({ save_order_btn: false, alert: getAlert1() });
                            }
                        },
                        (reject) => {
                            console.log(reject);
                            this.setState({ save_order_btn: false });
                        }
                    )
            },
            "prefill": {
                "name": user.muser_name,
                "email": user.muser_email,
                "contact": user.mobilenumber
            },
            "notes": {
                "address": user.muser_address
            },
            "theme": {
                "color": "#3399cc"
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            console.log("payment.failed", response);
        });
        rzp1.open();
    }
    order_success(invoiceNumber, order_id) {
        this.setState({ alert: null });
        //this.props.history.push();
        this.setState({ logintab: '', paymenttab: '', donetab: 'active', checkouttab: '', invoiceNumber: invoiceNumber, order_id: order_id });
    }
    proceed_to_payment = () => {
        this.setState({ logintab: 'active', paymenttab: '', donetab: '', checkouttab: '' });
    }
    set_tab_active = (e, tab) => {
        e.preventDefault();
        if (tab == 'checkouttab' && this.state.donetab != 'active')
            this.setState({ logintab: '', paymenttab: '', donetab: '', checkouttab: 'active' });
        if (tab == 'logintab' && this.state.donetab != 'active')
            this.setState({ logintab: 'active', paymenttab: '', donetab: '', checkouttab: '' });
        if (tab == 'paymenttab' && this.state.donetab != 'active' && this.state.address_id)
            this.setState({ logintab: '', paymenttab: 'active', donetab: '', checkouttab: '' });
    }
    use_layalty_points = (status) => {
        if (status == true) {
            let total_amount = parseFloat(this.state.payble_amount) + parseFloat(this.state.payble_amount_tax) + parseFloat(this.state.freight_charge) - parseFloat(this.state.coupon_amount);
            if (total_amount > parseFloat(this.state.loyalty_point)) {
                this.setState({ used_loyalty_point: this.state.loyalty_point });
            }
            else {
                this.setState({ used_loyalty_point: total_amount });
            }
        }
        else {
            this.setState({ used_loyalty_point: 0 });
        }
    }
    componentDidMount() {
        const login_token = localStorage.getItem('login_token');
        const client_contact = localStorage.getItem('client_contact');
        const client_name = localStorage.getItem('client_name');
        (login_token && client_contact) ?
            this.setState({ userLogin: true, client_name: client_name })
            : this.setState({ userLogin: false });
    }
    handleCallback = (response) => {
        if (response.address_id) {
            this.setState({ address_id: response.address_id, logintab: '', paymenttab: 'active', donetab: '', checkouttab: '' })
            this.check_delivery_pincode(this.state.pincode, response.address_id);
        }
    }
    offer_handleCallback = (offer_status, reload_status) => {
        this.setState({ offer_status: offer_status });
        if (reload_status == true) {
            this.get_coupon_loyelty_amount();
        }
    }
    render() {
        const { p_d_isLoaded, products_details, price, p_qty } = this.state;
        return (
            <div>
                <CouponMdl offer_status={this.state.offer_status} price_id={this.state.price.product_price_id} parentCallback={this.offer_handleCallback} />
                <Helmet>
                    <title>Checkout</title>
                </Helmet>
                {/* <Header /> */}
                <div className="page-container">
                    {this.state.alert}
                    <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <a href="/"><strong><span className="mdi mdi-home" /> Home</strong></a> <span className="mdi mdi-chevron-right" /> <a href="/">Checkout</a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="shopping_cart_page check-out-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-8 mx-auto">
                                    <div className="widget">
                                        <div className="text-center">
                                            <ul className="nav nav-tabs round-tabs" role="tablist">
                                                <li className="nav-item">
                                                    <a className={"nav-link " + this.state.checkouttab} onClick={(e) => this.set_tab_active(e, 'checkouttab')} href="#checkouttab" /* data-toggle="tab"  role="tab" */ aria-expanded="true">Checkout</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={"nav-link " + this.state.logintab} onClick={(e) => this.set_tab_active(e, 'logintab')} href="#logintab" /* data-toggle="tab"  role="tab" */ aria-expanded="false">Delivery Address</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={"nav-link " + this.state.paymenttab} onClick={(e) => this.set_tab_active(e, 'paymenttab')} href="#paymenttab" /* data-toggle="tab"  role="tab" */ aria-expanded="false">Payment</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={"nav-link " + this.state.donetab} onClick={(e) => e.preventDefault()} href="#donetab" /* data-toggle="tab"  role="tab" */ aria-expanded="false">Order Placed</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="tab-content">
                                            <div className={"tab-pane " + this.state.paymenttab} id="paymenttab" role="tabpanel" aria-expanded="false">
                                                <div className="order-detail-content">
                                                    <div>
                                                        <table className="table" style={{ background: "#f7f7f7 none repeat scroll 0 0" }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Cart Subtotal</td>
                                                                    <td>₹{this.state.total_mrp_amount}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Saving Amount</td>
                                                                    <td><span className="text-success">-₹{this.state.discount_amount}</span></td>
                                                                </tr>
                                                                {this.state.coupon_amount > 0 ?
                                                                    <tr>
                                                                        <td>Coupon Applied</td>
                                                                        <td><span className="text-success">-₹{this.state.coupon_amount}</span></td>
                                                                    </tr>
                                                                    : <></>}

                                                                <tr>
                                                                    <td>Loyalty Point</td>
                                                                    <td>
                                                                        <span className="text-success">₹{this.state.loyalty_point}</span>
                                                                        {this.state.loyalty_point > 0 ?
                                                                            this.state.used_loyalty_point == 0 ?
                                                                                <span className="float-right mr-10 cursor text-success" onClick={() => this.use_layalty_points(true)}>Use</span>
                                                                                :
                                                                                <>
                                                                                    <span className="float-right mr-10">
                                                                                        <span className="text-success"><strong>₹{this.state.used_loyalty_point} Used</strong></span>
                                                                                        <span className="text-danger ml-2 cursor" onClick={() => this.use_layalty_points(false)}>Remove</span>
                                                                                    </span>
                                                                                </>
                                                                            : <></>}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Shipping Charge</td>
                                                                    <td>
                                                                        {(this.state.free_shipping == false) ?
                                                                            "₹" + this.state.freight_charge :
                                                                            (
                                                                                (this.state.shipping_charge == 0)
                                                                                    ?
                                                                                    <>
                                                                                        <span className='mr-2 text-danger' style={{ textDecoration: 'line-through' }}>₹{this.state.freight_charge}</span>
                                                                                        <span className='text-success'>Free Shipping</span>
                                                                                    </>
                                                                                    : "₹" + this.state.shipping_charge
                                                                            )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Payble Amount</td>
                                                                    <td> ₹{(parseFloat(this.state.payble_amount) + parseFloat(this.state.payble_amount_tax) + parseFloat(this.state.final_shipping_charge) - parseFloat(this.state.coupon_amount) - parseFloat(this.state.used_loyalty_point))}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="text-right">
                                                        <p className="text-primary" onClick={() => this.offer_handleCallback(true)} style={{ cursor: 'pointer' }}>Apply Coupon Code</p>
                                                    </div>
                                                    <div className="panel-heading">
                                                        <h4 className="panel-title"><i className="fa fa-credit-card"></i> Payment Method</h4>
                                                    </div>
                                                    <p>Please select the preferred payment method to use on this order.</p>
                                                    <div className="radio">
                                                        <label>
                                                            <input type="radio" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value="1" name="payment_method" /><span className="ml-20">Cash On Delivery</span>
                                                        </label>
                                                    </div>
                                                    <div className="radio">
                                                        <label>
                                                            <input type="radio" disabled={false} value="2" name="payment_method" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} /><span className="ml-20">By Online</span>
                                                        </label>
                                                    </div>
                                                    <div className="cart_navigation text-center">
                                                        <a href="/" className="btn btn-theme-round-outline mr-5">Back to Order</a>
                                                        <button type='button' disabled={this.state.save_order_btn} onClick={() => this.save_new_order()} className="btn btn-theme-round">Pay ₹{(parseFloat(this.state.payble_amount) + parseFloat(this.state.payble_amount_tax) + parseFloat(this.state.final_shipping_charge) - parseFloat(this.state.coupon_amount) - parseFloat(this.state.used_loyalty_point))}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"tab-pane " + this.state.checkouttab} id="checkouttab" role="tabpanel" aria-expanded="false">
                                                <div className="order-detail-form">
                                                    <form className="col-lg-6 col-md-6 mx-auto">
                                                        {p_d_isLoaded == true ?
                                                            <div className="cart-list-product">
                                                                <Link to={"/product/" + products_details.product_slug + "/" + products_details.id}>
                                                                    <img className="img-fluid" src={products_details.img_paths} />
                                                                </Link>
                                                                <span className="badge badge-success">{price.disc_type == 'flat' ? ' ₹' + price.unit_discount
                                                                    : " " + price.discount_percent + '% '} OFF</span>
                                                                <h5>{products_details.p_name}</h5>
                                                                <p className="offer-price mb-0">
                                                                    <span className="offer-price" id={"product_price_" + (products_details.id)}>₹{price.unit_price}</span>
                                                                    <span className="regular-price" id={"product_mrp_price_" + (products_details.id)}> ₹ {price.unit_mrp}</span>
                                                                </p>
                                                                <div className="table-cart-stepper">
                                                                    <div className="number-style">
                                                                        <span onClick={() => this.change_quantity(products_details.id, '-')} className="number-minus"></span>
                                                                        <input className={"product_" + products_details.id} value={p_qty || 1} type="number" readOnly />
                                                                        <span onClick={() => this.change_quantity(products_details.id, '+')} className="number-plus"></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="text-center mt-3">
                                                                <h6>Your Cart is Empty</h6>
                                                            </div>
                                                        }
                                                    </form>
                                                    <div className="cart_navigation text-center">
                                                        <a href="/" className="btn btn-theme-round-outline mr-5"><i className="fa fa-arrow-left"></i> Continue shopping</a>
                                                        <button type="button" onClick={() => this.proceed_to_payment()} className="btn btn-theme-round"><i className="fa fa-check"></i> Proceed to Payment</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"tab-pane " + this.state.logintab} id="logintab" role="tabpanel" aria-expanded="false">
                                                <div className="order-detail-form">
                                                    {this.state.userLogin == false ?
                                                        <form className="col-lg-10 col-md-10 mx-auto text-center">
                                                            <Link to="/" type="button" onClick={e => e.preventDefault()} data-target="#bd-example-modal" data-toggle="modal" className="btn btn-theme-round-outline">Login/Sign Up</Link>
                                                        </form>
                                                        :
                                                        <>
                                                            {(products_details && p_d_isLoaded == true) ?
                                                                <DeliveryAddress parentCallback={this.handleCallback} />
                                                                :
                                                                <></>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <div className={"tab-pane " + this.state.donetab} id="donetab" role="tabpanel" aria-expanded="false">
                                                <div className="order-detail-form text-center">
                                                    <div className="col-lg-10 col-md-10 mx-auto order-done">
                                                        <i className="mdi mdi-check-circle-outline"></i>
                                                        <h2 className="text-success">Congrats! Your Order has been Accepted..</h2>
                                                        <p>
                                                            Order Number: {this.state.invoiceNumber}
                                                        </p>
                                                    </div>
                                                    <div className="cart_navigation text-center">
                                                        <a href="/" className="btn btn-theme-round-outline mr-3">Return to store</a>
                                                        <a href={"/order-success/" + this.state.invoiceNumber + "/" + this.state.order_id} className="btn btn-theme-round">Order Detail</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/*  <Footer /> */}
            </div>
        );
    }
}
export default ProductCheckout;
