import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import { fetch_login_signup_slider, get_data_all } from '../components/Fetch_All_data.js';
import parse from 'html-react-parser';
class About extends Component {
   constructor(props) {
      super(props);
      this.state = {
         aboutimage: '',
         data_status: false,
         aboutImg_status: false,
      };
   }
   componentDidMount() {
      //this.login_signup_slider();
      this.get_page_data();
   }
   login_signup_slider = () => {
      fetch_login_signup_slider()
         .then(
            (resolve) => {
               if (resolve['aboutImg_status'] == 1) {
                  this.setState({ aboutimage: resolve['aboutImg'], aboutImg_status: true })
               }
            },
            (reject) => {
               console.log(reject);
            }
         )
   }
   get_page_data = () => {
      let options = { 'page_type': 'about' };
      get_data_all('m/get/page', options)
         .then(
            (resolve) => {
               if (resolve['response'] == 1) {
                  this.setState({ contents: resolve['contents'], data_status: true })
               }
            },
            (reject) => {
               console.log(reject);
            }
         )
   }
   render() {
      return (
         <>
            <Helmet>
               <title>About | Harpra</title>
            </Helmet>
            {/* <Header /> */}
            <div className="page-container">
               <section className="inner-header">
                  <div className="container">
                     <div className="row">
                        <div className="col-md-12 text-center">
                           <h1 className="mt-3 mb-2 text-black">About Us</h1>
                           <div className="breadcrumbs">
                              <p className="mb-2 text-black"><Link className="text-black" to="/">Home</Link>  /  <span className="text-success">About Us</span></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="bg-white">
                  <div className="container">
                     <div className="row">
                        {/* {this.state.aboutImg_status == true ?
                           <div className="pl-4 col-lg-5 col-md-5 pr-4 mb-3">
                              <img className="rounded img-fluid mt-5" src={this.state.aboutimage} alt="bsquaremart" />
                           </div>
                           : ''
                        } */}
                        <div className="col-lg-12 col-md-12 mt-5 pl-3 pr-3">
                           {this.state.data_status == true ?
                              parse(this.state.contents['page_content'])
                              : ''
                           }
                        </div>
                     </div>
                  </div>
               </section>
               {/* <section className="section-padding bg-about-bottom">
                  <div className="section-title text-center mb-5">
                     <h2>What We Provide?</h2>
                  </div>
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-4 col-md-4">
                           <div className="mt-4 mb-4"><i className="text-success mdi mdi-shopping mdi-48px"></i></div>
                           <h5 className="mt-3 mb-3 text-secondary">Best Prices & Offers</h5>
                           <p>The offer price for a particular stock or share is the price that the person selling it says that they want for it.</p>
                        </div>
                        <div className="col-lg-4 col-md-4">
                           <div className="mt-4 mb-4"><i className="text-success mdi mdi-earth mdi-48px"></i></div>
                           <h5 className="mb-3 text-secondary">Wide Assortment</h5>
                           <p>A wide assortment strategy is used when retailers aim to offer a lot of different product lines or categories, but with lesser depth in each category. It aims to provide more variety in the types of product lines offered but does not provide a high number of products in each product line</p>
                        </div>
                        <div className="col-lg-4 col-md-4">
                           <div className="mt-4 mb-4"><i className="text-success mdi mdi-refresh mdi-48px"></i></div>
                           <h5 className="mt-3 mb-3 text-secondary">Easy Returns</h5>
                           <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using.</p>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-lg-4 col-md-4">
                           <div className="mt-4 mb-4"><i className="text-success mdi mdi-truck-fast mdi-48px"></i></div>
                           <h5 className="mb-3 text-secondary">Next Day Delivery</h5>
                           <p>If eligible, order within the time-frame mentioned on the product details page to get your items delivered on the next business day</p>
                        </div>
                        <div className="col-lg-4 col-md-4">
                           <div className="mt-4 mb-4"><i className="text-success mdi mdi-basket mdi-48px"></i></div>
                           <h5 className="mt-3 mb-3 text-secondary">100% Satisfaction Guarantee</h5>
                           <p>We promise that the buyer of any Yes product from this website may return their goods for a product refund with no questions asked. We will even issue the refund before you send the product back so that you are not worried about your money being held.</p>
                        </div>
                        <div className="col-lg-4 col-md-4">
                           <div className="mt-4 mb-4"><i className="text-success mdi mdi mdi-tag-heart mdi-48px"></i></div>
                           <h5 className="mt-3 mb-3 text-secondary">Great Daily Deals Discount</h5>
                           <p>These discount and deal sites all have a direct sales component that allows users to purchase deeply discounted items on-site or through an app. Some also curate coupons, special deals, and instant discounts from third-party retailers.</p>
                        </div>
                     </div>
                  </div>
               </section> */}
            </div>
            {/* <Footer /> */}
         </>
      );
   }
}
export default About;
