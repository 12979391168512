import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Modal, ModalBody, ModalHeader, CardText } from 'reactstrap';
import { fetch_status_string, fetch_status_color, fetch_payment_mode_string } from './General_data.js';
import { get_order_details } from './Fetch_Order.js';
import { get_data_all } from './Fetch_All_data.js';
import Moment from 'react-moment';
import FadeIn from "react-lazyload-fadein";
class OrderReturnReplaceMdl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      ReturnReplace: "",
      review_comments: "",
      rn_rp_status: "",
      product_price_id: "",
      item_id: "",
      reviewId: null,
      data_status: false,
      submit_btn_status: true,
      Listshow: false,
      model_status: false,
      loading: false,
      order_details: [],
      color_list: [],
      size_list: [],
      shipment: [],
      ListItem: [],
      helpfullImages: [],
    };
  }
  log_order_details(order_id, inoice_number) {
    get_order_details(order_id, inoice_number)
      .then(
        (resolve) => {
          if (resolve['status'] == 1) {
            this.setState({
              order_details: resolve['order'],
              shipment: resolve.shipment || [],
              model_status: true,
              isLoading: false
            });
            this.get_return_replace_material(order_id, resolve['order'].m_u_id, this.state.ReturnReplace);
          }
          else {
            NotificationManager.warning('Warning', resolve.message);
          }
        },
        (reject) => {
          console.log(reject)
        }
      )
  }

  componentWillReceiveProps(nextProps) {
    let order_ids = nextProps.order_id;
    let inoice_number = nextProps.inoice_number;
    let model_status = nextProps.model_status;
    let retur_replace = nextProps.retur_replace;
    if (order_ids && inoice_number && model_status === true && retur_replace) {
      this.log_order_details(order_ids, inoice_number);
      this.setState({ ReturnReplace: retur_replace, reviewId: null, product_price_id: '', item_id: '' })
    }
    else {
      this.setState({ model_status: false, data_status: false, retur_replace: "", item_id: '' })
    }
  }
  componentDidMount() {
  }
  get_return_replace_material = (order_ids, m_u_id, retur_replace) => {
    get_data_all("m/get-return-replace-material", { order_id: order_ids, muser_id: m_u_id, retur_replace: retur_replace })
      .then(
        (resolve) => {
          if (resolve['status'] == 1) {
            this.setState({
              ListItem: resolve['data'],
              Listshow: true,
            });
            let return_replace_request = resolve['return_replace_request'];
            if (return_replace_request) {
              this.setState({
                reviewId: return_replace_request['reviewId'],
                item_id: return_replace_request['item_id'],
                rn_rp_status: return_replace_request['rn_rp_status'],
                product_price_id: return_replace_request['product_price_id'],
              });
              if (return_replace_request['rn_rp_status'] == 0 || return_replace_request['rn_rp_status'] == 1 || return_replace_request['rn_rp_status'] == 3) {
                this.setState({
                  ReturnReplace: return_replace_request['rn_rp_type'],
                });
              }
              if (return_replace_request['rn_rp_status'] != 3 && return_replace_request['rn_rp_status'] != 4) {
                this.setState({
                  submit_btn_status: false,
                });
              }
            }
            if (resolve['color_list']) {
              this.setState({ color_list: resolve['color_list'] });
            }
            if (resolve['size_list']) {
              this.setState({ size_list: resolve['size_list'] });
            }
            if (resolve['comments']) {
              this.setState({ review_comments: resolve['comments'].review_comments || '' });
              var review_image = JSON.parse(resolve['comments'].review_image);
              if (review_image && review_image.length > 0) {
                var helpfullImages = [];
                review_image.map((Imgitem) => {
                  var image_option = { 'image': Imgitem.image, 'formate': Imgitem.formate, 'newImage': Imgitem.newImage };
                  helpfullImages.push(image_option);
                })
                this.setState({
                  helpfullImages: helpfullImages,
                });
              }
            }
          }
        },
        (reject) => {
          console.log(reject)
        }
      )
  }
  onChangeContact = async (event, c) => {
    const ListItem = this.state.ListItem.map((item) => { return item.id === c.id ? { ...item, reviewId: event.target.checked } : { ...item, reviewId: false } })
    this.setState({
      ListItem: ListItem
    });
    //console.log(c.id,event.target.checked,this.state.ReturnReplace)
    if (event.target.checked && this.state.ReturnReplace == 2) {
      this.setState({ reviewId: c.id });
    }
    else {
      this.setState({ reviewId: null });
    }
  }
  async changePriveImage(target) {
    var new_image_list = [];
    if (target.files.length <= 4) {
      await Promise.all([...target.files].map((image) => {
        if (image != null) {
          if (image.type == "image/png" || image.type == "image/jpg" || image.type == "image/jpeg" || image.type == "image/webp") {
            var reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onloadend = () => {
              new_image_list.push({ image: reader.result, formate: 'base64', newImage: true });
              this.setState({ helpfullImages: new_image_list });
            };
          }
        }
      })
      )
    }
    else {
      NotificationManager.warning('Warning', "Maximum 4 images can be uploaded");
    }
  }
  remove_image(e, index) {
    e.preventDefault();
    const helpfullImages = this.state.helpfullImages.filter((item, i) => { return index !== i });
    this.setState({ helpfullImages: helpfullImages });
  }
  save_return_replace_process = () => {
    this.setState({ loading: true });
    get_data_all("m/save-return-replace", {
      order_id: this.state.order_details.order_id, muser_id: this.state.order_details.m_u_id,
      product_price_id: this.state.product_price_id, item_id: this.state.item_id,
      ReturnReplace: this.state.ReturnReplace, reviewId: this.state.reviewId
    })
      .then(
        (resolve) => {
          if (resolve['status'] == 1) {
            NotificationManager.success('Success', resolve.message);
            //this.get_return_replace_material(this.state.order_details.order_id, this.state.order_details.m_u_id, this.state.ReturnReplace);
            this.props.parentCallback({ reload: true });
          }
          else {
            NotificationManager.warning('Warning', resolve.message);
          }
          this.setState({ loading: false });
        },
        (reject) => {
          console.log(reject);
          this.setState({ loading: false });
        }
      )
  }
  cancel_return_replace = () => {
    this.setState({ loading: true });
    get_data_all("m/cancel-return-replace", { order_id: this.state.order_details.order_id, muser_id: this.state.order_details.m_u_id, ReturnReplace: this.state.ReturnReplace })
      .then(
        (resolve) => {
          if (resolve['status'] == 1) {
            NotificationManager.success('Success', resolve.message);
            this.props.parentCallback({ reload: true });
          }
          else {
            NotificationManager.warning('Warning', resolve.message);
          }
          this.setState({ loading: false });
        },
        (reject) => {
          console.log(reject);
          this.setState({ loading: false });
        }
      )
  }
  submitreview_form = () => {
    const helpfullImages = this.state.helpfullImages;
    const review_comments = this.state.review_comments || '';
    const ListItem = this.state.ListItem.filter(item => { return (item.reviewId == true || (item.reviewId != null && item.reviewId != false)) });
    if (helpfullImages.length == 0) {
      NotificationManager.warning('Warning', "Select At least one image");
    }
    else if (ListItem.length == 0) {
      NotificationManager.warning('Warning', "Select At least one resion");
    }
    else if (review_comments == '') {
      NotificationManager.warning('Warning', "Enter Other Description");
    }
    else if (this.state.ReturnReplace == '2' && this.state.reviewId == 3 && this.state.item_id == '') {
      NotificationManager.warning('Warning', "Select Color");
    }
    else if (this.state.ReturnReplace == '2' && this.state.reviewId == 5 && this.state.product_price_id == '') {
      NotificationManager.warning('Warning', "Select Size");
    }
    else {
      this.setState({ loading: true });
      get_data_all("m/save-review-material", {
        order_id: this.state.order_details.order_id,
        helpfullImages: helpfullImages,
        selected_resion: ListItem,
        review_comments: this.state.review_comments,
        muser_id: this.state.order_details.m_u_id, purpose: this.state.ReturnReplace
      })
        .then(
          (resolve) => {
            if (resolve['status'] == 1) {
              //NotificationManager.success('Success', resolve.message);
              this.save_return_replace_process();
            }
            else {
              NotificationManager.warning('Warning', resolve.message);
            }
            this.setState({ loading: false });
          },
          (reject) => {
            console.log(reject)
            this.setState({ loading: false });
          }
        )
    }
  }
  render() {
    const { isLoading, data_status, order_details, helpfullImages } = this.state;
    return (
      <>
        <NotificationContainer />
        <Modal size="lg" fade={false} isOpen={this.state.model_status} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.props.parentCallback({ reload: false })}>X</button>}>
            <CardText className="modal-title">
              Order {this.state.ReturnReplace == 1 ? 'Return' : 'Replace'} - {order_details.invoiceNumber || ''}
            </CardText>
          </ModalHeader>
          <ModalBody className="px-2 py-2">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    {isLoading == true ?
                      'Please Wait'
                      :
                      <div className="row">
                        <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                          <div className="card">
                            <div className='card-body'>
                              <div className='row'>
                                <div className="col-sm-6">
                                  <div>
                                    <label className="control-label">Order No :</label>
                                    <span>{order_details.invoiceNumber}</span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div>
                                    <label className="control-label">Order Date :</label>
                                    <span><Moment format="Do MMM YYYY">{order_details.order_date}</Moment></span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div>
                                    <label className="control-label">Order Status :</label>
                                    <span className={"badge " + fetch_status_color(order_details.order_status)}>{fetch_status_string(order_details.order_status)}</span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div>
                                    <label className="control-label">Total Amount :</label>
                                    <span>Rs.{order_details.final_amount}</span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div>
                                    <label className="control-label">Courier Name :</label>
                                    <span>{this.state.shipment.courier_name || '---'}</span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div>
                                    <label className="control-label">AWB Code :</label>
                                    <span>{this.state.shipment.awb_code || '---'}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12 mt-2">
                          <div className="row">
                            {this.state.Listshow == true ?
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>What did you not like? <span className="text-danger">*</span></label>
                                  <div className="custom-controls-stacked mt-2">
                                    {this.state.ListItem.map((item, index) => {
                                      return (
                                        <label className="custom-control custom-checkbox" key={index}>
                                          <input type="checkbox" checked={item.reviewId ? true : false} className="custom-control-input" onChange={(e) => this.onChangeContact(e, item)} />
                                          <span className="custom-control-indicator"></span>
                                          <span className="custom-control-description">{item.review}</span>
                                        </label>
                                      )
                                    })}
                                  </div>
                                </div>
                                {(this.state.ReturnReplace == 2) ?
                                  <>
                                    {(this.state.reviewId == 5) ?
                                      <div className="form-group">
                                        <label>what size do you want to replace with <span className="text-danger">*</span></label>
                                        <div className="custom-controls-stacked mt-2">
                                          {this.state.size_list.map((item, index) => {
                                            return (
                                              <label className="custom-control custom-checkbox" key={index}>
                                                <input type="checkbox" checked={item.product_price_id == this.state.product_price_id ? true : false} className="custom-control-input" value={item.product_price_id} onChange={(e) => { this.setState({ product_price_id: e.target.value }) }} />
                                                <span className="custom-control-indicator"></span>
                                                <span className="custom-control-description">{item.product_size}</span>
                                              </label>
                                            )
                                          })}
                                        </div>
                                      </div>
                                      : (this.state.reviewId == 3) ?
                                        <div className="form-group">
                                          <label>what color do you want to replace with <span className="text-danger">*</span></label>
                                          <div className="custom-controls-stacked mt-2">
                                            {this.state.color_list.map((item, index) => {
                                              return (
                                                <label className="custom-control custom-checkbox" key={index}>
                                                  <input type="checkbox" checked={item.item_id == this.state.item_id ? true : false} className="custom-control-input" value={item.item_id} onChange={(e) => this.setState({ item_id: e.target.value })} />
                                                  <span className="custom-control-indicator"></span>
                                                  <span className="custom-control-description">{item.item_color}</span>
                                                </label>
                                              )
                                            })}
                                          </div>
                                        </div>
                                        : <></>
                                    }
                                  </>
                                  : <></>
                                }
                                <div className="mt-5">
                                  {(this.state.rn_rp_status == 0) ?
                                    <button className="btn btn-sm btn-success" disabled={this.state.loading} onClick={() => this.cancel_return_replace()}>{this.state.loading && <i className="mdi mdi-refresh mdi-spin" aria-hidden="true"></i>} Cancel Request</button>
                                    : <></>}
                                </div>
                              </div> : <></>}
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="custom-file">
                                  <input type="file" id="file2" accept='image/*' onChange={(e) => this.changePriveImage(e.target)} className="custom-file-input" multiple />
                                  <span className="custom-file-control"></span>
                                </label>
                              </div>
                              {helpfullImages ?
                                <div className="row">
                                  {helpfullImages.map((item, index) => {
                                    return (
                                      <div className="col-sm-3 mt-2" key={"n_i" + index}>
                                        <FadeIn item_height={50} duration={50} easing={'ease-out'}>
                                          {onload => (
                                            <img alt={"Item Image"} onError={(e) => { e.target.onerror = null; e.target.src = '' }} title="click" src={item.image} onLoad={onload} style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '200px' }} />
                                          )}
                                        </FadeIn>
                                        <a href='#' onClick={(e) => this.remove_image(e, index)}>Remove</a>
                                      </div>
                                    )
                                  })}
                                </div>
                                : <></>}
                              <div className="form-group">
                                <label>What did you not like <span className="text-danger">*</span></label>
                                <textarea rows="4" cols="100" placeholder="Message" onChange={(e) => this.setState({ review_comments: e.target.value })} value={this.state.review_comments} className="form-control" name="message" maxLength="999" style={{ resize: "none" }}></textarea>
                              </div>
                              <div className="mt-5">
                                {this.state.submit_btn_status == true ?
                                  <button className="btn btn-sm btn-success" disabled={this.state.loading} onClick={() => this.submitreview_form()}>{this.state.loading && <i className="mdi mdi-refresh mdi-spin" aria-hidden="true"></i>} Submit</button>
                                  : <></>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal >
      </>
    );
  }
}
export default OrderReturnReplaceMdl;
