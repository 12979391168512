import React, { Component, memo } from 'react';
import { Link, Redirect } from 'react-router-dom';
import ReactCrop from 'react-image-crop';
import { Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import { get_data_all, fetch_profile } from '../components/Fetch_All_data.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import user from '../assets/img/user.jpg';
import 'react-image-crop/dist/ReactCrop.css';
class Profile_Nav extends Component {
   constructor(props) {
      super(props);
      this.state = {
         profile: '',
         current_page: '',
         birthday_image: '',
         base64String: '',
         imgModel: false,
         dobModel: false,
         dob_update_Btn: false,
         profileImg: localStorage.getItem('profileImg') || '',
         uploadBtn: true,
         alert: null,
         loyalty_point: 0,
         src: null,
         crop: {
            unit: '%',
            width: 50,
            aspect: 10 / 10,
         },
      };
   }
   componentDidMount() {
      this.setState({ current_page: this.props.current_page });
      this.get_user_profile();
   }
   onSelectFile = e => {
      if (e.target.files && e.target.files.length > 0) {
         const reader = new FileReader();
         reader.addEventListener('load', () =>
            this.setState({ src: reader.result })
         );
         reader.readAsDataURL(e.target.files[0]);
      }
   };
   async makeClientCrop(crop) {
      if (this.imageRef && crop.width && crop.height) {
         const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newImageFile.jpg'
         );
         if (croppedImageUrl) {
            this.setState({ uploadBtn: false });
            this.setState({ base64String: croppedImageUrl['base64String'] });
         }
         else {
            this.setState({ uploadBtn: true, base64String: '' });
         }
      }
      else {
         this.setState({ uploadBtn: true, base64String: '' });
      }
   }
   onImageLoaded = image => {
      this.imageRef = image;
   };

   onCropComplete = crop => {
      this.makeClientCrop(crop);
   };

   onCropChange = (crop, percentCrop) => {
      // You could also use percentCrop:
      // this.setState({ crop: percentCrop });
      this.setState({ crop });
   };
   getCroppedImg(image, crop, fileName) {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
         image,
         crop.x * scaleX,
         crop.y * scaleY,
         crop.width * scaleX,
         crop.height * scaleY,
         0,
         0,
         crop.width,
         crop.height
      );
      return new Promise((resolve, reject) => {
         canvas.toBlob(blob => {
            if (!blob) {
               //reject(new Error('Canvas is empty'));
               console.error('Canvas is empty');
               return;
            }
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            let fileUrl = this.fileUrl;
            reader.onloadend = function () {
               var base64String = reader.result;
               // console.log('Base64 String - ', base64String);
               // Simply Print the Base64 Encoded String,
               // without additional data: Attributes.
               /* console.log('Base64 String without Tags- ',
                base64String.substr(base64String.indexOf(', ') + 1)); */
               resolve({ "fileUrl": fileUrl, "base64String": base64String });
            }
         }, 'image/jpg');
      });
   }
   upload_profile_image = () => {
      let base64String = this.state.base64String || '';
      let uploadBtn = this.state.uploadBtn;
      if (uploadBtn == false) {
         this.setState({ loading: true, uploadBtn: true });
         let options = {
            'image': base64String, 'm_u_token': localStorage.getItem('login_token'),
            'muser_id': localStorage.getItem('client_id')
         };
         get_data_all('m/profile_upload', options)
            .then(
               (resolve) => {
                  if (resolve['status'] == 1) {
                     this.setState({ loading: false, profileImg: resolve['profileImg'] })
                     localStorage.setItem('profileImg', resolve['profileImg']);
                     const getAlert1 = () => (
                        <SweetAlert success title={resolve.message} onConfirm={() => { this.setState({ alert: null, imgModel: false }); }} />
                     );
                     this.setState({ alert: getAlert1() });
                  }
                  else {
                     this.setState({ loading: false, uploadBtn: false })
                     const getAlert2 = () => (
                        <SweetAlert warning title={resolve.message} onConfirm={() => { this.setState({ alert: null }); }} />
                     );
                     this.setState({ alert: getAlert2() });
                  }
               },
               (reject) => {
                  console.log(reject)
               }
            )
      }
   }
   update_dob = () => {
      let user_dob = this.state.user_dob || '';
      if (user_dob == '') {
         NotificationManager.warning('Warning', "Enter date of birth");
      }
      else {
         this.setState({ dob_update_Btn: true });
         let options = {
            'user_dob': user_dob, 'm_u_token': localStorage.getItem('login_token'),
            'muser_id': localStorage.getItem('client_id')
         };
         get_data_all('m/update-date-of-birth', options)
            .then(
               (resolve) => {
                  this.setState({ dob_update_Btn: false })
                  if (resolve['status'] == 1) {
                     const getAlert1 = () => (
                        <SweetAlert success title={resolve.message} onConfirm={() => { this.setState({ alert: null, dobModel: false }); }} />
                     );
                     this.setState({ alert: getAlert1() });
                  }
                  else {
                     const getAlert2 = () => (
                        <SweetAlert warning title={resolve.message} onConfirm={() => { this.setState({ alert: null }); }} />
                     );
                     this.setState({ alert: getAlert2() });
                  }
               },
               (reject) => {
                  console.log(reject)
               }
            )
      }
   }
   get_user_profile = () => {
      fetch_profile()
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  this.setState({ loyalty_point: resolve.data['loyalty_point'] });
                  if (this.state.current_page == 'myprofile' && (resolve.data['user_dob'] == '' || resolve.data['user_dob'] == '0000-00-00')) {
                     this.setState({ dobModel: true, birthday_image: resolve.birthday_image['image'] || '' });
                  }
               }
               else {
                  window.localStorage.removeItem("login_token");
                  window.localStorage.removeItem("client_contact");
                  window.localStorage.removeItem("client_name");
                  window.localStorage.removeItem("client_id");
                  return <div>{<Redirect to={{ pathname: '/', state: { page_reload: true } }}></Redirect>}</div>
               }
            },
            (reject) => {
               console.log(reject);
            }
         )
   }
   render() {
      const { crop, croppedImageUrl, src, current_page } = this.state;
      return (
         <div>
            {this.state.alert}
            <div className="card account-left">
               <div className="user-profile-header">
                  <img alt={localStorage.getItem('client_name')} onClick={() => this.setState({ imgModel: true, src: '' })} onError={(e) => { e.target.onerror = null; e.target.src = user }} src={this.state.profileImg} className="profileImg" />
                  <h5 className="mb-1 text-secondary"><strong>Hi </strong> {localStorage.getItem('client_name')}</h5>
                  <p> +91 {localStorage.getItem('client_contact')}</p>
               </div>
               <div className="list-group">
                  <Link to="/myprofile" className={"list-group-item list-group-item-action" + ((current_page == 'myprofile') ? " active" : "")}><i aria-hidden="true" className="mdi mdi-account-outline" />  My Profile</Link>
                  <Link className={"list-group-item list-group-item-action justify-content-between" + ((current_page == 'loyaltypoint') ? " active" : "")} to="/loyalty-point"><span><i className="mdi mdi-heart-alt fa-fw"></i> Loyalty Point</span> <span className="badge badge-success">{this.state.loyalty_point || 0} Point</span></Link>
                  <Link to="/myaddress" className={"list-group-item list-group-item-action" + ((current_page == 'myaddress') ? " active" : "")}><i aria-hidden="true" className="mdi mdi-map-marker-circle" />  Manage Address</Link>
                  {/* <Link to="/wishlist" className={"list-group-item list-group-item-action"+ ((current_page == 'wishlist') ? " active" : "")}><i aria-hidden="true" className="mdi mdi-heart-outline" />  Wish List </Link> */}
                  <Link to="/orderlist" className={"list-group-item list-group-item-action" + ((current_page == 'orderlist') ? " active" : "")}><i aria-hidden="true" className="mdi mdi-format-list-bulleted" /> My Order</Link>
                  <Link to="/changepassword" className={"list-group-item list-group-item-action" + ((current_page == 'changepassword') ? " active" : "")}><i aria-hidden="true" className="mdi mdi-account-outline" />  Password</Link>
                  <Link to="/logout" className={"list-group-item list-group-item-action" + ((current_page == 'logout') ? " active" : "")}><i aria-hidden="true" className="mdi mdi-lock" />  Logout</Link>
               </div>
            </div>
            <NotificationContainer />
            <Modal size="md" fade={false} isOpen={this.state.imgModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
               <ModalHeader>
                  <button type="button" className="close" onClick={() => this.setState({ imgModel: false })} aria-label="Close">
                     <span aria-hidden="true">×</span>
                  </button>
                  <CardText className="modal-title">
                     Profile Image
                  </CardText>
               </ModalHeader>
               <ModalBody>
                  <div className="form-wrap text-tenter">
                     <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                        <div>
                           <input type="file" className="form-control" accept="image/*" onChange={this.onSelectFile} />
                        </div>
                        <br />
                        {src && (
                           <ReactCrop
                              src={src}
                              crop={crop}
                              ruleOfThirds
                              onImageLoaded={this.onImageLoaded}
                              onComplete={this.onCropComplete}
                              onChange={this.onCropChange}
                           />
                        )}
                        {/* {croppedImageUrl && (
                              <div className="text-center">
                                 <img alt="Prifile Image" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
                              </div>
                           )} */}
                     </div>
                  </div>
               </ModalBody>
               <ModalFooter>
                  <button type="button" disabled={this.state.uploadBtn} onClick={() => this.upload_profile_image()} className="btn btn-primary btn-sm"> {this.state.loading && <i className="mdi mdi-refresh mdi-spin" aria-hidden="true"></i>} Upload</button>
                  <button type="button" onClick={() => this.setState({ imgModel: false })} className="btn btn-danger btn-sm"> Cancel</button>
               </ModalFooter>
            </Modal>
            <Modal size="md" fade={false} isOpen={this.state.dobModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
               <ModalHeader>
                  <button type="button" className="close" onClick={() => this.setState({ dobModel: false })} aria-label="Close">
                     <span aria-hidden="true">×</span>
                  </button>
                  <CardText className="modal-title">
                     Update Your Date of Birth
                  </CardText>
               </ModalHeader>
               <ModalBody>
                  <div className="row text-center">
                     <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                        <div>
                           {this.state.birthday_image != '' ? <>
                              <img src={this.state.birthday_image} style={{ maxHeight: '200px' }} />
                           </>
                              : <></>}
                           <input type="date" className="form-control mt-3" onChange={(e) => this.setState({ user_dob: e.target.value })} />
                        </div>
                     </div>
                  </div>
               </ModalBody>
               <ModalFooter>
                  <button type="button" disabled={this.state.dob_update_Btn} onClick={() => this.update_dob()} className="btn btn-primary btn-sm"> {this.state.dob_update_Btn && <i className="mdi mdi-refresh mdi-spin" aria-hidden="true"></i>} Update</button>
                  <button type="button" onClick={() => this.setState({ dobModel: false })} className="btn btn-danger btn-sm"> Cancel</button>
               </ModalFooter>
            </Modal>
         </div>
      );
   }
}
export default memo(Profile_Nav);
