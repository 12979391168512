import { apiUrl } from '../Base_page.js';
export { fetch_cart_data,remove_cart_product,cart_add_quantity,remove_cart_product2,check_cart_product,add_remove_wish_list }
function fetch_cart_data(actual_area_id) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/fetch_cart_product', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({'m_u_token': localStorage.getItem('app_token'),'area_id':actual_area_id})
    })
     .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function add_remove_wish_list(product_id)
{
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/add_remove_wish_list', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({'m_u_token': localStorage.getItem('app_token'),'product_id':product_id})
    })
     .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function check_cart_product(actual_area_id,product_price_id) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/check-cart-product', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({'m_u_token': localStorage.getItem('app_token'),'area_id':actual_area_id,'product_price_id':product_price_id})
    })
     .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function remove_cart_product(product_price_id,cart_id) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/delete_cart_product', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'm_u_token': localStorage.getItem('app_token'),'area_id':localStorage.getItem('actual_area_id'),'product_price_id':product_price_id,'cart_id':cart_id})
    })
     .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function remove_cart_product2(product_id,product_price_id) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/delete_cart_product', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'm_u_token': localStorage.getItem('app_token'),'area_id':localStorage.getItem('actual_area_id'),'product_price_id':product_price_id,'product_id':product_id})
    })
     .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function cart_add_quantity(price_id,product_id,quantity) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/update_or_insert_cart_product', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'm_u_token': localStorage.getItem('app_token'),'price_id':price_id,
      'product_id':product_id,'total_qty':quantity,'area_id':localStorage.getItem('actual_area_id')})
    })
     .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}