import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import { Oval } from 'react-loader-spinner';
import SweetAlert from 'react-bootstrap-sweetalert';
import { fetch_status_string, fetch_status_color, fetch_payment_mode_string } from '../components/General_data.js';
import { get_data_all } from '../components/Fetch_All_data.js';
import { get_order_details, cancel_order_action } from '../components/Fetch_Order.js';
import OrderTrackMdl from '../components/OrderTrackMdl.js';
import OrderReturnReplaceMdl from '../components/OrderReturnReplaceMdl.js';
class Order_details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order_remark: '',
            retur_replace: '',
            order_id: '0',
            available_replace_return: 0,
            inoice_number: '',
            order_details: [],
            return_replace_request: [],
            carts: [],
            pro_isLoaded: false,
            cancel_loader: false,
            model_status: false,
            return_model_status: false,
        };
        this.onChange = this.onChange.bind(this);
    }
    componentDidMount() {
        if (this.props.match.isExact == true) {
            const order_id = this.props.match.params.order_id;
            const inoice_number = this.props.match.params.inoice_number;
            this.setState({ order_id: order_id, inoice_number: inoice_number });
            this.load_order_detail(order_id, inoice_number);
        }
        else {
            this.props.history.push("/");
        }
    }
    load_order_detail = (order_id, inoice_number) => {
        get_order_details(order_id, inoice_number)
            .then(
                (resolve) => {
                    if (resolve['status'] == 1) {
                        this.setState({
                            order_details: resolve['order'],
                            carts: resolve['cart'],
                            available_replace_return: resolve['available_replace_return'],
                            return_replace_request: resolve['return_replace_request'],
                            pro_isLoaded: true
                        });
                    }
                },
                (reject) => {
                    console.log(reject)
                }
            )
    }
    order_track = () => {
        this.setState({ model_status: true })
    }
    handleCallback = (data) => {
        this.setState({ model_status: false, return_model_status: false, retur_replace: '' });
        if (data && data.reload == true) {
            this.load_order_detail(this.state.order_id, this.state.inoice_number);
        }
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    return_replace(type) {
        this.setState({ return_model_status: true, retur_replace: type });
    }
    cancel_action = () => {
        this.setState({ cancel_loader: true })
        let order_remark = this.state.order_remark;
        const order_id = this.state.order_id;
        let formIsValid = true;
        if (order_remark == '') {
            formIsValid = false;
            this.setState({ cancel_loader: false })
            let getAlert1 = () => (
                <SweetAlert warning title={"Select Cancel Reason"} onConfirm={() => { this.setState({ alert: null }); }} />
            );
            this.setState({ alert: getAlert1() });
        }
        else if (formIsValid == true && order_id > 0) {
            cancel_order_action(order_id, order_remark)
                .then(
                    (resolve) => {
                        if (resolve['status'] == '1') {
                            let getAlert1 = () => (
                                <SweetAlert warning title={resolve['message']} onConfirm={() => { this.setState({ alert: null }); window.location.reload(false); }} />
                            );
                            this.setState({ alert: getAlert1() });
                        }
                        else {
                            let getAlert2 = () => (
                                <SweetAlert warning title={resolve['message']} onConfirm={() => { this.setState({ alert: null }); }} />
                            );
                            this.setState({ alert: getAlert2() });
                        }
                    },
                    (reject) => {
                        console.log(reject)
                    }
                )
        }
        else {
            this.setState({ cancel_loader: false })
            let getAlert1 = () => (
                <SweetAlert warning title={"Select Cancel Reason"} onConfirm={() => { this.setState({ alert: null }); }} />
            );
            this.setState({ alert: getAlert1() });
        }
    }
    again_pay() {
        get_data_all("m/request-again-pay-order", { 'order_id': this.state.order_id, 'muser_id': localStorage.getItem('client_id') })
            .then(
                (resolve) => {
                    if (resolve['status'] == '1') {
                        let payment = resolve['payment'];
                        let gateway = resolve['gateway'];
                        if (gateway == 'razorpay') {
                            this.make_payment(payment, resolve['invoiceNumber'], resolve['order_id'], resolve['user']);
                        }
                        else {
                            window.location.href = resolve['payment_url'];
                            //window.open(resolve['payment_url']);
                        }
                    }
                    else {
                        let getAlert2 = () => (
                            <SweetAlert warning title={resolve['message']} onConfirm={() => { this.setState({ alert: null }); }} />
                        );
                        this.setState({ alert: getAlert2() });
                    }
                },
                (reject) => {
                    console.log(reject)
                }
            )
    }
    order_success(invoiceNumber, order_id) {
        this.setState({ alert: null });
        this.props.history.push("/order-success/" + invoiceNumber + "/" + order_id);
    }
    make_payment = (payment, invoice_number, order_id, user) => {
        var options = {
            "key": payment.key_id, // Enter the Key ID generated from the Dashboard
            "amount": payment.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": user.muser_name,
            "description": "Product order",
            "image": payment.image,
            "order_id": payment.OrderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": (response) => {
                response['muser_id'] = localStorage.getItem('client_id');
                response['invoice_number'] = invoice_number;
                get_data_all('m/razorpay_order_success', response)
                    .then(
                        (resolve) => {
                            if (resolve['status'] == '1') {
                                let getAlert = () => (
                                    <SweetAlert success title={resolve['message']} onConfirm={() => { this.order_success(invoice_number, order_id) }} />
                                );
                                this.setState({ alert: getAlert() });
                            }
                            else {
                                let getAlert1 = () => (
                                    <SweetAlert warning title={resolve['message']} onConfirm={() => { this.setState({ alert: null }); }} />
                                );
                                this.setState({ save_order_btn: false, alert: getAlert1() });
                            }
                        },
                        (reject) => {
                            console.log(reject);
                            this.setState({ save_order_btn: false });
                        }
                    )
            },
            "prefill": {
                "name": user.muser_name,
                "email": user.muser_email,
                "contact": user.mobilenumber
            },
            "notes": {
                "address": user.muser_address
            },
            "theme": {
                "color": "#3399cc"
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            console.log("payment.failed", response);
        });
        rzp1.open();
    }
    render() {
        const { order_details, carts, pro_isLoaded, model_status, order_id, inoice_number, available_replace_return, return_model_status, retur_replace, return_replace_request } = this.state;
        return (
            <div>
                <div className="page-container">
                    {this.state.alert}
                    {pro_isLoaded == true ?
                        <>
                            <OrderTrackMdl model_status={model_status} parentCallback={this.handleCallback} inoice_number={inoice_number} order_id={order_id} />
                            <OrderReturnReplaceMdl model_status={return_model_status} retur_replace={retur_replace} parentCallback={this.handleCallback} inoice_number={inoice_number} order_id={order_id} />
                            <section className="account-page">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="my-1 justify-content-center">
                                                <div className="card card-1">
                                                    <div className="card-header bg-white">
                                                        <h5>Order Detail</h5>
                                                        <div className="order-list-tabel-main table-responsive">
                                                            <table className="table" cellSpacing="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <td>{order_details.muser_name}</td>
                                                                        <th>Contact</th>
                                                                        <td>{order_details.mobilenumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Area</th>
                                                                        <td>{order_details.area_name}</td>
                                                                        <th>Pin Code</th>
                                                                        <td>{order_details.pinno}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Address</th>
                                                                        <td>{order_details.muser_address},{order_details.city_name} {order_details.state_name}</td>
                                                                        <th>Address Type</th>
                                                                        <td>{
                                                                            (order_details.addr_type == 1) ?
                                                                                'Home'
                                                                                : (order_details.addr_type == 2) ?
                                                                                    'Office'
                                                                                    :
                                                                                    'Other'
                                                                        }
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Order No</th>
                                                                        <td>#{order_details.invoiceNumber}</td>
                                                                        <th>Order Type</th>
                                                                        <td>{fetch_payment_mode_string(order_details.p_mode)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Order Date</th>
                                                                        <td>
                                                                            <Moment format="dddd Do MMMM YYYY">{order_details.order_date}</Moment>
                                                                        </td>
                                                                        <th>
                                                                            {(order_details.order_status == 6) ? 'Delivery date' : 'Expected Delivery date'}
                                                                        </th>
                                                                        <td><Moment format="dddd Do MMMM YYYY">{order_details.delivery_date}</Moment></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Order Status</th>
                                                                        <td>
                                                                            <span className={"badge " + fetch_status_color(order_details.order_status)}>{fetch_status_string(order_details.order_status)}
                                                                            </span>
                                                                            {order_details.order_status == 10 ?
                                                                                <button className="btn btn-sm btn-warning ml-2" onClick={() => this.again_pay()}>Pay Now</button>
                                                                                : <></>}
                                                                        </td>
                                                                        <th>{/* Coupon Code */}</th>
                                                                        <td>{/* {order_details.coupon_code != null ? 'Applied' : 'Not Applied'} */}</td>
                                                                        <td></td>
                                                                        <td>
                                                                            {order_details.order_status == 6 && available_replace_return >= 0 ? <>
                                                                                <button onClick={() => this.return_replace(1)} className="btn btn-danger btn-sm mr-2"> Return</button>
                                                                                <button onClick={() => this.return_replace(2)} className="btn btn-warning btn-sm mr-2"> Replace</button>
                                                                            </> : <>
                                                                                {(return_replace_request && (order_details.order_status == 8 || order_details.order_status == 9 || order_details.order_status == 12)) ?
                                                                                    <>
                                                                                        <button onClick={() => this.return_replace(1)} className="btn btn-primary btn-sm mr-2"> Return Request Detail</button>
                                                                                    </>
                                                                                    : (return_replace_request && (order_details.order_status == 13 || order_details.order_status == 14 || order_details.order_status == 15)) ?
                                                                                        <button onClick={() => this.return_replace(2)} className="btn btn-primary btn-sm mr-2"> Replacement Request Detail</button>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                            </>}
                                                                            {order_details.order_status != 0 && order_details.order_status != 1 && order_details.order_status != 7 && order_details.order_status != 11 && order_details.order_status != 10 ?
                                                                                <button onClick={() => this.order_track()} className="btn btn-info btn-sm">Track</button>
                                                                                : <></>}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="order-product-detail">
                                                        <div className="row mb-4 ml-1 mr-1">
                                                            <div className="col-lg-8 col-sm-8">
                                                                <h6 className="color-1 mb-2 mt-2 ml-3 change-color">Product Detail (<span style={{ color: 'red' }}>{carts.length}</span>)</h6>
                                                                {carts.map((cart, index) => (
                                                                    <div className="row" key={"o_details" + index}>
                                                                        <div className="col">
                                                                            <div className="card-2">
                                                                                <div className="mr-0 ml-0">
                                                                                    <div className="media">
                                                                                        <div className="sq text-left">
                                                                                            <a href={"/product/" + cart.product_slug + "/" + cart.product_id + "?variant=" + cart.price_id} target="_blank">
                                                                                                <img style={{ width: '100px', padding: '10px' }} className="img-fluid" src={cart.img_paths} width={90} height={90} />
                                                                                            </a>
                                                                                        </div>
                                                                                        <div className="media-body my-auto text-left">
                                                                                            <div className="row my-auto flex-column flex-md-row">
                                                                                                <div className="col my-auto">
                                                                                                    <p>{cart.product_name} </p>
                                                                                                    <p>Size: {cart.product_size} </p>
                                                                                                    <p>Style Code: {cart.sku} </p>
                                                                                                    <p>Quantity: {cart.p_qty} </p>
                                                                                                    <p>Price: <small><span className="offer-price">₹{cart.p_price} </span>
                                                                                                        {(cart.p_price != cart.actual_price) ?
                                                                                                            <span className="regular-price">₹{cart.actual_price}</span>
                                                                                                            : <></>}
                                                                                                    </small> </p>
                                                                                                </div>
                                                                                                <div className="col my-auto">
                                                                                                    <h4>Total</h4>
                                                                                                    <h5 className="mb-0">₹{(cart.p_qty * cart.p_price)}</h5>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="col-lg-4 col-sm-4">
                                                                <table className="table mt-2">
                                                                    <tr>
                                                                        <td><span className="font-weight-bold">Sub Total</span></td>
                                                                        <td>₹{order_details.order_amount}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span className="font-weight-bold">Shipping Charges</span></td>
                                                                        <td>{order_details.shipping_amount != '0' || order_details.shipping_amount != '' ? "₹" + order_details.shipping_amount : 'Free'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span className="font-weight-bold">Coupon Amount</span></td>
                                                                        <td>{order_details.couponAmount != '0' || order_details.couponAmount != '' ? "₹" + order_details.couponAmount : '0'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span className="font-weight-bold">Loyalty Point Used</span></td>
                                                                        <td>{order_details.loyalty_discount != '0' || order_details.loyalty_discount != '' ? "₹" + order_details.loyalty_discount : '0'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span className="font-weight-bold">Payble Amount</span></td>
                                                                        <td>₹{order_details.final_amount}</td>
                                                                    </tr>
                                                                    {/* <p>Tax Amount : ₹{this.state.payble_amount_tax}</p> */}
                                                                </table>
                                                                {/*  <div className="row justify-content-between">
                                                            <div className="flex-sm-col text-right col">
                                                                <p className="mb-1"> <b>Discount Amount</b></p>
                                                            </div>
                                                            <div className="flex-sm-col col-auto">
                                                                <p className="mb-1">₹ {(order_details.discount_amount == '' || order_details.discount_amount == '0' || order_details.discount_amount == null) ? '0' : order_details.discount_amount}</p>
                                                            </div>
                                                        </div> */}
                                                                {(order_details.order_status == 1 || order_details.order_status == 10) ?
                                                                    <div className="col-lg-12 col-sm-12">
                                                                        <h6 className="font-weight-bold">Cancel Order</h6>
                                                                        <div className="row">
                                                                            <div className="col-sm-12 col-lg-12 col-md-12">
                                                                                <select name="order_remark" value={this.state.order_remark} onChange={this.onChange} className="form-control">
                                                                                    <option value="">Select Order Cancel Reason</option>
                                                                                    <option value="0">Order placed by mistake</option>
                                                                                    <option value="1">Expected delivery time is too long</option>
                                                                                    <option value="2">Item Price/Shpping cost is too high</option>
                                                                                    <option value="3">Bought it from somewhere else</option>
                                                                                    <option value="4">The delivery is delayed</option>
                                                                                    <option value="5">Need to change shipping address</option>
                                                                                    <option value="6">My reason is not listed</option>
                                                                                </select>
                                                                                <button id="update_order_status_btn" disabled={this.state.cancel_loader} onClick={() => this.cancel_action()} className="btn btn-danger mt-1">{this.state.cancel_loader && <i className="mdi mdi-refresh mdi-spin" aria-hidden="true"></i>} Order Cancel</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>
                        :
                        <div className="text-center">
                            {
                                <Oval arialLabel="loading-indicator" height={150} color="blue" secondaryColor="white" strokeWidth={5} strokeWidthSecondary={1} wrapperStyle={{ marginTop: "2%", display: "inline-block" }} />
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}
export default withRouter(Order_details);
