export const insertToken = (apiUrl) => dispatch => {
	let token
	if (localStorage.getItem('app_token')) {
		token = JSON.parse(localStorage.getItem('app_token'))
		dispatch({
			type: INSERT_TOKEN_SUCCESS,
			payload: token
		})
	}
	else {
		fetch(apiUrl + 'm/generate_u_token')
			.then(res => res.json())
			.then(
				(result) => {
					localStorage.setItem('app_token', result['user_token']);
					localStorage.setItem('base_url', result['base_url']);
				},
				(error) => {
					console.log(error);
				}
			)
		dispatch({
			type: INSERT_TOKEN_SUCCESS,
			payload: token
		})
	}
}
export const count_cart_product = (apiUrl) => dispatch => {
	let cart_count
	fetch(apiUrl + 'm/count_cart_product')
		.then(res => res.json())
		.then(
			(result) => {
				cart_count = result.count;
			},
			(error) => {
				console.log(error);
			}
		)
	dispatch({
		type: INSERT_TOKEN_SUCCESS,
		payload: cart_count
	})
}
export const POST_TOKEN_BEGIN = 'POST_TOKEN_BEGIN'
export const POST_TOKEN_SUCCESS = 'POST_TOKEN_SUCCESS'
export const POST_TOKEN_FAIL = 'POST_TOKEN_FAIL'
export const INSERT_TOKEN_SUCCESS = 'INSERT_TOKEN_SUCCESS'
export const INSERT_TOKEN_FAIL = 'INSERT_TOKEN_FAIL'


