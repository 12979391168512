import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import { get_data_all } from '../components/Fetch_All_data.js';
import parse from 'html-react-parser';
class ShippingPolicy extends Component {
   constructor(props) {
      super(props);
      this.state = {
         data_status: false,
         contents: []
      };
   }
   componentDidMount() {
      this.get_page_data();
   }
   get_page_data = () => {
      let options = { 'page_type': 'shipping_policy' };
      get_data_all('m/get/page', options)
         .then(
            (resolve) => {
               if (resolve['response'] == 1) {
                  this.setState({ contents: resolve['contents'], data_status: true })
               }
            },
            (reject) => {
               console.log(reject);
            }
         )
   }
   render() {
      return (
         <div>
            <Helmet>
               <title>Shipping Policy | Harpra</title>
            </Helmet>
            {/* <Header /> */}
            <div className="page-container">
               <section className="inner-header">
                  <div className="container">
                     <div className="row">
                        <div className="col-md-12 text-center">
                           <h1 className="mt-3 mb-3 text-black">Shipping Policy</h1>
                           <div className="breadcrumbs">
                              <p className="mb-2 text-black"><Link className="text-black" to="/">Home</Link>  /  <span className="text-success">Return & Refund Policy</span></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="bg-white">
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-12 col-md-12 mt-3 pl-2 pr-2">
                           {this.state.data_status == true ?
                              parse(this.state.contents['page_content'])
                              : ''
                           }
                        </div>
                     </div>
                  </div>
               </section>
            </div>
            {/* <Footer /> */}
         </div>
      );
   }
}
export default ShippingPolicy;
