import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, CardText } from 'reactstrap';
import FadeIn from "react-lazyload-fadein";
class SizeChartMdl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            size_chart: props.size_chart || ''
        };
    }
    render() {
        return (
            <Modal isOpen={true} size="lg" centered={true} fade={false} role="dialog" backdrop="static" keyboard={false}>
                <ModalHeader close={<span className="close cursor" onClick={() => { this.props.model_handler() }}>X</span>}>
                    <CardText className="modal-title">
                        Size Chart
                    </CardText>
                </ModalHeader>
                <ModalBody>
                    <div className="login-modal text-center">
                        <FadeIn easing={'ease-out'}>
                            {onload => (
                                <img className="" src={this.state.size_chart} onLoad={onload} />
                            )}
                        </FadeIn>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default SizeChartMdl;