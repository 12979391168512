export { fetch_status_string, fetch_status_color, fetch_payment_mode_string, only_number }
function fetch_status_string(status_id) {
    switch (status_id) {
        case '0':
            return ('Failed')
        case '1':
            return ('Placed')
        case '2':
            return ('Confirmed')
        case '3':
            return ('Order Processing')
        case '4':
            return ('Order Packed')
        case '5':
            return ('Dispatched')
        case '6':
            return ('Delivered')
        case '7':
            return ('Cancelled')
        case '8':
            return ('Return Complete')
        case '9':
            return ('Return Request')
        case '12':
            return ('Return Accepted')
        case '17':
            return ('Return Process')
        case '10':
            return ('Payment Process')
        case '11':
            return ('Payment Failed')
        case '13':
            return ('Replacement Request')
        case '14':
            return ('Replacement Accepted')
        case '15':
            return ('Replacement Process')
        case '16':
            return ('Replacement Complete')
        default:
            return ('Other')
    }
}
function only_number(e) {
    e.target.value = e.target.value.replace(/[^0-9.]/g, ''); e.target.value = e.target.value.replace(/(\..*)\./g, '$1');
}
function fetch_payment_mode_string(status_id) {
    switch (status_id) {
        case '1':
            return ('COD')
        case '2':
            return ('Online')
        default:
            return ('Other')
    }
}
function fetch_status_color(status_id) {
    switch (status_id) {
        case '0':
            return ('badge-danger')
        case '1':
            return ('badge-success')
        case '2':
            return ('badge-info')
        case '3':
            return ('badge-light')
        case '4':
            return ('badge-warning')
        case '5':
            return ('badge-light')
        case '6':
            return ('badge-success')
        case '7':
            return ('badge-danger')
        case '8':
            return ('badge-info')
        case '9':
            return ('badge-warning')
        case '10':
            return ('badge-info')
        case '11':
            return ('badge-danger')
        case '12':
            return ('badge-light')
        case '13':
            return ('badge-warning')
        case '14':
            return ('badge-light')
        case '15':
            return ('badge-info')
        case '16':
            return ('badge-success')
        case '17':
            return ('badge-info')
        default:
            return ('badge-danger')
    }
}
