import $ from 'jquery';
export function one_cat_variation(group_type) {
    return new Promise((resolved) => {
        $(document).ready(function () {
            if (group_type == 'main') {
                $('#all_category').prop("checked", false);
            }
            else if (group_type == 'sub') {
                $('#all_category2').prop("checked", false);
            }
            else {
                $('#all_brand').prop("checked", false);
            }

            var checked_category_product = [];
            var cat_variation = $('input[name="main_cat_variation[]"]');
            cat_variation.each(function () {
                if ($(this).is(':checked')) {
                    checked_category_product.push($(this).val());
                }
            });

            var checked_sub_category_product = [];
            var sub_cat_variation = $('input[name="sub_cat_variation[]"]');
            sub_cat_variation.each(function () {
                if ($(this).is(':checked')) {
                    checked_sub_category_product.push($(this).val());
                }
            });

            var checked_brand_product = [];
            var brand_variation = $('input[name="brand_variation[]"]');
            brand_variation.each(function () {
                if ($(this).is(':checked')) {
                    checked_brand_product.push($(this).val());
                }
            });
            resolved({ 'sub_cat': checked_sub_category_product, 'main_cat': checked_category_product, 'brand': checked_brand_product })
        })
    })
}
export function other_action() {
    $(document).ready(function () {
        $(document).on('click', '.filterBtn', function () {
            var this_element = $(this);
            var filter_show = this_element.attr('filter-show');
            if (filter_show == 'true') {
                $('.shop-filters').hide();
                this_element.text("Filter");
                this_element.attr("filter-show", 'false');
            }
            else {
                $('.shop-filters').show();
                this_element.text("Unfilter");
                this_element.attr("filter-show", 'true');
            }
        })
    })
}
export function all_cat_variation(checked, group_type) {
    return new Promise((resolved) => {
        $(document).ready(function () {
            if (checked) {
                if (group_type == 'main') {
                    $('.main_cat_variation').prop("checked", true);
                }
                else if (group_type == 'sub') {
                    $('.sub_cat_variation').prop("checked", true);
                }
                else {
                    $('.brand_variation').prop("checked", true);
                }
            }
            else {
                if (group_type == 'main') {
                    $('.main_cat_variation').prop("checked", false);
                }
                else if (group_type == 'sub') {
                    $('.sub_cat_variation').prop("checked", false);
                }
                else {
                    $('.brand_variation').prop("checked", false);
                }
            }
            var checked_category_product = [];
            var cat_variation = $('input[name="main_cat_variation[]"]');
            cat_variation.each(function () {
                if ($(this).is(':checked')) {
                    checked_category_product.push($(this).val());
                }
            });

            var checked_sub_category_product = [];
            var sub_cat_variation = $('input[name="sub_cat_variation[]"]');
            sub_cat_variation.each(function () {
                if ($(this).is(':checked')) {
                    checked_sub_category_product.push($(this).val());
                }
            });

            var checked_brand_product = [];
            var brand_variation = $('input[name="brand_variation[]"]');
            brand_variation.each(function () {
                if ($(this).is(':checked')) {
                    checked_brand_product.push($(this).val());
                }
            });
            resolved({ 'sub_cat': checked_sub_category_product, 'main_cat': checked_category_product, 'brand': checked_brand_product })
        })
    })
}
export function fetch_all_selected_option() {

    return new Promise((resolved) => {
        $(document).ready(function () {
            var checked_category_product = [];
            var cat_variation = $('input[name="main_cat_variation[]"]');
            cat_variation.each(function () {
                if ($(this).is(':checked')) {
                    checked_category_product.push($(this).val());
                }
            });

            var checked_sub_category_product = [];
            var sub_cat_variation = $('input[name="sub_cat_variation[]"]');
            sub_cat_variation.each(function () {
                if ($(this).is(':checked')) {
                    checked_sub_category_product.push($(this).val());
                }
            });

            var checked_brand_product = [];
            var brand_variation = $('input[name="brand_variation[]"]');
            brand_variation.each(function () {
                if ($(this).is(':checked')) {
                    checked_brand_product.push($(this).val());
                }
            });
            resolved({ 'sub_cat': checked_sub_category_product, 'main_cat': checked_category_product, 'brand': checked_brand_product })
        })
    })
}