import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux'
import './assets/page-loader.css';
import Home from './pages/Home.js';
import About from './pages/About.js';
import OurBlog from './pages/OurBlog.js';
import OurTeam from './pages/OurTeam.js';
import OurCertificate from './pages/OurCertificate.js';
import WorkWithUs from './pages/WorkWithUs.js';
import QualityPolicy from './pages/QualityPolicy.js';
import PrivacyPolicy from './pages/PrivacyPolicy.js';
import RefundPolicy from './pages/RefundPolicy.js';
import ShippingPolicy from './pages/ShippingPolicy.js';
import TermsCondition from './pages/TermsCondition.js';
import NewArrivals from './pages/NewArrivals.js';
import Sale from './pages/Sale.js';
import Faq from './pages/Faq.js';
import Contact from './pages/Contact.js';
import Login_check from './pages/Login_check.js';
import { insertToken } from './redux/action/tokenAction'
import Myprofile from './pages/Myprofile.js';
import Myaddress from './pages/Myaddress.js';
import Orderlist from './pages/Orderlist.js';
import LoyaltyPoint from './pages/LoyaltyPoint.js';
import ChangePassword from './pages/ChangePassword.js';
import Logout from './pages/Logout.js';
import ProfileLogin from './pages/ProfileLogin.js';
import Product_details from './pages/Product_details.js';
import Category_product from './pages/Category_product.js';
import Order_details from './pages/Order_details.js';
import Order_success from './pages/Order_success.js';
import Notfound from './pages/Notfound.js';
import Checkout from './pages/Checkout.js';
import ProductCheckout from './pages/ProductCheckout.js';
import Wishlist from './pages/Wishlist.js';
import Payment_continue from './pages/Payment_continue.js';
import { apiUrl } from './Base_page.js';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
class App extends Component {
  componentDidMount() {
    this.props.insertToken(apiUrl)
  }
  render() {
    return (
      <>
        <Router>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/work-with-us" component={WorkWithUs} />
            <Route exact path="/quality-policy" component={QualityPolicy} />
            <Route exact path="/our-blog" component={OurBlog} />
            <Route exact path="/our-team" component={OurTeam} />
            <Route exact path="/our-certificate" component={OurCertificate} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/refund-policy" component={RefundPolicy} />
            <Route exact path="/shipping-policy" component={ShippingPolicy} />
            <Route exact path="/terms-condition" component={TermsCondition} />
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/login" component={ProfileLogin} />
            <Route exact path="/product/:name/:id" component={Product_details} />
            <Route exact path="/order-details/:inoice_number/:order_id"><Login_check refer="orderlist" cmp={Order_details} /></Route>
            <Route exact path="/order-success/:inoice_number/:order_id"><Login_check refer="orderlist" cmp={Order_success} /></Route>
            <Route exact path="/category/:c_type/:subcat_name/:cat_id" component={Category_product} />
            <Route exact path="/logout"><Logout /></Route>
            <Route exact path="/wishlist"><Wishlist /></Route>
            <Route exact path="/loyalty-point"><LoyaltyPoint /></Route>
            <Route exact path="/myprofile"><Login_check refer="myprofile" cmp={Myprofile} /></Route>
            <Route exact path="/checkout" component={Checkout} />
            <Route exact path="/checkout/:name/:id" component={ProductCheckout} />
            <Route exact path="/myaddress"><Login_check refer="myaddress" cmp={Myaddress} /></Route>
            <Route exact path="/new-arrivals" component={NewArrivals}></Route>
            <Route exact path="/sale" component={Sale}></Route>
            <Route exact path="/payment-continue" component={Payment_continue} />
            <Route exact path="/changepassword"><Login_check refer="changepassword" cmp={ChangePassword} /></Route>
            <Route exact path="/orderlist"><Login_check refer="orderlist" cmp={Orderlist} /></Route>
            <Route path="*" component={Notfound} />
          </Switch>
          <Footer />
        </Router>
      </>
    );
  }
}
const mapStoreToProps = state => ({
  token: state.token.user_token
})
const mapDispatchToProps = {
  insertToken
}
export default connect(mapStoreToProps, mapDispatchToProps)(App);