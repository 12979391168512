import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { updtae_order_address, pincode_wise_search, fetch_cities_in_option, fetch_state_option } from '../components/Fetch_All_data.js';
class Update_Address extends Component {
   constructor(props) {
      super(props);
      this.state = {
         error: '',
         first_name: '',
         last_name: '',
         contact: '',
         address_1: '',
         userarea: '',
         pincode: '',
         city: '',
         states: '',
         area_id: '',
         id: '',
         state_id: '',
         pincode_id: '',
         city_id: '',
         offfice_type: '1',
         area_list: [],
         state_list: [],
         city_list: [],
         loading: false,
         formIsValid: false,
         alert: null,
         area_isLoaded: false
      };
      this.onChange = this.onChange.bind(this);

   }
   only_number = (e) => {
      e.target.value = e.target.value.replace(/[^0-9.]/g, ''); e.target.value = e.target.value.replace(/(\..*)\./g, '$1');
   }
   update_address() {
      this.setState({ loading: true })
      this.setState({ name1IsValid: false })
      this.setState({ name2IsValid: false })
      this.setState({ mobileIsValid: false })
      this.setState({ adrs1IsValid: false })
      this.setState({ pincodeIsValid: false })
      this.setState({ cityIsValid: false })
      this.setState({ stateIsValid: false })
      this.setState({ area_idIsValid: false })
      this.setState({ userareaIsValid: false })
      let form_data = this.state;
      this.setState({ error_message: '' })
      let formIsValid = true;
      if (form_data.first_name == '') {
         formIsValid = false;
         this.setState({ name1IsValid: true })
      }
      if (form_data.last_name == '') {
         formIsValid = false;
         this.setState({ name2IsValid: true })
      }
      if (form_data.address_1 == '') {
         formIsValid = false;
         this.setState({ adrs1IsValid: true })
      }
      if (form_data.pincode == '') {
         formIsValid = false;
         this.setState({ pincodeIsValid: true })
      }
      if (form_data.city_id == '') {
         formIsValid = false;
         this.setState({ cityIsValid: true })
      }
      /* if (form_data.area_id == '') {
         formIsValid = false;
         this.setState({ area_idIsValid: true })
      } */
      if (form_data.state_id == '') {
         formIsValid = false;
         this.setState({ stateIsValid: true })
      }
      if ((form_data.contact) == null || (form_data.contact).length != '10' || !(form_data.contact).match(/^[0-9]+$/)) {
         formIsValid = false;
         this.setState({ mobileIsValid: true })
      }
      if (form_data.userarea == '') {
         formIsValid = false;
         this.setState({ userareaIsValid: true })
         this.setState({ loading: false })
      }
      if (formIsValid == true) {
         updtae_order_address({
            'muser_adr_id': form_data.id,
            'muser_id': localStorage.getItem('client_id'), 'firstname': form_data.first_name,
            'lastname': form_data.last_name, 'addressline': form_data.address_1, 'addr_type': form_data.offfice_type,
            'pincode': form_data.pincode, 'mobile': form_data.contact, 'area_id': form_data.area_id, 'userarea': form_data.userarea,
            'city_id': form_data.city_id, 'state_id': form_data.state_id, 'pincode_id': form_data.pincode_id
         })
            .then((resolve) => {
               if (resolve['status'] == '1') {
                  const getAlert1 = () => (
                     <SweetAlert success title={resolve['message']} onConfirm={() => { this.setState({ alert: null }); }} />
                  );
                  this.setState({ alert: getAlert1() });
                  window.location.reload(false);
               }
               else {
                  const getAlert1 = () => (
                     <SweetAlert warning title={resolve['message']} onConfirm={() => { this.setState({ alert: null }); }} />
                  );
                  this.setState({ alert: getAlert1() });
                  this.setState({ loading: false })
               }
            },
               (reject) => {
                  console.log(reject);
               })
      }
      this.setState({ loading: false })
   }
   handleKeyUp = (event) => {
      let pincode = event.target.value;
      if (pincode.length == 6) {
         //this.pincode_function(pincode, 0);
      }

   }
   pincode_function(pincode, area_id) {
      pincode_wise_search(pincode)
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  this.setState({ area_list: resolve['data'] });
                  this.setState({ city: resolve['data'][0].city_name });
                  this.setState({ pincode: pincode, area_id: area_id });
                  this.setState({ city_id: resolve['data'][0].city_id });
                  this.setState({ states: resolve['data'][0].state });
                  this.setState({ state_id: resolve['data'][0].state_id });
                  this.setState({ pincode_id: resolve['data'][0].pincode_id });
                  this.setState({ area_isLoaded: true });
               }
               else {
                  const getAlert2 = () => (
                     <SweetAlert warning title={resolve['message']} onConfirm={() => { this.setState({ alert: null }); }} />
                  );
                  this.setState({ alert: getAlert2() });
                  this.setState({ area_isLoaded: false });
                  this.setState({ area_id: '0' });
               }
            },
            (reject) => {
               console.log(reject);
            })
   }
   onChange(e) {
      if (e.target.name == 'area_id') {
         this.setState({ userarea: e.target.options[e.target.selectedIndex].text });
      } else if (e.target.name == 'state_id') {
         this.get_city_list(e.target.value);
      }
      this.setState({ [e.target.name]: e.target.value });
   }

   componentDidMount() {
      let address_data = this.props.address;
      if (address_data) {
         //this.pincode_function(address_data['pinno'], address_data.area_id);
         fetch_state_option().then((resolve) => {
            if (resolve['status'] == 1) {
               this.setState({ state_list: resolve['data'] });
            }
         }, (reject) => {
            console.log(reject);
         });
         this.setState({
            first_name: address_data.fir_name || '', last_name: address_data.lastname || '',
            contact: address_data.mobilenumber || '', pincode: address_data.pinno || '', address_1: address_data.address_line1,
            states: address_data.state_name, offfice_type: address_data.addr_type || '',
            area_id: address_data.area_id, city_id: address_data.city_id, pincode_id: address_data.pincode_id,
            state_id: address_data.state_id, city_name: address_data.city_name, id: address_data.id, userarea: address_data.userarea
         });
         this.get_city_list(address_data.state_id);
      }
   }
   get_city_list(state_id) {
      fetch_cities_in_option(state_id).then((resolve) => {
         if (resolve['status'] == 1) {
            this.setState({ city_list: resolve['data'] });
         }
         else {
            this.setState({ city_list: [] });
         }
      }, (reject) => {
         console.log(reject);
      })
   }
   address_list_display() {
      this.props.parentCallback();
   }
   render() {
      const { error, area_isLoaded } = this.state;
      return (
         <div>
            {this.state.alert}
            <div className="card card-body account-right">
               <div className="widget">
                  <div className="section-header">
                     <h5 className="heading-design-h5">
                        Update Address
                     </h5>
                  </div>
                  <div className="text-right">
                     <p style={{ cursor: 'pointer', 'color': 'red' }} onClick={() => this.address_list_display()}><span className="mdi mdi-chevron-left"></span> Back to Address List</p>
                  </div>
                  <div className="row">
                     <div className="col-sm-6">
                        <div className="form-group">
                           <label className="control-label">First Name <span className="required">*</span></label>
                           <input className="form-control border-form-control" value={this.state.first_name} name="first_name" placeholder="First Name" onChange={this.onChange} type="text" />
                           {this.state.name1IsValid == true ? <span style={{ color: 'red' }}>First Name Required</span> : ''}
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className="form-group">
                           <label className="control-label">Last Name <span className="required">*</span></label>
                           <input className="form-control border-form-control" value={this.state.last_name} name="last_name" placeholder="Last Name" onChange={this.onChange} type="text" />
                           {this.state.name2IsValid == true ? <span style={{ color: 'red' }}>Last Name Required</span> : ''}
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className="form-group">
                           <label className="control-label">Contact<span className="required">*</span></label>
                           <input className="form-control border-form-control" maxLength={10} onInput={(e) => this.only_number(e)} value={this.state.contact} name="contact" placeholder="Contact" onChange={this.onChange} type="text" />
                           {this.state.mobileIsValid == true ? <span style={{ color: 'red' }}>Contact Number Required</span> : ''}
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className="form-group">
                           <label className="control-label">Select Address Type <span className="required" >*</span></label>
                           <select name="offfice_type" value={this.state.offfice_type} onChange={this.onChange} className="select2 form-control border-form-control">
                              <option value="1">HOME</option>
                              <option value="2">OFFICE</option>
                              <option value="3">OTHER</option>
                           </select>
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className="form-group">
                           <label className="control-label">Pincode<span className="required">*</span></label>
                           <input className="form-control border-form-control" value={this.state.pincode} onInput={(e) => this.only_number(e)} name="pincode" placeholder="Pincode" onChange={this.onChange} type="text" />
                           {this.state.pincodeIsValid == true ? <span style={{ color: 'red' }}>Pincode Required</span> : ''}
                        </div>
                     </div>
                     {/* <div className="col-sm-6">
                        <div className="form-group">
                           <label className="control-label">Select Area <span className="required" >*</span></label>
                           {this.state.area_isLoaded == true ?
                              <select name="area_id" value={this.state.area_id} onChange={this.onChange} className="select2 form-control border-form-control">
                                 <option key={"area_0012"} value="0">Select Area</option>
                                 {
                                    this.state.area_list.map((area, index) => {
                                       return (
                                          <option key={"area_" + index} value={area.a_id}>{area.area_name}</option>
                                       )
                                    })}
                              </select>
                              :
                              <select name="area_id" value="0" onChange={this.onChange} className="select2 form-control border-form-control">
                                 <option key={"area_0012"} value="0">Select Area</option>
                              </select>}
                           {this.state.area_idIsValid == true ? <span style={{ color: 'red' }}>Area Required</span> : ''}
                        </div>
                     </div> */}
                     {/* <div className="col-sm-6">
                        <div className="form-group">
                           <label className="control-label">City<span className="required">*</span></label>
                           <input className="form-control border-form-control" readOnly value={this.state.city} name="city" placeholder="City" onChange={this.onChange} type="text" />
                           {this.state.cityIsValid == true ? <span style={{ color: 'red' }}>City Required</span> : ''}
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className="form-group">
                           <label className="control-label">State<span className="required">*</span></label>
                           <input className="form-control border-form-control" readOnly value={this.state.states} name="states" placeholder="State" onChange={this.onChange} type="text" />
                           {this.state.stateIsValid == true ? <span style={{ color: 'red' }}>State Required</span> : ''}
                        </div>
                     </div> */}
                     <div className="col-sm-6">
                        <div className="form-group">
                           <label className="control-label">Select State <span className="required" >*</span></label>
                           <select name="state_id" value={this.state.state_id} onChange={this.onChange} className="select2 form-control border-form-control">
                              <option key={"state_id_12"} value="">Select State</option>
                              {
                                 this.state.state_list.map((state, index) => {
                                    return (
                                       <option key={"state_" + state.state_code_id} value={state.state_code_id}>{state.state_name}</option>
                                    )
                                 })}
                           </select>
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className="form-group">
                           <label className="control-label">Select City <span className="required" >*</span></label>
                           <select name="city_id" value={this.state.city_id} onChange={this.onChange} className="select2 form-control border-form-control">
                              <option key={"city_id_12"} value="">Select City</option>
                              {
                                 this.state.city_list.map((city, index) => {
                                    return (
                                       <option key={"city_" + city.city_id} value={city.city_id}>{city.city_name}</option>
                                    )
                                 })}
                           </select>
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className="form-group">
                           <label className="control-label">Area/NearBy<span className="required">*</span></label>
                           <input className="form-control border-form-control" value={this.state.userarea} name="userarea" placeholder="Area/NearBy" onChange={this.onChange} type="text" />
                           {this.state.userareaIsValid == true ? <span style={{ color: 'red' }}>Area/NearBy Required</span> : ''}
                        </div>
                     </div>
                     <div className="col-sm-12">
                        <div className="form-group">
                           <label className="control-label">Full Address<span className="required">*</span></label>
                           <input className="form-control border-form-control" value={this.state.address_1} name="address_1" placeholder="Full Address" onChange={this.onChange} type="text" />
                           {this.state.adrs1IsValid == true ? <span style={{ color: 'red' }}>Full Address Required</span> : ''}
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-sm-12 text-center">
                        <button type="button" disabled={this.state.loading} onClick={() => this.update_address()} className="btn btn-success btn-lg"> {this.state.loading && <i className="mdi mdi-refresh mdi-spin" aria-hidden="true"></i>} Update Address </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}
export default Update_Address;
