import React, { Component } from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import { updtae_password } from '../components/Update_all_data.js';
import Profile_Nav from './Profile_Nav.js';
class ChangePassword extends Component {
   constructor(props) {
      super(props);
      this.state = {
         loading: false,
         alert: null,
         old_pass: '',
         new_pass: '',
         conform_new_pass: '',
         old_pass_Isloaded: false,
         new_pass_Isloaded: false,
         con_new_pass_Isloaded: false,
      };
      this.onChange = this.onChange.bind(this);
   }
   onChange(e) {
      this.setState({ [e.target.name]: e.target.value });
   }
   update_password() {
      this.setState({ loading: true })
      this.setState({ old_pass_Isloaded: false })
      this.setState({ new_pass_Isloaded: false })
      this.setState({ con_new_pass_Isloaded: false })
      let form_data = this.state;
      this.setState({ error_message: '' })
      let formIsValid = true;
      if (form_data.old_pass == '') {
         formIsValid = false;
         this.setState({ old_pass_Isloaded: true })
      }
      if (form_data.new_pass == '') {
         formIsValid = false;
         this.setState({ new_pass_Isloaded: true })
      }
      if (form_data.conform_new_pass == '' || form_data.new_pass != form_data.conform_new_pass) {
         formIsValid = false;
         this.setState({ con_new_pass_Isloaded: true })
      }
      if (formIsValid == true) {
         updtae_password(form_data.conform_new_pass, form_data.new_pass, form_data.old_pass)
            .then((resolve) => {
               if (resolve['status'] == '1') {
                  this.setState({ error: resolve['message'] });
                  const getAlert1 = () => (
                     <SweetAlert success title={resolve['message']} onConfirm={() => { this.setState({ alert: null }); window.location.reload() }} />
                  );
                  this.setState({ alert: getAlert1() });
               }
               else {
                  const getAlert1 = () => (
                     <SweetAlert warning title={resolve['message']} onConfirm={() => { this.setState({ alert: null }); }} />
                  );
                  this.setState({ alert: getAlert1() });
               }
            },
               (reject) => {
                  console.log(reject);
               })
      }
      this.setState({ loading: false })
   }
   render() {
      return (
         <div>
            {/* <Header /> */}
            <div className="page-container">
               {this.state.alert}
               <section className="account-page section-padding">
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-10 mx-auto">
                           <div className="row no-gutters">
                              <div className="col-md-4">
                                 <Profile_Nav current_page={"changepassword"} />
                              </div>
                              <div className="col-md-8">
                                 <div className="card card-body account-right">
                                    <div className="widget">
                                       <div className="section-header">
                                          <h5 className="heading-design-h5">
                                             Change Password
                                          </h5>
                                       </div>
                                       <div className="row">
                                          <div className="col-sm-8">
                                             <div className="form-group">
                                                <label className="control-label">Old Password <span className="required">*</span></label>
                                                <input className="form-control border-form-control" name="old_pass" onChange={this.onChange} type="password" />
                                                {this.state.old_pass_Isloaded == true ? <span style={{ color: 'red' }}>Old Password Required</span> : ''}
                                             </div>
                                          </div>
                                          <div className="col-sm-8">
                                             <div className="form-group">
                                                <label className="control-label">New  Password <span className="required">*</span></label>
                                                <input className="form-control border-form-control" name="new_pass" onChange={this.onChange} type="password" />
                                                {this.state.new_pass_Isloaded == true ? <span style={{ color: 'red' }}>New Password Required</span> : ''}
                                             </div>
                                          </div>
                                          <div className="col-sm-8">
                                             <div className="form-group">
                                                <label className="control-label">Conform New  Password <span className="required">*</span></label>
                                                <input className="form-control border-form-control" name="conform_new_pass" onChange={this.onChange} type="password" />
                                                {this.state.con_new_pass_Isloaded == true ? <span style={{ color: 'red' }}>Conform New Password Required</span> : ''}
                                             </div>
                                          </div>
                                       </div>
                                       <div className="row">
                                          <div className="col-sm-8 text-center">
                                             <button type="button" className="btn btn-success btn-lg" disabled={this.state.loading} onClick={() => this.update_password()}>{this.state.loading && <i className="mdi mdi-refresh mdi-spin" aria-hidden="true"></i>} Update Password </button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
            {/* <Footer /> */}
         </div>
      );
   }
}
export default ChangePassword;
