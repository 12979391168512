import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import OwlCarousel from 'react-owl-carousel';
import FadeIn from "react-lazyload-fadein";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { extract_price_detail, home_page_all_section_product, fetch_home_slider, fetch_product_main_group, get_data_all } from '../components/Fetch_All_data.js';
//import { add_cart_plus_multi } from '../assets/custom.js';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { ProductContaner } from '../components/ProductDetail_Comp.jsx';
import Loader, { Oval } from 'react-loader-spinner';
import service_info_banner from '../assets/img/service_info_banner.jpg';
import placeholder from '../assets/img/placeholder.jpeg';
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      cat_isLoaded: false,
      android_model_status: false,
      android_image_status: false,
      slider_isLoaded: false,
      pb1_heading: '',
      price_header_title: '',
      pb1_status: false,
      pb2_status: false,
      pb3_status: false,
      section_status: false,
      price_variation_status: false,
      main_cat_isLoaded: false,
      section_product: [],
      price_variation_list: [],
      slider: [],
      android_image: [],
      pb1_image: [],
      pb2_image: [],
      pb3_image: [],
      category: [],
      subgroup: []
    };
  }
  load_product_group = () => {
    fetch_product_main_group()
      .then(
        (resolve) => {
          if (resolve['status'] == 1) {
            this.setState({
              category: resolve['data'],
              //subgroup: resolve['subgroup'],
              //cat_isLoaded: true,
              main_cat_isLoaded: true
            });
          }
        },
        (reject) => {
          console.log(reject)
        }
      )
  }
  componentDidMount() {
    //add_cart_plus_multi();
    //var actual_area_id = localStorage.getItem('actual_area_id');
    let mythis = this;
    //if (actual_area_id) {
    mythis.load_home_slider();
    //mythis.load_product_group();
    setTimeout(function () {
      mythis.all_section_product();
    }, 2000);
    setTimeout(function () {
      mythis.get_price_variation_list();
    }, 1000);
    setTimeout(function () {
      mythis.display_app_download();
    }, 2000);
    // }
  }
  display_app_download = () => {
    if (this.state.android_image_status == true) {
      this.setState({ android_model_status: true })
    }
  }
  load_home_slider = () => {
    fetch_home_slider()
      .then(
        (resolve) => {
          if (resolve['status'] == 1) {
            this.setState({
              slider: resolve['banner'],
              slider_isLoaded: true
            });
          }
          if (resolve['pb1_status'] == 1) {
            this.setState({
              pb1_image: resolve['pb1_image'], pb1_status: true, pb1_heading: resolve['pb1_heading']
            });
          }
          if (resolve['pb2_status'] == 1) {
            this.setState({
              pb2_image: resolve['pb2_image'], pb2_status: true
            });
          }
          if (resolve['pb3_status'] == 1) {
            this.setState({
              pb3_image: resolve['pb3_image'], pb3_status: true
            });
          }
          if (resolve['android_image_status'] == 1) {
            this.setState({
              android_image: resolve['android_image'], android_image_status: true
            });
          }
        },
        (reject) => {
          console.log(reject)
        }
      )
  }
  all_section_product() {
    home_page_all_section_product()
      .then(
        (resolve) => {
          if (resolve['section_status'] == true) {
            this.setState({
              section_product: resolve['product'],
              section_status: true
            });
          }
        },
        (reject) => {
          console.log(reject)
        }
      )
  }
  get_price_variation_list() {
    get_data_all('m/get-price-variation-list', {})
      .then(
        (resolve) => {
          if (resolve['status'] == true) {
            this.setState({
              price_variation_list: resolve['data'],
              price_header_title: resolve['price_header_title'],
              price_variation_status: true
            });
          }
        },
        (reject) => {
          console.log(reject)
        }
      )
  }
  render() {
    const { error, cat_isLoaded, pb1_image, pb2_image, price_header_title, slider_isLoaded, slider } = this.state;
    return (
      <>
        <Helmet>
          <title>Harpra Fashion Store | The Brand of Tshirt</title>
        </Helmet>
        <div className="page-container">
          {this.state.android_model_status ?
            <div className="popup1">
              <div className="pop-container">
                <span onClick={() => this.setState({ android_model_status: false })} className="closepopup" title="Close Modal">×</span>
                <div className="imgcontainer">
                  <a href={this.state.android_image['weblink'] || ''}><img className="popup-img-rounded" src={this.state.android_image['image'] || ''} /></a>
                </div>
              </div>
            </div>
            : ''}
          <section className="carousel-slider-main text-center border-top border-bottom bg-white">
            {slider_isLoaded == true ?
              <OwlCarousel className="owl-theme owl-carousel owl-carousel-slider" responsiveClass={true} mouseDrag={true} navContainerClass="owl-controls clickable" dots={false} navClass={['owl-prev', 'owl-next']} navElement="div" controlsClass="owl-buttons" items="1" nav={true} lazyLoad={true} autoplay={true} loop={true}>
                {slider.map((slide, index) => {
                  return (
                    <div className="item" key={"slide_" + index}>
                      <a href={slide.weblink}>
                        <LazyLoadImage effect="blur" placeholderSrc={placeholder} visibleByDefault={true} wrapperClassName="image-slider-container" threshold={50} className="img-slider" alt={slide.weblink} src={slide.image} />
                      </a>
                    </div>
                  )
                })}
              </OwlCarousel>
              :
              <div>
                {
                  <Oval arialLabel="loading-indicator" height={150} color="#d98688" secondaryColor="white" strokeWidth={5} strokeWidthSecondary={1} wrapperStyle={{ marginTop: "2%", display: "inline-block" }} />
                }
              </div>}
          </section>
          {cat_isLoaded == true ?
            <section className="top-category section-padding">
              <div className="container-fluid">
                <OwlCarousel className="owl-theme owl-carousel-category" navContainerClass="owl-controls clickable" navClass={['owl-prev', 'owl-next']} navElement="div" controlsClass="owl-buttons" responsive={{ 0: { items: 2 }, 600: { items: 4 }, 1000: { items: 7 } }} dots={false} responsiveClass={true} nav={true} lazyLoad={true} rewind={true} autoplay={true}>
                  {this.state.subgroup.map((categorys, index) => {
                    return (
                      <div className="item" key={"sub_" + index}>
                        <div className="category-item">
                          <a href={"/category/sub/" + categorys.group_slug + "/" + categorys.id}>
                            <FadeIn easing={'ease-out'}>
                              {onload => (
                                <img className="img-fluid" alt={categorys.group_name} src={categorys.img_paths} title={categorys.group_name} onLoad={onload} />
                              )}
                            </FadeIn>
                            <h6>{categorys.group_name}</h6>
                          </a>
                        </div>
                      </div>
                    )
                  })}
                </OwlCarousel>
              </div>
            </section>
            : ''}
          {this.state.main_cat_isLoaded == true ?
            <section className="section-padding">
              <div className="container-fluid">
                <div className="section-header text-center line-bottom">
                  <h5 className="heading-design-h1">Shop By Top Category</h5>
                </div>
                <div className="row">
                  {this.state.category.map((categorys, index) => {
                    return (
                      <div className="col-sm-2 col-lg-2 col-xs-2 col-md-2 pl-1 pr-2 mb-2" key={"main_" + index}>
                        <div className="text-center category-box">
                          <a href={"/category/main/" + categorys.group_slug + "/" + categorys.id}>
                            <FadeIn easing={'ease-out'}>
                              {onload => (
                                <img style={{ width: '100%', borderRadius: '5px' }} className="img-fluid" alt={categorys.group_name} src={categorys.img_paths} title={categorys.group_name} onLoad={onload} />
                              )}
                            </FadeIn>
                            <div className="text-center mt-1 mb-1">
                              <h6 className="heading-design-h6">{categorys.group_name}</h6>
                            </div>
                          </a>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </section>
            : ''}
          {
            (this.state.price_variation_status == true && (this.state.price_variation_list.length) >= 3) ?
              <section className="offer-product mb-5">
                <div className="container-fluid">
                  <div className="section-header text-center line-bottom">
                    <h5 className="heading-design-h1">{price_header_title}</h5>
                  </div>
                  <div className="row no-gutters">
                    {this.state.price_variation_list.slice(0, 3).map((list, index) => {
                      return (
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" key={'image' + index}>
                          <div className="pd-5 text-center">
                            <Link to={"/category/hot/" + list.section_slug + "/" + list.section_id}>
                              <FadeIn easing={'ease-out'}>
                                {onload => (
                                  <img className="variation-image" src={list.image} alt={list.section_header} onLoad={onload} />
                                )}
                              </FadeIn>
                            </Link>
                          </div>
                        </div>
                      )
                    }
                    )
                    }
                  </div>
                </div>
              </section>
              : ''
          }
          {this.state.section_status == true ?
            this.state.section_product.slice(0, 1).map((section, index) => (
              (section.product_status == true && section.products.length > 4) ?
                <section className="product-items-slider section-padding" key={'section2' + index}>
                  <div className="container-fluid">
                    <div className="section-header text-center line-bottom">
                      <h5 className="heading-design-h1" section-key={'section2-' + index}>{section.section_header}
                      </h5>
                    </div>
                    <OwlCarousel className="owl-theme owl-carousel owl-carousel-featured" navContainerClass="owl-controls clickable" dots={false} responsive={{ 0: { items: 2 }, 600: { items: 3 }, 1000: { items: 4 } }} responsiveClass={true} navElement="div" nav={true} autoplay={true} loop={true} rewind={false} touchDrag={false} pullDrag={false} lazyLoad={false} mouseDrag={false}>
                      {section.products.slice(0, 10).map((product, index) => {
                        let price = extract_price_detail(product.price_detail);
                        return (
                          <div className="item" key={"pro1_" + product.id}>
                            <div className="product">
                              <Link to={"/product/" + product.product_slug + "/" + product.id + "?variant=" + price[0]['product_price_id']}>
                                <div className="product-header">
                                  <>
                                    {price[0]['unit_mrp'] != price[0]['unit_price'] ?
                                      <span className={"badge badge-success offerText_" + product.id}>
                                        Upto {price[0].disc_type == 'flat' ?
                                          price[0].unit_discount + ' ₹ ' : price[0].discount_percent + ' % '}
                                        OFF</span> : <></>
                                    }
                                    <img effect="blur" className="img-fluid" src={product.img_paths} alt={product.p_name} />
                                    {/* <span className="veg text-success mdi mdi-circle"></span> */}
                                  </>
                                </div>
                              </Link>
                              <ProductContaner product={product} price_detail={price} />
                            </div>
                          </div>
                        )
                      })}
                    </OwlCarousel>
                  </div>
                </section>
                : ''
            ))
            : ''
          }

          {
            (this.state.pb1_status == true && (pb1_image.length) > 1) ?
              <section className="offer-product mb-5">
                <div className="container-fluid">
                  <div className="section-header text-center line-bottom">
                    <h5 className="heading-design-h1">{this.state.pb1_heading}</h5>
                  </div>
                  <div className="row no-gutters">
                    {pb1_image.slice(0, 2).map((list, index) => {
                      return (
                        <div className={index == 0 ? 'col-md-8' : 'col-md-4'} key={'image' + index}>
                          <div className={index == 0 ? "pd-20" : "pd-20"}>
                            <a href={list.weblink}>
                              <FadeIn easing={'ease-out'}>
                                {onload => (
                                  <img style={{ width: '100%' }} src={list.image} alt={list.weblink} onLoad={onload} />
                                )}
                              </FadeIn>
                            </a>
                          </div>
                        </div>
                      )
                    }
                    )
                    }
                  </div>
                </div>
              </section>
              : ''
          }

          {this.state.section_status == true ?
            this.state.section_product.slice(1, 2).map((section, index) => (
              (section.product_status == true && section.products.length > 4) ?
                <section className="product-items-slider section-padding" key={'section3' + index}>
                  <div className="container-fluid">
                    <div className="section-header text-center line-bottom">
                      <h5 className="heading-design-h1" section-key={'section3' + index}>{section.section_header}
                      </h5>
                    </div>
                    <OwlCarousel className="owl-theme owl-carousel owl-carousel-featured" navContainerClass="owl-controls clickable" dots={false} responsive={{ 0: { items: 2 }, 600: { items: 3 }, 1000: { items: 5 } }} responsiveClass={true} navElement="div" nav={true} autoplay={true} loop={true} rewind={false} touchDrag={false} pullDrag={false} lazyLoad={false} mouseDrag={false}>
                      {section.products.slice(0, 10).map(product => {
                        let price = extract_price_detail(product.price_detail);
                        return (
                          <div className="item" key={"pro2_" + product.id}>
                            <div className="product">
                              <Link to={"/product/" + product.product_slug + "/" + product.id + "?variant=" + price[0]['product_price_id']}>
                                <div className="product-header">
                                  <>
                                    {price[0]['unit_mrp'] != price[0]['unit_price'] ?
                                      <span className={"badge badge-success offerText_" + product.id}>
                                        Upto {price[0].disc_type == 'flat' ?
                                          price[0].unit_discount + ' ₹ ' : price[0].discount_percent + ' % '}
                                        OFF</span> : <></>}
                                    <img className="img-fluid" src={product.img_paths} alt={product.p_name} />
                                    {/* <span className="veg text-success mdi mdi-circle"></span> */}
                                  </>
                                </div>
                              </Link>
                              <ProductContaner product={product} price_detail={price} />
                            </div>
                          </div>
                        )
                      })}
                    </OwlCarousel>
                  </div>
                </section>
                : ''
            ))
            : ''
          }
          {
            this.state.pb2_status == true ?
              <section className="offer-product mb-1 pb-1">
                <div className="container-fluid">
                  <div className="row no-gutters">
                    {pb2_image.slice(0, 1).map((list, index) => {
                      return (
                        <div className="col-md-12" key={'image' + index}>
                          <a href={list.weblink}><img style={{ width: '100%' }} src={list.image} /></a>
                        </div>
                      )
                    }
                    )
                    }
                  </div>
                </div>
              </section>
              : ''
          }
          {this.state.section_status == true ?
            this.state.section_product.slice(2, 3).map((section, index) => (
              (section.product_status == true && section.products.length > 4) ?
                <section className="product-items-slider section-padding" key={'section3' + index}>
                  <div className="container-fluid">
                    <div className="section-header">
                      <h5 className="heading-design-h5">{section.section_header}
                        <Link className="float-right text-secondary" to="#">View All</Link>
                      </h5>
                    </div>
                    <OwlCarousel className="owl-theme owl-carousel owl-carousel-featured" navContainerClass="owl-controls clickable" dots={false} responsive={{ 0: { items: 2 }, 600: { items: 3 }, 1000: { items: 5 } }} responsiveClass={true} navElement="div" nav={true} autoplay={true} rewind={false} loop={true} touchDrag={false} pullDrag={false} lazyLoad={false} mouseDrag={false}>
                      {section.products.slice(0, 10).map(product => {
                        let price = extract_price_detail(product.price_detail);
                        return (
                          <div className="item" key={"pro3_" + product.id}>
                            <div className="product">
                              <Link to={"/product/" + product.product_slug + "/" + product.id + "?variant=" + price[0]['product_price_id']}>
                                <div className="product-header">
                                  <>
                                    {price[0]['unit_mrp'] != price[0]['unit_price'] ?
                                      <span className={"badge badge-success offerText_" + product.id}>
                                        Upto {price[0].disc_type == 'flat' ?
                                          price[0].unit_discount + ' ₹ ' : price[0].discount_percent + ' % '}
                                        OFF</span> : <></>
                                    }
                                    <img className="img-fluid" src={product.img_paths} alt={product.p_name} />
                                    {/* <span className="veg text-success mdi mdi-circle"></span> */}
                                  </>
                                </div>
                              </Link>
                              <ProductContaner product={product} price_detail={price} />
                            </div>
                          </div>
                        )
                      })}
                    </OwlCarousel>
                  </div>
                </section>
                : ''
            ))
            : ''
          }
          <section className="section-padding bg-white text-center">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <img alt="Harpra service info" src={service_info_banner} style={{ width: '100%' }} />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
export default Home;
