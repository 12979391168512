import { apiUrl } from '../Base_page.js';
export { updtae_password,update_profile_action }
function updtae_password(conform_new_pass, new_pass, old_pass) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/update_muser_password', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'muser_id': localStorage.getItem('client_id'),'user_new_pass':new_pass,
      'user_old_pass':old_pass,'conform_new_pass':conform_new_pass})
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
        .catch((error) => {
            reject(error);
          })
      });
  });
}
function update_profile_action(option_data) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/update_muser_acc_details', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify(option_data)
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
        .catch((error) => {
            reject(error);
          })
      });
  });
}