import React, { Component } from 'react';
function ProductContaner(props) {
    const product = props.product;
    const price_detail = props.price_detail;
    return (
        <>
            <div className="product-body">
                <h5>{product.p_name}</h5>
                <h6>{/* <strong><span className="mdi mdi-approval" /> Available in</strong> - */}
                    {/* <span className={"avl_" + product.id}>{price_detail[0].unit_quantity} {price_detail[0].unit}   @{parseFloat(price_detail[0]['unit_price']) / parseInt(price_detail[0].unit_quantity)}/{price_detail[0].unit}</span> */}
                </h6>
            </div>
            {
                (price_detail != null) ?
                    <div className="product-footer text-center">
                        <p className="offer-price mt-2 mb-2">
                            ₹<span className={"sale_" + product.id}>
                                {price_detail[0]['unit_price'] || 0}
                            </span>
                            {price_detail[0]['unit_mrp'] != price_detail[0]['unit_price'] ?
                                <span className="ml-3 regular-price">
                                    ₹ <span className={"mrp_" + product.id}>
                                        {price_detail[0]['unit_mrp'] || 0}
                                    </span>
                                </span>
                                : ''}
                        </p>
                        <a data-product_id={product.id} href={"/product/" + product.product_slug + "/" + product.id + "?variant=" + price_detail[0]['product_price_id']} id={"p1_" + product.id} className="btn buy-button-1 btn-sm"><i className="mdi mdi-cart-outline"></i> Buy</a>
                        {/* <div>
                            <select id={"grid1_" + product.id} data-wish-added={product.wish_added} className={"form-control mb-3 mt-1 product_price_id grid1_" + product.id}>
                            {price_detail.map(price => (
                                (price) ?

                                    <option key={"price" + price['product_price_id'] + product.id} value={price['product_price_id']}>{price['unit_quantity']}&nbsp;{price['unit']} - ₹
                                        {price['unit_price']}({(price['disc_type'] == 'flat' ? ' ₹' + price['unit_discount'] : price['discount_percent'] + '% ')}OFF)</option>
                                    : ''
                            ))}
                            </select>
                        </div> */}
                        {/* <div className="row">
                                <div className="col-lg-12 col-sm-12">
                                {
                                    (price_detail[0]['CartQty'] > 0) ?
                                        <div className={"text-center product-grid-" + product.id}>
                                            <div className="table-cart-stepper float-center">
                                            <div className="number-style text-center">
                                                <span data-product-id={(product.id)} data-product-operater="-" data-price-id={price_detail[0]['product_price_id']} className="number-minus changeproductQty"></span>
                                                <input type="number" readOnly className={"product_" + product.id} value={price_detail[0]['CartQty']} />
                                                <span data-product-id={(product.id)} data-product-operater="+" data-price-id={price_detail[0]['product_price_id']} className="number-plus changeproductQty"></span>
                                            </div>
                                            </div> */}
                        {/*  <button type="button" data-wish-added={product.wish_added} data-price-id={price_detail[0]['product_price_id']} data-product-id={product.id} className="btn btn-secondary removeCartProduct btn-sm float-left"><i className="mdi mdi-close"></i></button> */}
                        {/*  <a type="button" data-wish-product-id={product.id} className="wishlist-icon p_add_wishlist float-right" title="Add to Wishlist">
                                {product.wish_added == 0 ?
                                <i className="mdi mdi-heart-outline text-danger"></i>
                                :
                                <i className="mdi mdi-heart text-danger"></i>
                                }
                            </a> */}
                        {/* </div>
                            :
                            <div className={"text-center product-grid-" + product.id}>
                                <button type="button" data-wish-added={product.wish_added} data-product_id={product.id} id={"p1_" + product.id} className="addTocartBtn btn float-left"> <i className="mdi mdi-cart-outline"></i> Add To Cart </button> */}
                        {/* <a type="button" data-wish-product-id={product.id} className="wishlist-icon p_add_wishlist float-right" title="Add to Wishlist">
                                {product.wish_added == 0 ?
                                <i className="mdi mdi-heart-outline text-danger"></i>
                                :
                                <i className="mdi mdi-heart text-danger"></i>
                                }
                            </a> */}
                        {/* </div> */}
                        {/*  }
                            </div>
                        </div> */}
                    </div>
                    : ''
            }
        </>
    )
}
export { ProductContaner };