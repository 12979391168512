import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import { get_data_all } from '../components/Fetch_All_data.js';
class OurTeam extends Component {
   constructor(props) {
      super(props);
      this.state = {
      };
   }
   componentDidMount() {
   }
   render() {
      return (
         <>
            <Helmet>
               <title>Our Team | Harpra</title>
            </Helmet>
            {/* <Header /> */}
            <div className="page-container">
               <section className="inner-header">
                  <div className="container">
                     <div className="row">
                        <div className="col-md-12 text-center">
                           <h1 className="mt-3 mb-2 text-black">Our Team</h1>
                           <div className="breadcrumbs">
                              <p className="mb-2 text-black"><Link className="text-black" to="/">Home</Link>  /  <span className="text-success">Our Team</span></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="bg-white">
                  <div className="container pt-5">
                     <div className='row'>
                     </div>
                  </div>
               </section>
            </div>
            {/* <Footer /> */}
         </>
      );
   }
}
export default OurTeam;
