import $ from 'jquery';
import { cart_add_quantity, add_remove_wish_list, remove_cart_product2, check_cart_product } from '../components/Cart_Action.js';
export function cart_toggle() {
    $(document).ready(function () {
        $('[data-toggle="offcanvas"]').on('click', function () {
            $('body').toggleClass('toggled');
        });
        $(document).click(function (e) {
            if ($(e.target).closest(".searchcontent").length == 0 && $(e.target).closest(".top-categories-search").length == 0) {
                $('.searchdiv').hide();
            }
        });
        $(document).on('click', 'li.nav-item', function () {
            console.log('clicked')
            //$("#navbarText").removeClass("show");
            if (window.innerWidth < 700) {
                $(".navbar-toggler").click();
            }
        });
    })
}
export function remove_btn_from_html(product_id, cart_id, cart_value) {
    $(document).ready(function () {
        var content = '<button type="button" data-product_id="' + product_id + '" id="p1_' + product_id + '" class="addTocartBtn btn btn-sm float-right"> Add To Cart <i class="mdi mdi-plus"></i></button>';
        $('.product-grid-' + product_id).html(content);
        $("#checkout_" + product_id + "_" + cart_id).remove();
        $("#total_cart_price").text(cart_value.total_mrp_amount || 0);
        $("#total_cart_discount").text(cart_value.discount || 0);
        $("#total_cart_payble").text(cart_value.total_amount || 0);
        $("#total_payble_price").text(cart_value.total_amount || 0);
    })
}
export function header_cart_plus_minus() {
    $(document).on('click', '.headerchangeproductQty', function () {
        var this_element = $(this);
        var product_id = this_element.attr('data-product-id');
        var price_id = this_element.attr('data-price-id');
        var old_quantity = parseInt($('.product_' + product_id).val());
        var operater = this_element.attr('data-product-operater');
        var quantity = operater == '+' ? (old_quantity) + 1 : (old_quantity) - 1;
        if (product_id && quantity > 0) {
            if (price_id) {
                cart_add_quantity(price_id, product_id, quantity)
                    .then(
                        (resolve) => {
                            if (resolve['status'] == 1) {
                                let cart_value = resolve['cart_value'];
                                console.log(cart_value)
                                $('.product_' + product_id).val(quantity);
                                $('#cart_total_price_' + price_id).text(resolve['total_price'] || 0);
                                $('#cart_total_mrp_price_' + price_id).text(resolve['total_mrp_price'] || 0);
                                $("#total_cart_price").text(cart_value.total_mrp_amount || 0);
                                $("#total_cart_discount").text(cart_value.discount || 0);
                                $("#total_cart_payble").text(cart_value.total_amount || 0);
                                $("#total_payble_price").text(cart_value.total_amount || 0);
                            }
                        })
            }
        }
    });
}
export function rating_system() {
    $(document).ready(function () {
        $(document).on('click', ".rateButton", function () {
            if (!$(this).hasClass('harpra-text-color')) {
                $(this).addClass('harpra-text-color star-selected');
                $(this).prevAll('.rateButton').addClass('harpra-text-color star-selected');
                $(this).nextAll('.rateButton').removeClass('harpra-text-color star-selected');
            } else {
                $(this).nextAll('.rateButton').removeClass('harpra-text-color star-selected');
                $(this).removeClass('harpra-text-color star-selected');
            }
            $("#ratingValue").val($('.star-selected').length);
        });
    })
}
export function update_rating_system(count) {
    $(document).ready(function () {
        $.each($(".rateButton"), function (index, value) {
            if (index < count) {
                $(value).addClass('harpra-text-color star-selected');
                $(value).prevAll('.rateButton').addClass('harpra-text-color star-selected');
                $(value).nextAll('.rateButton').removeClass('harpra-text-color star-selected');
            } else {
                $(value).nextAll('.rateButton').removeClass('harpra-text-color star-selected');
                $(value).removeClass('harpra-text-color star-selected');
            }
        });
        $("#ratingValue").val(count);
    });
}
export function add_cart_plus_multi() {
    $(document).ready(function () {
        $(document).on('click', '.addTocartBtn', function () {
            var this_element = $(this);
            var quantity = 1;
            var product_id = this_element.attr('data-product_id');
            var wish_added = this_element.attr('data-wish-added');
            var old_cart_value = parseInt($('.cart-value').text());
            if (product_id) {
                var price_id = $('#grid1_' + product_id).val();
                if (price_id) {
                    cart_add_quantity(price_id, product_id, quantity)
                        .then(
                            (resolve) => {
                                if (resolve['status'] == 1) {
                                    $('.cart-value').text(old_cart_value + quantity);
                                    var content = '<div class="table-cart-stepper float-center">'
                                        + '<div class="number-style text-center">'
                                        + '<span data-product-id="' + product_id + '" data-price-id="' + price_id + '" data-product-operater="-" class="number-minus changeproductQty"></span>'
                                        + '<input type="number" readOnly class="product_' + product_id + '" value="1" />'
                                        + '<span data-product-id="' + product_id + '" data-price-id="' + price_id + '" data-product-operater="+" class="number-plus changeproductQty"></span>'
                                        + '</div>'
                                        + '</div>';
                                    //+ '<button type="button" data-wish-added="' + wish_added + '" data-price-id="' + price_id + '" data-product-id="' + product_id + '" class="btn btn-secondary removeCartProduct btn-sm float-left"><i class="mdi mdi-close"></i></button>';

                                    /* content = content + '<a type="button" data-wish-product-id="' + product_id + '" class="wishlist-icon p_add_wishlist float-right" title="Add to Wishlist">';
                                    if (wish_added == 0) {
                                        content = content + '<i class="mdi mdi-heart-outline text-danger"></i>';
                                    }
                                    else {
                                        content = content + '<i class="mdi mdi-heart text-danger"></i>';
                                    }
                                    content = content + '</a>'; */
                                    $('.product-grid-' + product_id).html(content);
                                }
                            })
                }
            }
        });
        $(document).on('click', '.p_add_wishlist', function () {
            var this_element = $(this);
            var product_id = this_element.attr('data-wish-product-id');
            var old_wish_value = parseInt($('.wishList-value').text());
            add_remove_wish_list(product_id)
                .then(
                    (resolve) => {
                        if (resolve['status'] == 1) {
                            $(this_element).html('<i class="mdi mdi-heart text-danger"></i>');
                            $('#grid1_' + product_id).attr('data-wish-added', '1');
                            $("button[data-product-id=" + product_id + "]").attr('data-wish-added', '1');
                            $("button[data-product_id=" + product_id + "]").attr('data-wish-added', '1');
                            $('.wishList-value').text(old_wish_value + 1);
                        }
                        else {
                            $(this_element).html('<i class="mdi mdi-heart-outline text-danger"></i>');
                            $('#grid1_' + product_id).attr('data-wish-added', '0');
                            $("button[data-product-id=" + product_id + "]").attr('data-wish-added', '0');
                            $("button[data-product_id=" + product_id + "]").attr('data-wish-added', '0');
                            $('.wishList-value').text(old_wish_value - 1);
                        }
                    })
        })
        $(document).on('click', '.changeproductQty', function () {
            var this_element = $(this);
            var product_id = this_element.attr('data-product-id');
            var price_id = this_element.attr('data-price-id');
            var old_quantity = parseInt($('.product_' + product_id).val());
            var operater = this_element.attr('data-product-operater');
            var quantity = operater == '+' ? (old_quantity) + 1 : (old_quantity) - 1;
            if (product_id && price_id) {
                if (quantity >= 0) {
                    cart_add_quantity(price_id, product_id, quantity)
                        .then(
                            (resolve) => {
                                if (resolve['status'] == 1) {
                                    let cart_value = resolve['cart_value'];
                                    $('.product_' + product_id).val(quantity);
                                    $('#cart_total_price_' + price_id).text(resolve['total_price'] || 0);
                                    $("#total_cart_price").text(cart_value.total_mrp_amount || 0);
                                    $("#total_cart_discount").text(cart_value.discount || 0);
                                    $("#total_cart_payble").text(cart_value.total_amount || 0);
                                    $("#total_payble_price").text(cart_value.total_amount || 0);
                                }
                                else if (resolve['status'] == 2) {
                                    var cart_id = 0;
                                    let cart_value = resolve['cart_value'];
                                    var content = '<button type="button" data-product_id="' + product_id + '" id="p1_' + product_id + '" class="addTocartBtn btn btn-sm float-right"> Add To Cart <i class="mdi mdi-plus"></i></button>';
                                    $('.product-grid-' + product_id).html(content);
                                    $("#checkout_" + product_id + "_" + cart_id).remove();
                                    $("#total_cart_price").text(cart_value.total_mrp_amount || 0);
                                    $("#total_cart_discount").text(cart_value.discount || 0);
                                    $("#total_cart_payble").text(cart_value.total_amount || 0);
                                    $("#total_payble_price").text(cart_value.total_amount || 0);
                                    var old_cart_value = parseInt($('.cart-value').text());
                                    $('.cart-value').text(old_cart_value - 1);
                                }
                            })
                }
            }
        });
        $(document).on('change', '.product_price_id', function () {
            var this_element = $(this);
            var product_price_id = this_element.val();
            var wish_added = this_element.attr('data-wish-added');
            const actual_area_id = localStorage.getItem('actual_area_id') ? localStorage.getItem('actual_area_id') : 0;
            check_cart_product(actual_area_id, product_price_id)
                .then(
                    (resolve) => {
                        if (resolve['status'] == 1) {
                            let priceData = resolve['price_data'];
                            $('.grid1_' + priceData.product_id).val(product_price_id);
                            if (resolve['cart_status'] == false) {
                                var content = '<button type="button" data-wish-added="' + wish_added + '" data-product_id="' + priceData.product_id + '" id="p1_' + priceData.product_id + '" class="addTocartBtn btn btn-sm float-left"> Add To Cart <i class="mdi mdi-plus"></i></button>';
                                /* content = content + '<a type="button" data-wish-product-id="' + priceData.product_id + '" class="wishlist-icon p_add_wishlist float-right" title="Add to Wishlist">';
                                if (wish_added == 0) {
                                    content = content + '<i class="mdi mdi-heart-outline text-danger"></i>';
                                }
                                else {
                                    content = content + '<i class="mdi mdi-heart text-danger"></i>';
                                }
                                content = content + '</a>'; */
                                $('.product-grid-' + priceData.product_id).html(content);
                            }
                            else {
                                let cartData = resolve['cart_data'];
                                var content = '<div class="table-cart-stepper float-center">'
                                    + '<div class="number-style text-center">'
                                    + '<span data-product-id="' + priceData.product_id + '" data-price-id="' + product_price_id + '" data-product-operater="-" class="number-minus changeproductQty"></span>'
                                    + '<input type="number" readOnly class="product_' + priceData.product_id + '" value="' + cartData.p_qty + '" />'
                                    + '<span data-product-id="' + priceData.product_id + '" data-price-id="' + product_price_id + '" data-product-operater="+" class="number-plus changeproductQty"></span>'
                                    + '</div>'
                                    + '</div>';
                                //+ '<button type="button" data-wish-added="' + wish_added + '" data-price-id="' + product_price_id + '" data-product-id="' + priceData.product_id + '" class="btn btn-secondary removeCartProduct btn-sm float-left"><i class="mdi mdi-close"></i></button>';
                                /* content = content + '<a type="button" data-wish-product-id="' + priceData.product_id + '" class="wishlist-icon p_add_wishlist float-right" title="Add to Wishlist">';
                                if (wish_added == 0) {
                                    content = content + '<i class="mdi mdi-heart-outline text-danger"></i>';
                                }
                                else {
                                    content = content + '<i class="mdi mdi-heart text-danger"></i>';
                                }
                                content = content + '</a>'; */
                                $('.product-grid-' + priceData.product_id).html(content);
                            }
                            var offer_section = priceData['unit_discount'] + (priceData.disc_type == 'flat' ? ' ₹ ' : ' % ') + ' OFF';
                            //$('.offerText_' + priceData.product_id).html(offer_section);
                            var perUnit = parseFloat(priceData.unit_price) / parseInt(priceData.unit_quantity);
                            $('.avl_' + priceData.product_id).html(priceData.unit_quantity + " " + priceData.unit + '  @' + perUnit + '/' + priceData.unit);
                            $('.sale_' + priceData.product_id).html(priceData.unit_price);
                            $('.mrp_' + priceData.product_id).html(priceData.unit_mrp);
                        }
                    })
        });
        $(document).on('click', '.removeCartProduct', function () {
            var this_element = $(this);
            var product_id = this_element.attr('data-product-id');
            var price_id = this_element.attr('data-price-id');
            var wish_added = this_element.attr('data-wish-added');
            var old_cart_value = parseInt($('.cart-value').text());
            if (product_id) {
                if (price_id) {
                    remove_cart_product2(product_id, price_id)
                        .then(
                            (resolve) => {
                                if (resolve['status'] == 1) {
                                    $('.cart-value').text(old_cart_value - 1);
                                    var content = '<button type="button" data-wish-added="' + wish_added + '" data-product_id="' + product_id + '" id="p1_' + product_id + '" class="addTocartBtn btn btn-sm float-left"> Add To Cart <i class="mdi mdi-plus"></i></button>';
                                    /* content = content + '<a type="button" data-wish-product-id="' + product_id + '" class="wishlist-icon p_add_wishlist float-right" title="Add to Wishlist">';
                                    if (wish_added == 0) {
                                        content = content + '<i class="mdi mdi-heart-outline text-danger"></i>';
                                    }
                                    else {
                                        content = content + '<i class="mdi mdi-heart text-danger"></i>';
                                    }
                                    content = content + '</a>'; */
                                    $('.product-grid-' + product_id).html(content);
                                }
                            })
                }
            }
        });
    })
}