import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import { fetch_cart_data, remove_cart_product, cart_add_quantity } from '../components/Cart_Action.js';
import { cart_plus_minus, update_cart_value_text } from '../assets/custom2.js';
class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cart_isLoaded: false,
            cart_product: []
        };
    }
    change_quantity = (cart, c_id, price_id, product_id, operater) => {
        var cart_quantity = document.getElementById("cart_quantity_" + c_id);
        const quantity = operater == '+' ? parseInt(cart_quantity.value) + 1 : parseInt(cart_quantity.value) - 1;
        if (quantity > 0) {
            cart_plus_minus(cart_add_quantity, price_id, c_id, product_id, quantity, cart);
        }
    }

    get_user_cart() {
        const actual_area_id = localStorage.getItem('actual_area_id') ? localStorage.getItem('actual_area_id') : null;
        fetch_cart_data(actual_area_id)
            .then(
                (resolve) => {
                    if (resolve['status'] == 1) {
                        this.setState({
                            cart_isLoaded: true,
                            cart_product: resolve['data']
                        });
                    }
                    else {
                        this.setState({
                            cart_isLoaded: false,
                            cart_product: []
                        });
                    }
                },
                (reject) => {
                    console.log(reject)
                }
            )
    }
    removecart = (price_id, c_id) => {
        remove_cart_product(price_id, c_id)
            .then(
                (resolve) => {
                    if (resolve['status'] == 1) {
                        update_cart_value_text();
                        const items = this.state.cart_product.filter(item => item.c_id !== c_id);
                        this.setState({
                            cart_isLoaded: true,
                            cart_product: items
                        });
                    }
                },
                (reject) => {
                    console.log(reject)
                }
            )
    }
    componentDidMount() {
        this.get_user_cart();
    }
    shouldComponentUpdate() {
        return true;
    }
    render() {
        const { cart_isLoaded, cart_product } = this.state;
        var payable_total = 0;
        var total_p_discount = 0;
        var total_actual_price = 0;
        return (
            <div>
                <Helmet>
                    <title>Checkout | Harpra</title>
                </Helmet>
                {/* <Header /> */}
                <div className="page-container">
                    <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <a href="/"><strong><span className="mdi mdi-home" /> Home</strong></a> <span className="mdi mdi-chevron-right" /> <a href="#">Checkout</a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="checkout-page section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <h5 className="card-header">My Cart <span className="text-secondary float-right">({cart_product.length} item)</span></h5>
                                        <div className="card-body pt-0 pr-0 pl-0 pb-0">
                                            <div className="row">
                                                <div className="col-lg-8 col-sm-12">
                                                    {cart_isLoaded == true ?
                                                        cart_product.map((cart, index) => {
                                                            payable_total = payable_total + parseFloat(cart.total_price);
                                                            total_p_discount = total_p_discount + parseFloat(cart.p_discount);
                                                            total_actual_price = total_actual_price + parseFloat(cart.actual_price * cart.p_qty);
                                                            return (
                                                                <div className="cart-list-product" id={"checkout_" + cart.product_id + "_" + cart.c_id} key={"cart_" + index}>
                                                                    <Link className="float-right remove-cart" onClick={() => this.removecart(cart.price_id, cart.c_id)} to="#"><i className="mdi mdi-delete"></i></Link>
                                                                    <Link to={"/product/" + ((cart.p_name).replace(/[^a-z0-9\s]/gi, '').replace(/[-\s]/g, '-')) + "/" + cart.product_id} target="_blank">
                                                                        <img className="img-fluid" src={cart.img_paths} />
                                                                    </Link>
                                                                    <span className="badge badge-success">{cart.p_discount}₹ OFF</span>
                                                                    <h5>{cart.p_name}</h5>
                                                                    <h6><span>{cart.p_unit_qty} {cart.p_unit}</span></h6>
                                                                    <p className="offer-price mb-0">
                                                                        <span className="offer-price" id={"product_price_" + (cart.c_id)}>₹{cart.sale_price}</span>
                                                                        <span className="regular-price" id={"product_mrp_price_" + (cart.c_id)}> ₹ {cart.actual_price}</span>
                                                                    </p>
                                                                    <div className="table-cart-stepper">
                                                                        <div className="number-style text-center">
                                                                            <span id={"minus_btn_" + (cart.c_id)} onClick={() => this.change_quantity(cart, cart.c_id, cart.price_id, cart.product_id, '-')} className="number-minus"></span>
                                                                            <input className={"product_" + cart.product_id} value={cart.p_qty} type="number" id={"cart_quantity_" + (cart.c_id)} readOnly />
                                                                            <span id={"plus_btn_" + (cart.c_id)} onClick={() => this.change_quantity(cart, cart.c_id, cart.price_id, cart.product_id, '+')} className="number-plus"></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        <div className="text-center mt-3">
                                                            <h6>Your Cart is Empty</h6>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="col-lg-4 col-sm-12">
                                                    <div className="cart-sidebar-footer">
                                                        <div className="cart-store-details">
                                                            <p>Cart Subtotal <strong className="float-right">₹<span id="total_cart_price">{total_actual_price}</span></strong></p>
                                                            <h6>Saving Amount <strong className="float-right text-danger">-₹<span id="total_cart_discount">{total_p_discount}</span> </strong></h6>
                                                            <p>Total Amount <strong className="float-right">₹<span id="total_payble_price">{payable_total}</span></strong></p>
                                                        </div>
                                                        <a href="/payment-continue"><button className="btn btn-secondary btn-lg btn-block text-left" target="_self"><span className="float-left"><i className="mdi mdi-cart-outline"></i> Proceed to Payment </span><span className="float-right"><strong>₹<span id="total_cart_payble">{payable_total}</span></strong> <span className="mdi mdi-chevron-right"></span></span></button></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
export default Checkout;
