import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import CouponMdl from './CouponMdl.js';
import { fetch_order_address, get_data_all } from '../components/Fetch_All_data.js';
class Payment_continue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address_isLoaded: false,
            save_order_btn: false,
            offer_status: false,
            deliveryAddressId: 0,
            free_shipping: false,
            payment_method: 0,
            freight_charge: 0,
            alert: null,
            payble_amount: 0,
            used_loyalty_point: 0,
            loyalty_point: 0,
            coupon_amount: 0,
            address_id: 0,
            total_mrp_amount: 0,
            shipping_charge: 0,
            discount_amount: 0,
            payble_amount_tax: 0,
            delivery_address: []
        };
    }

    componentDidMount() {
        var address_id = localStorage.getItem('address_id');
        if (address_id) {
            this.setState({ deliveryAddressId: address_id });
        }
        this.fetch_delivery_address();
        this.fetch_total_order(address_id);
        this.get_coupon_loyelty_amount();
    }
    add_new_address = () => {
        this.props.history.push("/myaddress");
    }
    set_delivery_Address = (address) => {
        localStorage.setItem('pincode', address.pinno);
        localStorage.setItem('address_id', address.id);
        this.setState({ deliveryAddressId: address.id });
        this.fetch_total_order(address.id);
    }
    get_coupon_loyelty_amount = () => {
        var app_token = localStorage.getItem('app_token');
        let options = { 'm_u_token': app_token };
        get_data_all('m/coupon-loyalty-amount', options)
            .then(
                (resolve) => {
                    if (resolve['status'] == 1) {
                        this.setState({ coupon_amount: resolve['coupon_amount'], loyalty_point: resolve['loyalty_point'] });
                    }
                    else {

                    }
                },
                (reject) => {
                    console.log(reject);
                }
            )
    }
    offer_handleCallback = (offer_status, reload_status) => {
        this.setState({ offer_status: offer_status });
        if (reload_status == true) {
            this.get_coupon_loyelty_amount();
        }
    }
    fetch_total_order = (address_id) => {
        let options = {
            'm_u_token': localStorage.getItem('app_token'),
            'area_id': localStorage.getItem('actual_area_id'),
            'pincode': localStorage.getItem('pincode'),
            'deliveryAddressId': address_id
        }
        get_data_all('m/sum_cart_product_amount', options)
            .then(
                (resolve) => {
                    this.setState({
                        payble_amount: resolve['total'],
                        payble_amount_tax: resolve['payble_amount_tax'],
                        total_mrp_amount: resolve['total_mrp_amount'],
                        discount_amount: resolve['discount']
                    });
                    if (resolve['delivery_detail']['status'] == 1) {
                        this.setState({ freight_charge: resolve['delivery_detail']['freight_charge'] })
                        if (resolve['delivery_detail']['free_shipping'] != true) {
                            this.setState({ shipping_charge: resolve['delivery_detail']['freight_charge'] })
                        }
                        this.setState({ free_shipping: resolve['delivery_detail']['free_shipping'] })
                    }
                },
                (reject) => {
                    console.log(reject);
                }
            )
    }
    fetch_delivery_address() {
        fetch_order_address()
            .then(
                (resolve) => {
                    if (resolve['status'] == 1) {
                        //let actual_area_id =localStorage.getItem('actual_area_id');
                        let items = resolve['data'];//.filter(item => item.area_id === actual_area_id);
                        this.setState({
                            delivery_address: items,
                            address_isLoaded: true
                        });
                    }
                    else {
                        this.setState({ error: resolve['message'] });
                    }
                },
                (reject) => {
                    console.log(reject);
                }
            )
    }
    save_new_order = () => {
        let address_id = this.state.deliveryAddressId;
        let payment_method = this.state.payment_method;
        var login_token = localStorage.getItem('login_token');
        var client_token = localStorage.getItem('client_contact');
        if (!client_token && !login_token) {
            let getAlert5 = () => (
                <SweetAlert warning title={"Please login then countinue"} onConfirm={() => {
                    this.setState({ alert: null });
                    this.props.history.push("/login");
                }} />
            );
            this.setState({ alert: getAlert5() });
        }
        else if (address_id == 0) {
            let getAlert1 = () => (
                <SweetAlert warning title={"Please Select Delivery Address"} onConfirm={() => { this.setState({ alert: null }); }} />
            );
            this.setState({ alert: getAlert1() });
        }
        else if (payment_method == 0) {
            let getAlert1 = () => (
                <SweetAlert warning title={"Please Select Payment Method"} onConfirm={() => { this.setState({ alert: null }); }} />
            );
            this.setState({ alert: getAlert1() });
        }
        else {
            this.setState({ save_order_btn: true });
            let options = {
                'm_u_token': localStorage.getItem('app_token'),
                'area_id': localStorage.getItem('actual_area_id'),
                'p_mode': payment_method,
                'addressId': address_id,
                'plateform': "WEB",
                'used_loyalty_point': this.state.used_loyalty_point,
                'coupon_amount': this.state.coupon_amount,
                'discount_amount': this.state.discount_amount,
                'muser_id': localStorage.getItem('client_id')
            }
            document.body.classList.add('loader-active');
            get_data_all('m/save_deli_order', options)
                .then(
                    (resolve) => {
                        document.body.classList.remove('loader-active');
                        if (resolve['status'] == 1) {
                            let invoiceNumber = resolve['invoiceNumber'];
                            let order_id = resolve['order_id'];
                            let getAlert = () => (
                                <SweetAlert success title={resolve['message']} onConfirm={() => { this.order_success(invoiceNumber, order_id) }} />
                            );
                            this.setState({ alert: getAlert() });
                        }
                        else if (resolve['status'] == 3) {
                            let payment = resolve['payment'];
                            let gateway = resolve['gateway'];
                            if (gateway == 'razorpay') {
                                this.make_payment(payment, resolve['invoiceNumber'], resolve['order_id'], resolve['user']);
                            }
                            else {
                                window.location.href = resolve['payment_url'];
                            }
                        }
                        else {
                            let getAlert1 = () => (
                                <SweetAlert warning title={resolve['message']} onConfirm={() => { this.setState({ alert: null }); }} />
                            );
                            this.setState({ save_order_btn: false, alert: getAlert1() });
                        }
                    },
                    (reject) => {
                        console.log(reject);
                        document.body.classList.remove('loader-active');
                    }
                )
        }
    }
    use_layalty_points = (status) => {
        if (status == true) {
            let total_amount = parseFloat(this.state.payble_amount) + parseFloat(this.state.payble_amount_tax) + parseFloat(this.state.shipping_charge) - parseFloat(this.state.coupon_amount);
            if (total_amount > parseFloat(this.state.loyalty_point)) {
                this.setState({ used_loyalty_point: this.state.loyalty_point });
            }
            else {
                this.setState({ used_loyalty_point: total_amount });
            }
        }
        else {
            this.setState({ used_loyalty_point: 0 });
        }
    }
    order_success(invoiceNumber, order_id) {
        this.setState({ alert: null });
        this.props.history.push("/order-success/" + invoiceNumber + "/" + order_id);
    }
    make_payment = (payment, invoice_number, order_id, user) => {
        var options = {
            "key": payment.key_id, // Enter the Key ID generated from the Dashboard
            "amount": payment.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": user.muser_name,
            "description": "Product order",
            "image": payment.image,
            "order_id": payment.OrderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": (response) => {
                response['muser_id'] = localStorage.getItem('client_id');
                response['invoice_number'] = invoice_number;
                get_data_all('m/razorpay_order_success', response)
                    .then(
                        (resolve) => {
                            if (resolve['status'] == '1') {
                                let getAlert = () => (
                                    <SweetAlert success title={resolve['message']} onConfirm={() => { this.order_success(invoice_number, order_id) }} />
                                );
                                this.setState({ alert: getAlert() });
                            }
                            else {
                                let getAlert1 = () => (
                                    <SweetAlert warning title={resolve['message']} onConfirm={() => { this.setState({ alert: null }); }} />
                                );
                                this.setState({ save_order_btn: false, alert: getAlert1() });
                            }
                        },
                        (reject) => {
                            console.log(reject);
                            this.setState({ save_order_btn: false });
                        }
                    )
            },
            "prefill": {
                "name": user.muser_name,
                "email": user.muser_email,
                "contact": user.mobilenumber
            },
            "notes": {
                "address": user.muser_address
            },
            "theme": {
                "color": "#3399cc"
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            console.log("payment.failed", response);
        });
        rzp1.open();
    }
    render() {
        const { address_isLoaded, delivery_address } = this.state;
        return (
            <div>
                {/* <Header /> */}
                <div className="page-container">
                    {this.state.alert}
                    <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <a href="/"><strong><span className="mdi mdi-home" /> Home</strong></a> <span className="mdi mdi-chevron-right" /> <a>Continue Payment</a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <CouponMdl offer_status={this.state.offer_status} price_id={0} parentCallback={this.offer_handleCallback} />
                    {/* <Loader type="Bars" visible={true} color="#00BFFF" height={100} width={100} /> */}
                    <section className="checkout-page section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <h5 className="card-header">Continue Payment</h5>
                                        <div className="card-body pt-0 pr-0 pl-0 pb-0">
                                            <div className="container">
                                                <div className="row ">
                                                    <div className="col-sm-1">
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                                        <div className="bx-wrapper mt-3">
                                                            <div className="panel-heading">
                                                                <h4 className="addressTag"><i className="fa fa-credit-card"></i> Select Delivery Address</h4>
                                                            </div>
                                                            {address_isLoaded == true ?
                                                                delivery_address.length > 0 ?
                                                                    <>
                                                                        {delivery_address.map((address, index) => {
                                                                            return (
                                                                                <div key={"add_" + address.id}>
                                                                                    <div /*style={address.a_status == 1 ? {} : { color: '#bbc0c5' }}*/>
                                                                                        {/* <span data-name="addressTag">{address.addr_type}</span> */}
                                                                                        <div className="custom-control custom-radio">
                                                                                            <input checked={(address.id == this.state.deliveryAddressId) ? true : false} type="radio" value={address.id} onChange={(e) => this.set_delivery_Address(address)} id={"deliveryAddressId" + address.id} name="deliveryAddressId" className="custom-control-input" />
                                                                                            <label className="custom-control-label" htmlFor={"deliveryAddressId" + address.id}><h6 data-name="name" className="truncated">{address.fir_name + " " + address.lastname}  <span className="addressType">{(address.addr_type == '1') ? 'Home' : (address.addr_type == '2' ? 'Office' : 'Other')}</span></h6></label>
                                                                                        </div>
                                                                                        <div className="address">
                                                                                            <span data-name="address1" className="truncated"><strong>Address :</strong> {address.address_line1} {address.userarea}  {address.city_name}, {address.state_name} - {address.pinno}</span>
                                                                                        </div>
                                                                                        <div className="phone">
                                                                                            <strong>Contact No. :</strong> <span data-name="mobile">{address.mobilenumber}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    {address.a_status != 1 ?
                                                                                        <>
                                                                                            {/* <h5 style={{ color: 'red', fontSize: 'inherit' }}>Service not available in this Address</h5> */}
                                                                                        </>
                                                                                        :
                                                                                        ''
                                                                                    }
                                                                                    <hr />
                                                                                </div>
                                                                            )
                                                                        })
                                                                        }
                                                                        <div className="pb-3">
                                                                            <button className="btn btn-danger btn-sm" onClick={() => this.add_new_address()}>Add Delivery Address</button>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <p>You don't add any delivery address, please add delivery address</p>
                                                                        <button className="btn btn-danger btn-sm" onClick={() => this.add_new_address()}>Add Delivery Address</button>
                                                                    </>

                                                                :
                                                                <>
                                                                    <p>You don't add any delivery address, please add delivery address</p>
                                                                    <button className="btn btn-danger btn-sm" onClick={() => this.add_new_address()}>Add Delivery Address</button>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-12">
                                                        <div className="checkout-box mt-3 mb-3">
                                                            <div className="checkout-payment-methods">
                                                                <div className="panel-body">
                                                                    <div>
                                                                        <table className="table">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Cart Subtotal</td>
                                                                                    <td>₹{this.state.total_mrp_amount}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Saving Amount</td>
                                                                                    <td><span className="text-danger">-₹{this.state.discount_amount}</span></td>
                                                                                </tr>
                                                                                {this.state.coupon_amount > 0 ?
                                                                                    <tr>
                                                                                        <td>Coupon Applied</td>
                                                                                        <td><span className="text-success">-₹{this.state.coupon_amount}</span></td>
                                                                                    </tr>
                                                                                    : <></>}
                                                                                <tr>
                                                                                    <td>Loyalty Point</td>
                                                                                    <td>
                                                                                        <span className="text-success">₹{this.state.loyalty_point}</span>
                                                                                        {this.state.loyalty_point > 0 ?
                                                                                            this.state.used_loyalty_point == 0 ?
                                                                                                <span className="float-right mr-10 cursor text-success" onClick={() => this.use_layalty_points(true)}>Use</span>
                                                                                                :
                                                                                                <>
                                                                                                    <span className="float-right mr-10">
                                                                                                        <span className="text-success"><strong>₹{this.state.used_loyalty_point} Used</strong></span>
                                                                                                        <span className="text-danger ml-2 cursor" onClick={() => this.use_layalty_points(false)}>Remove</span>
                                                                                                    </span>
                                                                                                </>
                                                                                            : <></>}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Shipping Charge</td>
                                                                                    <td>
                                                                                        {(this.state.free_shipping == false) ?
                                                                                            "₹" + this.state.freight_charge : <>
                                                                                                <span className='mr-2 text-danger' style={{ textDecoration: 'line-through' }}>₹{this.state.freight_charge}</span>
                                                                                                <span className='text-success'>Free Shipping</span>
                                                                                            </>}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Payble Amount</td>
                                                                                    <td> ₹{(parseFloat(this.state.payble_amount) + parseFloat(this.state.payble_amount_tax) + parseFloat(this.state.shipping_charge) - parseFloat(this.state.coupon_amount) - parseFloat(this.state.used_loyalty_point))}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="text-right">
                                                                        <p className="text-primary" onClick={() => this.offer_handleCallback(true)} style={{ cursor: 'pointer' }}>Apply Coupon Code</p>
                                                                    </div>
                                                                    <div className="panel-heading">
                                                                        <h4 className="panel-title"><i className="fa fa-credit-card"></i> Payment Method</h4>
                                                                    </div>
                                                                    {/* <p>Please select the preferred payment method to use on this order.</p> */}
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input type="radio" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value="1" name="payment_method" /><span className="ml-20">Cash On Delivery</span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input type="radio" disabled={false} value="2" name="payment_method" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} /><span className="ml-20">By Online</span>
                                                                        </label>
                                                                    </div>
                                                                    {(this.state.payment_method && this.state.payble_amount) ?
                                                                        <div className="buttons mt-2 clearfix">
                                                                            <div className="pull-right">
                                                                                <button type="button" disabled={this.state.save_order_btn} onClick={() => this.save_new_order()} className="btn btn-primary btn-lg">{this.state.save_order_btn && <i className="mdi mdi-refresh mdi-spin" aria-hidden="true"></i>} Place Order</button>
                                                                            </div>
                                                                        </div>
                                                                        : <></>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
export default withRouter(Payment_continue);
