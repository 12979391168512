import { apiUrl } from '../Base_page.js';
export { fetch_order_list,get_order_details,cancel_order_action }
function fetch_order_list() {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/product_order_list', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'muser_id': localStorage.getItem('client_id')})
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function cancel_order_action(order_id,order_remark) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/order_cancel', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'muser_id': localStorage.getItem('client_id'),'order_id':order_id,'reason':order_remark})
    })
    .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
function get_order_details(order_id,inoice_number) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + 'm/product_order_list_detail', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
      body: JSON.stringify({ 'muser_id': localStorage.getItem('client_id'),'order_id':order_id,'inoice_number':inoice_number})
    })
      .then(result => {
        result.json().then((res) => {
          resolve(res);
        })
          .catch((error) => {
            reject(error);
          })
      });
  });
}
