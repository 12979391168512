import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import store from './redux/store'
import 'react-notifications/lib/notifications.css';
ReactDOM.render(
  <Provider store={store}> <App /></Provider>, document.getElementById('root')
);
reportWebVitals();
