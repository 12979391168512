import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { cart_service } from '../_services/cart.service.js';
import { Oval } from 'react-loader-spinner';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import {
   Dropdown,
   DropdownToggle,
   DropdownMenu,
   DropdownItem,
} from 'reactstrap';
import { rating_system, update_rating_system } from '../assets/custom.js';
import { extract_price_detail, get_product_details, fetch_sub_group_product, fetch_topsaver_product, get_data_all, sort_price_list, fetch_order_address } from '../components/Fetch_All_data.js';
import OwlCarousel from 'react-owl-carousel';
import FadeIn from "react-lazyload-fadein";
import { only_number } from "../components/General_data";
import { ProductContaner } from '../components/ProductDetail_Comp.jsx';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'owl.carousel/dist/assets/owl.carousel.css';
import location from '../assets/img/location.png';
import scale from '../assets/img/scale.svg';
import parse from 'html-react-parser';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { cart_add_quantity } from '../components/Cart_Action.js';
import SizeChartMdl from '../components/SizeChartMdl.js'
import ImagePopUpMdl from '../components/ImagePopUpMdl.js'
import ProductDescriptionMdl from '../components/ProductDescriptionMdl.js'
class Product_details extends Component {
   constructor(props) {
      super(props);
      this.state = {
         products_details: [],
         products: [],
         price: [],
         group: [],
         rating_list: [],
         helpfullImages: [],
         ratingform_status: false,
         show_description: false,
         rating_status: false,
         p_price_list: [],
         loginMdl_status: false,
         average: 0,
         myrating_count: 0,
         comments: '',
         delivery_address_list: [],
         delivery_address_selected: [],
         images: [],
         p_d_isLoaded: false,
         pincodeIsValid: false,
         free_shipping: false,
         dropdownOpen: false,
         wish_added: 0,
         address_id: 0,
         img_url: '',
         alert: null,
         freight_charge: '',
         etd_delivery: '',
         Invalide_pincode_message: '',
         delivery_pincode: '',
         pincode: '',
         pro_isLoaded: false
      };
      window.scroll({ top: 0, behavior: 'smooth' });
      if (props.match.isExact == true) {
         const product_id = props.match.params.id;
         const params = new URLSearchParams(props.location.search);
         const variant = params.get('variant');
         if (product_id) {
            this.fetch_product_detial(product_id, variant);
         }
      }
   }
   fetch_product_detial = (product_id, variant) => {
      var delivery_pincode = localStorage.getItem('pincode');
      var address_id = localStorage.getItem('address_id');
      get_product_details(product_id, variant, delivery_pincode, address_id)
         .then(
            async (resolve) => {
               if (resolve['status'] == 1) {
                  let price = await resolve['price'] || resolve['prices'][0] || [];
                  this.setState({
                     products_details: resolve['data'],
                     price: price,
                     wish_added: resolve['wish_added'],
                     images: JSON.parse(resolve['data'].product_images || []),
                     img_url: resolve['img_url'],
                     group: resolve['group'],
                     p_price_list: resolve['prices'] || [],
                     p_d_isLoaded: true
                  });
                  if (delivery_pincode || address_id)
                     this.check_delivery_pincode(delivery_pincode, address_id);

                  this.call_sub_group_product(resolve['data']['parent_id']);
                  this.itemRating(product_id);
               }
            },
            (reject) => {
               console.log(reject)
            }
         )
   }
   display_size_chart = () => {
      if (this.state.products_details.size_chart)
         this.setState({ alert: <SizeChartMdl size_chart={this.state.products_details.size_chart} model_handler={this.close_modal} /> })
   }
   view_product_description = () => {
      this.setState({ alert: <ProductDescriptionMdl products_details={this.state.products_details} model_handler={this.close_modal} /> })
   }
   show_image_popup = (index) => {
      this.setState({ alert: <ImagePopUpMdl img_url={this.state.img_url} images={this.state.images} index={index} title={this.state.products_details.p_name} model_handler={this.close_modal} /> })
   }
   close_modal = () => {
      this.setState({ alert: null });
   }
   itemRating(product_id) {
      get_data_all('m/user-rating-list', { 'product_id': product_id, 'product_price_id': this.state.price.product_price_id || 0, 'm_u_token': localStorage.getItem('login_token') })
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  this.setState({
                     rating_list: resolve['rating_list'] || [],
                     helpfullImages: resolve['helpfullImages'] || [],
                     rating_status: true,
                     oneStarRatingPercent: resolve['oneStarRatingPercent'], oneStarRating: resolve['oneStarRating'],
                     twoStarRatingPercent: resolve['twoStarRatingPercent'], twoStarRating: resolve['twoStarRating'],
                     threeStarRatingPercent: resolve['threeStarRatingPercent'], threeStarRating: resolve['threeStarRating'],
                     fourStarRatingPercent: resolve['fourStarRatingPercent'], fourStarRating: resolve['fourStarRating'],
                     fiveStarRatingPercent: resolve['fiveStarRatingPercent'], fiveStarRating: resolve['fiveStarRating'],
                     average: resolve['avarage_rating'], myrating_count: resolve['myrating_count'] || 0, comments: resolve['comments'] || ''
                  });
                  update_rating_system(resolve['myrating_count'] || 0);
               }
            },
            (reject) => {
               console.log(reject)
            }
         )
   }
   get_deliver_address = () => {
      fetch_order_address()
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  this.setState({ delivery_address_list: resolve['data'] });
               }
            },
            (reject) => {
               console.log(reject);
            }
         )
   }
   call_sub_group_product(parent_id) {
      fetch_sub_group_product(parent_id, 0, 0)
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  this.setState({
                     products: resolve['data'],
                     pro_isLoaded: true
                  });
               }
            },
            (reject) => {
               console.log(reject)
            }
         )
   }
   set_delivery_pincode = () => {
      let pincode = this.state.pincode;
      let address_id = '';
      if (pincode.length == 6) {
         this.check_delivery_pincode(pincode, address_id);
      }
      else {
         this.setState({ pincodeIsValid: true, Invalide_pincode_message: 'Enter valid pincode' });
      }
   }
   check_delivery_pincode = (pincode, address_id) => {
      let price_id = this.state.price['product_price_id'];
      let options = { 'pincode': pincode, 'item_id': this.state.products_details.id, 'price_id': price_id, 'address_id': address_id };
      get_data_all('m/check-delivery-pincode', options)
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  this.setState({ etd_delivery: resolve['etd_delivery'], shipping_charge: resolve['shipping_charge'], free_shipping: resolve['free_shipping'], freight_charge: resolve['freight_charge'], address_id: address_id, pincode: '', delivery_pincode: pincode, delivery_city: resolve['delivery_city'], pincodeIsValid: false, Invalide_pincode_message: '' });
                  localStorage.setItem('pincode', pincode);
                  localStorage.setItem('address_id', address_id);
                  localStorage.setItem('delivery_city', resolve['delivery_city']);
                  if (this.state.delivery_address_list.length > 0) {
                     const items = this.state.delivery_address_list.filter(item => item.id == address_id);
                     this.setState({ delivery_address_selected: items });
                  }
                  else {
                     this.setState({ delivery_city: resolve['delivery_city'], delivery_pincode: pincode });
                  }
               }
               else {
                  this.setState({ pincodeIsValid: true, Invalide_pincode_message: resolve['message'] });
               }
            },
            (reject) => {
               console.log(reject);
            }
         )
   }
   load_section2_product() {
      fetch_topsaver_product()
         .then(
            (resolve) => {
               if (resolve['status'] == 1) {
                  this.setState({
                     products: resolve['data'],
                     pro_isLoaded: true
                  });
               }
            },
            (reject) => {
               console.log(reject)
            }
         )
   }
   addTocart = (e) => {
      e.preventDefault()
      if (this.state.price.unit_stock > 0) {
         cart_add_quantity(this.state.price.product_price_id, this.state.products_details.id, 1)
            .then(
               (resolve) => {
                  if (resolve['status'] == 1) {
                     NotificationManager.success('Success', resolve.message);
                     cart_service.sendData(true);
                  }
                  else {
                     NotificationManager.warning('Warning', resolve.message);
                  }
               },
               (reject) => {
                  console.log(reject)
               }
            )
      }
      else {
         NotificationManager.warning('Warning', "Stock not available");
      }
   }
   componentDidUpdate(prevProps) {
      const next_params = new URLSearchParams(this.props.location.search);
      const prev_params = new URLSearchParams(prevProps.location.search);
      const next_variant = next_params.get('variant');
      const prev_variant = prev_params.get('variant');
      if (this.props.match.params.id == prevProps.match.params.id && next_variant != prev_variant) {
         window.scroll({ top: 0, behavior: 'smooth' });
         this.setState({ p_d_isLoaded: false });
         this.fetch_product_detial(this.props.match.params.id, next_variant);
      }
      else if (this.props.match.params.id != prevProps.match.params.id && next_variant != prev_variant) {
         window.scroll({ top: 0, behavior: 'smooth' });
         this.setState({ p_d_isLoaded: false });
         this.fetch_product_detial(this.props.match.params.id, next_variant);
      }
   }
   componentDidMount() {
      rating_system();
      this.get_deliver_address();
   }
   rate_this_product = (status) => {
      var login_token = localStorage.getItem('login_token');
      var client_token = localStorage.getItem('client_contact');
      if (!client_token && !login_token) {
         this.setState({ loginMdl_status: true, loading: false })
      }
      else {
         this.setState({ ratingform_status: status })
      }
   }
   save_new_rating = () => {
      var rateButton = document.getElementsByClassName("star-selected");
      let comments = this.state.comments || '';
      let product_id = this.state.products_details.id;
      let rating_count = rateButton.length;
      const helpfullImages = this.state.helpfullImages;
      if (comments != '') {
         get_data_all('m/save-new-rating', {
            'rating_count': rating_count, 'product_id': product_id, 'helpfullImages': helpfullImages,
            'product_price_id': this.state.price.product_price_id || 0,
            'comments': comments, 'm_u_token': localStorage.getItem('login_token')
         })
            .then(
               (resolve) => {
                  if (resolve['status'] == 1) {
                     const getAlert1 = () => (
                        <SweetAlert success title={resolve.message} onConfirm={() => { this.setState({ alert: null }); }} />
                     );
                     this.setState({ alert: getAlert1(), ratingform_status: false });
                     this.itemRating(product_id);
                  }
                  else {
                     const getAlert1 = () => (
                        <SweetAlert warning title={resolve.message} onConfirm={() => { this.setState({ alert: null }); }} />
                     );
                     this.setState({ alert: getAlert1() });
                  }
               },
               (reject) => {
                  console.log(reject)
               }
            )
      }
      else {
         NotificationManager.warning('Warning', "Enter Your Review");
      }
   }
   rating_login = () => {
      let user_mobile = this.state.user_mobile || '';
      let user_pass = this.state.user_pass || '';
      console.log(user_mobile)
      if (user_mobile == '') {
         NotificationManager.warning('Warning', "Enter Mobile No./Email");
      }
      else if (user_pass.length < 6) {
         NotificationManager.warning('Warning', "Enter valid password");
      }
      else {
         this.setState({ loading: true })
         let options = {
            'user_mobile': user_mobile, 'device_token': '',
            'user_pass': user_pass, 'm_u_token': localStorage.getItem('login_token')
         }
         get_data_all('m/check_user_login', options)
            .then(
               (resolve) => {
                  if (resolve['status'] == '1') {
                     localStorage.setItem('app_token', (resolve.data['m_u_token']));
                     localStorage.setItem('login_token', (resolve.data['m_u_token']));
                     localStorage.setItem('client_id', (resolve.data['muser_id']));
                     localStorage.setItem('client_contact', (resolve.data['muser_mobno']));
                     localStorage.setItem('client_name', (resolve.data['muser_name']));
                     localStorage.setItem('profileImg', (localStorage.getItem('base_url') + resolve.data['profile_img']));
                     this.setState({ ratingform_status: true, loginMdl_status: false, loading: false })
                     //window.reload();
                  }
                  else {
                     const getAlert1 = () => (
                        <SweetAlert warning title={resolve.message} onConfirm={() => { this.setState({ alert: null }); }} />
                     );
                     this.setState({ alert: getAlert1(), loading: false });
                  }
               },
               (reject) => {
                  console.log(reject);
               }
            )
      }
   }
   async changePriveImage(target) {
      var new_image_list = [];
      if (target.files.length <= 4) {
         await Promise.all([...target.files].map((image) => {
            if (image != null) {
               if (image.type == "image/png" || image.type == "image/jpg" || image.type == "image/jpeg" || image.type == "image/webp") {
                  var reader = new FileReader();
                  reader.readAsDataURL(image);
                  reader.onloadend = () => {
                     new_image_list.push({ image: reader.result, formate: 'base64', newImage: true });
                     this.setState({ helpfullImages: new_image_list });
                  };
               }
            }
         })
         )
      }
      else {
         NotificationManager.warning('Warning', "Maximum 4 images can be uploaded");
      }
   }
   remove_image(e, index) {
      e.preventDefault();
      const helpfullImages = this.state.helpfullImages.filter((item, i) => { return index !== i });
      this.setState({ helpfullImages: helpfullImages });
   }
   setDropdownOpen = () => this.setState({ dropdownOpen: this.state.dropdownOpen ? false : true });
   render() {
      const { alert, price, products_details, p_price_list, images, pro_isLoaded, products, p_d_isLoaded, dropdownOpen, rating_list, average, helpfullImages } = this.state;
      var TITLE = products_details.p_name || 'Product';
      var group_name = this.state.group['group_name'] || '';
      var group_slug = this.state.group['group_slug'] || '';
      var parent_id = products_details.parent_id || 0;
      return (
         <>
            <Helmet>
               <title>{TITLE} | Harpra</title>
            </Helmet>
            {/* <Header /> */}
            <div className="page-container">
               <NotificationContainer />
               {alert}
               <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white">
                  <div className="container">
                     <div className="row">
                        <div className="col-md-12">
                           <Link to="/"><strong><span className="mdi mdi-home" /> Home</strong></Link>
                           <span className="mdi mdi-chevron-right" />
                           <Link to={"/category/main/" + group_slug + "/" + parent_id}>{group_name}</Link><span className="mdi mdi-chevron-right" /> {TITLE}
                        </div>
                     </div>
                  </div>
               </section>
               {p_d_isLoaded == true ?
                  <>
                     <section className="shop-single section-padding pt-3">
                        <div className="container">
                           <div className="row">
                              <div className="col-md-5">
                                 <div className="shop-detail-left">
                                    <div className="shop-detail-slider product-items-slider">
                                       {/*  <div className="favourite-icon">
                                    <a className="fav-btn" data-placement="bottom" data-toggle="tooltip" href="#" data-original-title="59% OFF"><i className="mdi mdi-tag-outline" /></a>
                                 </div> */}
                                       <OwlCarousel id="sync1" style={{ opacity: 1, display: 'block' }} className="owl-theme owl-carousel owl-carousel-featured" items="1" responsiveClass={true} navElement="div" navContainerClass="owl-controls clickable" nav={true} rewind={true} autoplay={true} loop>
                                          {images.map((image, index) => {
                                             return (
                                                (image && image['images'] && image['status'] == '1') ?
                                                   <div className="item text-center" key={"img1_" + index}>
                                                      <FadeIn easing={'ease-out'}>
                                                         {onload => (
                                                            <img src={this.state.img_url + image['images']} onClick={() => this.show_image_popup(index)} onLoad={onload} className="img-fluid text-center product-detail-image" style={{ padding: '50px 60px 0px 50px' }} />
                                                         )}
                                                      </FadeIn>
                                                   </div>
                                                   : ''
                                             )
                                          })}
                                       </OwlCarousel>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-7">
                                 <div className="shop-detail-right">
                                    {price.unit_mrp != price.unit_price ?
                                       <span className={"badge badge-success offerText_" + products_details.id}> Upto
                                          {price.disc_type == 'flat' ? ' ₹' + price.unit_discount
                                             : " " + price.discount_percent + '% '}
                                          OFF</span>
                                       : <></>}
                                    <h2>{products_details.p_name}</h2>
                                    <h6>{/* <strong><span className="mdi mdi-approval" /> Available in</strong> -  */}
                                       {/* <span className={"avl_" + products_details.id}>{p_price_list[0].unit_quantity} {p_price_list[0].unit}  @{parseFloat(p_price_list[0].unit_price) / parseInt(p_price_list[0].unit_quantity)}/{p_price_list[0].unit}</span> */}
                                    </h6>
                                    <p>
                                       <span className="offer-price mr-2"><span className="text-success">₹<span className={"mrp_" + products_details.id}>{price.unit_price}</span></span></span>
                                       {price.unit_mrp != price.unit_price ?
                                          <span className="regular-price">₹<span className={"sale_" + products_details.id}>{price.unit_mrp}</span></span>
                                          : <></>}
                                    </p>
                                    {/* <p className="offer-price mb-0">Selling Price : </p> */}
                                    {/* <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                       <select id={"grid1_" + products_details.id} data-wish-added={this.state.wish_added} className={"form-control mb-2 mt-2 product_price_id grid1_" + products_details.id}>
                                          {p_price_list.map(price => (
                                             <option key={"price" + price.product_price_id} value={price.product_price_id}>₹{price.unit_price} ({(price.disc_type == 'flat' ? ' ₹' + price.unit_discount
                                                : price.discount_percent + '% ')}OFF)</option>
                                          ))}
                                       </select>
                                    </div>
                                 </div> */}
                                    <div className="row align-items-center">
                                       <div className="col-sm-8 col-lg-8">
                                          <div className="pd-10">
                                             <label><span>Size:</span><span className="ml-5">{price.product_size}</span></label>
                                             <div className="d-flex flex-wrap">
                                                {p_price_list.map(prices => (
                                                   <Link key={"price" + prices.product_price_id} to={"/product/" + products_details.product_slug + "/" + products_details.id + "?variant=" + prices.product_price_id} disabled={prices.unit_stock > 0 ? false : true} className={"product-variant-text d-flex flex-center border cursor" + ((price.product_price_id == prices.product_price_id) ? ' active' : '') + ((parseInt(prices.unit_stock) <= 0) ? ' price-not-available' : '')}>{prices.product_size}</Link>
                                                ))}
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-sm-4 col-lg-4 mt-2 text-center">
                                          {products_details.size_chart &&
                                             <Link to={'/'} onClick={(e) => { e.preventDefault(); this.display_size_chart() }} className="text-link cursor text-dark font-weight-bold">Size Chart <img className='ml-2' src={scale} /></Link>}
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-sm-12 col-lg-12 col-xs-12">
                                          <a href="#" onClick={(e) => this.addTocart(e)} className="btn btn-sm addTocartBtn" disabled={price.unit_stock > 0 ? false : true}> <i className="mdi mdi-cart-outline"></i> Add To Cart </a>
                                          <a href={"/checkout/" + products_details.product_slug + "/" + products_details.id + "?variant=" + price.product_price_id} className="btn btn-sm buyBtn"> <i className="mdi mdi-flash-outline"></i> Buy Now </a>
                                       </div>
                                    </div>
                                    <hr />
                                    <div className="row my-2">
                                       <div className="col-sm-8 col-lg-8 col-xs-12">
                                          <img src={location} style={{ height: "22px" }} />   Deliver To
                                          {(this.state.delivery_pincode == '' || this.state.delivery_city == '') ?
                                             <>
                                                <div className="form-group">
                                                   <input className="form-control border-form-control" value={this.state.pincode} onInput={(e) => only_number(e)} name="pincode" placeholder="Enter Pincode" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} type="text" />
                                                   <button className="btn btn-outline-dark mt-2 btn-sm mr-3" onClick={() => this.set_delivery_pincode()}>Check</button>
                                                   {this.state.pincodeIsValid == true ? <span style={{ color: 'red' }}>{this.state.Invalide_pincode_message}</span> : ''}
                                                </div>
                                             </>
                                             :
                                             (this.state.delivery_pincode && this.state.delivery_city) ?
                                                <>
                                                   <Dropdown isOpen={dropdownOpen} toggle={this.setDropdownOpen} direction={'down'}>
                                                      <DropdownToggle caret>
                                                         {(this.state.delivery_address_selected.length > 0) ?
                                                            this.state.delivery_address_selected.map((address, index) => {
                                                               return (
                                                                  <div key={"adds_" + index}>
                                                                     <p style={{ color: 'rgb(182 182 182)' }} className="ml-0">
                                                                        <span style={{ color: '#fff' }}>{address.fir_name + " " + address.lastname},</span>
                                                                        <span>{(address.address_line1 + ' ' + address.userarea + ' ' + address.city_name).slice(0, 25)}... </span>
                                                                        <span data-name="city">{address.pinno}</span>
                                                                        <span className="addressType ml-2 text-white">{(address.addr_type == '1') ? 'Home' : (address.addr_type == '2' ? 'Office' : 'Other')}</span>
                                                                     </p>
                                                                  </div>
                                                               )
                                                            })
                                                            :
                                                            <>
                                                               {this.state.delivery_city} - {this.state.delivery_pincode}
                                                            </>
                                                         }
                                                      </DropdownToggle>
                                                      <DropdownMenu>
                                                         {
                                                            this.state.delivery_address_list.map((address, index) => {
                                                               return (
                                                                  <div key={"add_" + index}>
                                                                     <DropdownItem style={{ backgroundColor: (this.state.address_id == address.id ? 'rgb(240 240 240)' : '') }} onClick={(e) => { this.state.address_id !== address.id ? this.check_delivery_pincode(address.pinno, address.id) : this.setState({ delivery_pincode: address.pinno }) }}>
                                                                        {/*  <input type={'radio'} name="address_id" value={address.id} id={"address" + address.id} onChange={this.onChange} style={{ fontSize: '16px' }} /> */}
                                                                        <label htmlFor={"address" + address.id} className="ml-0 mb-0">{address.fir_name + " " + address.lastname} <span className="addressType">{(address.addr_type == '1') ? 'Home' : (address.addr_type == '2' ? 'Office' : 'Other')}</span></label>
                                                                        <p style={{ color: '#909092' }} className="ml-0">
                                                                           <span>{address.address_line1} {address.userarea} {address.city_name} </span>
                                                                           <span data-name="city">{address.state_name} </span>  - <span>{address.pinno}</span>
                                                                        </p>
                                                                     </DropdownItem>
                                                                     <DropdownItem divider />
                                                                  </div>
                                                               )
                                                            })
                                                         }
                                                         <DropdownItem onClick={() => this.setState({ delivery_city: '', delivery_pincode: '' })}>
                                                            Change Delivery Pincode
                                                         </DropdownItem>
                                                      </DropdownMenu>
                                                   </Dropdown>
                                                   <h6 className="mt-2">
                                                      Delivery by {this.state.etd_delivery}  |
                                                      {(this.state.free_shipping == false) ? (this.state.freight_charge ? '₹' + this.state.freight_charge : '') :
                                                         (
                                                            (this.state.shipping_charge == 0)
                                                               ?
                                                               <>
                                                                  <span className='ml-2 mr-2 text-danger' style={{ textDecoration: 'line-through' }}>₹{this.state.freight_charge}</span>
                                                                  <span className='text-success'>Free Shipping</span>
                                                               </>
                                                               : <span className='ml-2'>₹ {this.state.shipping_charge}</span>
                                                         )
                                                      }
                                                   </h6>
                                                </> : <></>
                                          }
                                       </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                       <div className="col-sm-12 col-lg-6">
                                          {(p_price_list[0].CartQty == 0 || p_price_list[0].CartQty == null) ?
                                             <div className={"text-center product-grid-" + products_details.id}>
                                                {/*  <a type="button" data-wish-product-id={products_details.id} className="wishlist-icon p_add_wishlist float-right" title="Add to Wishlist">
                                             {this.state.wish_added == 0 ?
                                                <i className="mdi mdi-heart-outline text-danger"></i>
                                                :
                                                <i className="mdi mdi-heart text-danger"></i>
                                             }
                                          </a> */}
                                             </div>
                                             :
                                             <div className={"text-center product-grid-" + products_details.id}>
                                                {/* <div className="table-cart-stepper float-center mt-3">
                                                <div className="number-style text-center">
                                                   <span data-product-id={(products_details.id)} data-product-operater="-" data-price-id={p_price_list[0]['product_price_id']} className="number-minus changeproductQty"></span>
                                                   <input type="number" readOnly className={"product_" + products_details.id} value={p_price_list[0].CartQty} />
                                                   <span data-product-id={(products_details.id)} data-product-operater="+" data-price-id={p_price_list[0]['product_price_id']} className="number-plus changeproductQty"></span>
                                                </div>
                                             </div> */}
                                                {/* <button type="button" data-wish-added={this.state.wish_added} data-price-id={p_price_list[0]['product_price_id']} data-product-id={products_details.id} className="btn btn-secondary removeCartProduct btn-sm float-left"><i className="mdi mdi-close"></i></button> */}
                                                {/* <a type="button" data-wish-product-id={products_details.id} className="wishlist-icon p_add_wishlist float-right" title="Add to Wishlist">
                                             {this.state.wish_added == 0 ?
                                                <i className="mdi mdi-heart-outline text-danger"></i>
                                                :
                                                <i className="mdi mdi-heart text-danger"></i>
                                             }
                                          </a> */}
                                             </div>
                                          }
                                       </div>
                                       {/* <div className="col-sm-12 col-lg-6">
                                    <div className="mt-2">
                                       <a style={{width:'100%'}} href="/checkout" className="btn btn-primary btn-sm"><i className="mdi mdi-cart-outline"></i> Checkout </a>
                                    </div>
                                 </div> */}
                                    </div>
                                    <div className="short-description">
                                       <h5>
                                          Product Overview
                                          {/* <p className="float-right">Availability: <span className="badge badge-success">In Stock</span></p> */}
                                       </h5>
                                       <div className="mb-2">{parse(products_details.key_feature)}</div>
                                       {this.state.show_description && <div className="mb-0">{parse(products_details.description)}</div>}
                                       {/* <button class="button-2" role="button" onClick={() => this.view_product_description()}>View More</button> */}
                                       {this.state.show_description &&<button class="button-2" role="button" onClick={() => this.setState({ show_description: !this.state.show_description })}>Less More</button>}
                                       {!this.state.show_description &&<button class="button-2" role="button" onClick={() => this.setState({ show_description: !this.state.show_description })}>Product Description <i className='mdi mdi-chevron-down'></i></button>}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </section>
                     <section className="reviews-section">
                        <div className="container">
                           <div className="section-header">
                              <h5 className="heading-design-h5">
                                 Ratings & Reviews
                              </h5>
                           </div>
                           <div className="row">
                              <div className="col-sm-12 col-lg-12">
                                 <hr />
                                 <input type="hidden" ref="ratingValue" className="form-control" id="ratingValue" name="rating" value="1" />
                                 {this.state.rating_status ?
                                    <>
                                       <div id="ratingDetails" style={{ display: this.state.ratingform_status ? 'none' : 'block' }}>
                                          <div className="row">
                                             <div className="col-lg-3 col-sm-12">
                                                <div className="reviews-section-average">
                                                   <h5>Average user rating</h5>
                                                   <h2 className="bold"><span className="harpra-text-color">{(average).toFixed(1)}</span><small>/ 5</small></h2>
                                                   {[...Array(5).keys()].map((item, index) => {
                                                      return (
                                                         <label key={"star" + index} data-count={(average).toFixed() + '-' + item} className={"badge mr-1 " + (item < (average).toFixed() ? 'harpra-text-color' : '')} aria-label="Left Align">
                                                            <span className="mdi mdi-star" aria-hidden="true"></span>
                                                         </label>
                                                      )
                                                   })}
                                                </div>
                                             </div>
                                             <div className="col-lg-3 col-sm-12">
                                                <div className="reviews-section-rating mb18">
                                                   <h5>Rating breakdown</h5>
                                                   <div className="rating-list">
                                                      <div className="rating-list-left">
                                                         <div>5 <span className="mdi mdi-star harpra-text-color"></span></div>
                                                      </div>
                                                      <div className="rating-list-center">
                                                         <div className="progress">
                                                            <div className="progress-bar bg-success" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="5" style={{ width: this.state.fiveStarRatingPercent }}>
                                                               <span className="sr-only">{this.state.fiveStarRatingPercent}</span>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div className="rating-list-right text-success">{this.state.fiveStarRating}</div>
                                                   </div>
                                                   <div className="rating-list">
                                                      <div className="rating-list-left">
                                                         <div>4 <span className="mdi mdi-star harpra-text-color"></span></div>
                                                      </div>
                                                      <div className="rating-list-center">
                                                         <div className="progress">
                                                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow="4" aria-valuemin="0" aria-valuemax="5" style={{ width: this.state.fourStarRatingPercent }}>
                                                               <span className="sr-only">{this.state.fourStarRatingPercent}</span>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div className="rating-list-right text-primary">{this.state.fourStarRating}</div>
                                                   </div>
                                                   <div className="rating-list">
                                                      <div className="rating-list-left">
                                                         <div>3 <span className="mdi mdi-star harpra-text-color"></span></div>
                                                      </div>
                                                      <div className="rating-list-center">
                                                         <div className="progress">
                                                            <div className="progress-bar bg-info" role="progressbar" aria-valuenow="3" aria-valuemin="0" aria-valuemax="5" style={{ width: this.state.threeStarRatingPercent }}>
                                                               <span className="sr-only">{this.state.threeStarRatingPercent}</span>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div className="rating-list-right text-info">{this.state.threeStarRating}</div>
                                                   </div>
                                                   <div className="rating-list">
                                                      <div className="rating-list-left">
                                                         <div>2 <span className="mdi mdi-star harpra-text-color"></span></div>
                                                      </div>
                                                      <div className="rating-list-center">
                                                         <div className="progress">
                                                            <div className="progress-bar bg-warning" role="progressbar" aria-valuenow="2" aria-valuemin="0" aria-valuemax="5" style={{ width: this.state.twoStarRatingPercent }}>
                                                               <span className="sr-only">{this.state.twoStarRatingPercent}</span>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div className="rating-list-right text-warning">{this.state.twoStarRating}</div>
                                                   </div>
                                                   <div className="rating-list">
                                                      <div className="rating-list-left">
                                                         <div>1 <span className="mdi mdi-star harpra-text-color"></span></div>
                                                      </div>
                                                      <div className="rating-list-center">
                                                         <div className="progress">
                                                            <div className="progress-bar bg-danger" role="progressbar" aria-valuenow="1" aria-valuemin="0" aria-valuemax="5" style={{ width: this.state.oneStarRatingPercent }}>
                                                               <span className="sr-only">{this.state.oneStarRatingPercent}</span>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div className="rating-list-right text-danger">{this.state.oneStarRating}</div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="col-lg-4 col-sm-12">
                                                <div className="reviews-section-add-review">
                                                   <h5>Your Review</h5>
                                                   {this.state.myrating_count == 0 ?
                                                      <>
                                                         <button type="button" onClick={() => this.rate_this_product(true)} className="btn btn-sm btn-outline-info harpra-text-color">Add Your Review</button>
                                                         <Modal size="md" fade={false} isOpen={this.state.loginMdl_status} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
                                                            <ModalHeader close={<button className="close" onClick={() => this.setState({ loginMdl_status: false })}>X</button>}>
                                                               <CardText className="modal-title">
                                                                  Login to rate this product
                                                               </CardText>
                                                            </ModalHeader>
                                                            <ModalBody>
                                                               <div className="row text-tenter">
                                                                  <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                                                                     <div className="login-modal-right">
                                                                        <fieldset className="form-group">
                                                                           <label>Enter Mobile No/Email Id</label>
                                                                           <input type="text" className="form-control" name="user_mobile" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.user_mobile || ''} placeholder="Mobile No./Email" />
                                                                        </fieldset>
                                                                        <fieldset className="form-group">
                                                                           <label>Enter Password</label>
                                                                           <input type="password" className="form-control" name="user_pass" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.user_pass || ''} minLength="6" placeholder="Password" />
                                                                        </fieldset>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </ModalBody>
                                                            <ModalFooter>
                                                               <button type="button" disabled={this.state.loading} onClick={() => this.rating_login()} className="btn btn-primary btn-sm"> {this.state.loading && <i className="fa fa-refresh fa-spin" aria-hidden="true"></i>} Login</button>
                                                            </ModalFooter>
                                                         </Modal>
                                                      </>
                                                      :
                                                      <>
                                                         <div className="review-block-rate">
                                                            {[...Array(5).keys()].map((item, index) => {
                                                               return (
                                                                  <label key={"star" + index + item['ratingId'] + item['userId']} className={"badge " + (item < (this.state.myrating_count).toFixed() ? 'harpra-text-color' : '')} aria-label="Left Align">
                                                                     <span className="mdi mdi-star" aria-hidden="true"></span>
                                                                  </label>
                                                               )
                                                            })}
                                                         </div>
                                                         <h5>Your Comment
                                                            <span className="rating ml-1">
                                                               <i className="mdi mdi-pencil harpra-text-color cursor" onClick={() => this.rate_this_product(true)} aria-hidden="true"></i>
                                                            </span>
                                                         </h5>
                                                         <div className="review-block-description">{this.state.comments}</div>
                                                         <div className="mt-2">
                                                            {helpfullImages ?
                                                               <div className="row">
                                                                  {helpfullImages.map((item, index) => {
                                                                     return (
                                                                        <div className="col-sm-2 text-center mt-2" key={"n_i" + index}>
                                                                           <FadeIn item_height={50} duration={50} easing={'ease-out'}>
                                                                              {onload => (
                                                                                 <img alt={"Item Image"} onError={(e) => { e.target.onerror = null; e.target.src = '' }} title="click" src={item.image} onLoad={onload} style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '150px', borderRadius: '5px' }} />
                                                                              )}
                                                                           </FadeIn>
                                                                        </div>
                                                                     )
                                                                  })}
                                                               </div>
                                                               : <></>}
                                                         </div>
                                                      </>}
                                                </div>
                                             </div>
                                          </div>
                                          {rating_list.length > 0 ?
                                             <div className="row">
                                                <div className="col-sm-12">
                                                   <div className="widget reviews-section-comment mt-2">
                                                      {rating_list.map((item, index) => {
                                                         let helpfullImg = JSON.parse(item.helpfullImages || []);
                                                         return (
                                                            <div key={"rating_" + index}>
                                                               <div className="row">
                                                                  <div className="col-sm-3">
                                                                     {/*  <img src="" className="img-rounded user-pic" /> */}
                                                                     <div className="review-block-name"><i className="mdi mdi-ui-user mr-1"></i><span> {item['muser_name']}</span></div>
                                                                     <div className="review-block-date"><i className="mdi mdi-ui-calendar"></i> {item['created']}</div>
                                                                  </div>
                                                                  <div className="col-sm-9">
                                                                     <div className="review-block-rate stars-rating">
                                                                        {[...Array(5).keys()].map((item, index) => {
                                                                           return (
                                                                              <span key={"star" + index + item['ratingId'] + item['userId']} className="rating mr-1 " aria-label="Left Align">
                                                                                 <i className={"mdi mdi-star " + (item < (average).toFixed() ? 'harpra-text-color' : '')} aria-hidden="true"></i>
                                                                              </span>
                                                                           )
                                                                        })}
                                                                     </div>
                                                                     <div className="review-block-description">{item['comments']}</div>
                                                                     {helpfullImg ?
                                                                        <div className="row">
                                                                           {helpfullImg.map((item, index2) => {
                                                                              return (
                                                                                 <div className="col-sm-3 col-lg-2 text-center mt-2" key={"user_rating_image" + index + index2}>
                                                                                    <FadeIn item_height={50} duration={50} easing={'ease-out'}>
                                                                                       {onload => (
                                                                                          <img alt={item['muser_name']} onError={(e) => { e.target.onerror = null; e.target.src = '' }} title="click" src={item.image} onLoad={onload} style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '60px', borderRadius: '5px' }} />
                                                                                       )}
                                                                                    </FadeIn>
                                                                                 </div>
                                                                              )
                                                                           })}
                                                                        </div>
                                                                        : <></>}
                                                                  </div>
                                                               </div>
                                                               <hr />
                                                            </div>
                                                         )
                                                      })}
                                                   </div>
                                                </div>
                                             </div> : <></>}
                                       </div>
                                       <div id="ratingSection" style={{ display: this.state.ratingform_status ? 'block' : 'none' }}>
                                          <div className="row">
                                             <div className="col-sm-12">
                                                <div className="form-group">
                                                   <h4>Rate this product</h4>
                                                   <label className="rateButton mdi mdi-star" aria-label="Left Align">
                                                   </label>
                                                   <label className="rateButton mdi mdi-star" aria-label="Left Align">
                                                   </label>
                                                   <label className="rateButton mdi mdi-star" aria-label="Left Align">
                                                   </label>
                                                   <label className="rateButton mdi mdi-star" aria-label="Left Align">
                                                   </label>
                                                   <label className="rateButton mdi mdi-star" aria-label="Left Align">
                                                   </label>
                                                </div>
                                                <div className="form-group">
                                                   <label htmlFor="comments">Comment*</label>
                                                   <textarea className="form-control" rows="5" ref="comments" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.comments || ''} name="comments"></textarea>
                                                </div>
                                                <div className="form-group">
                                                   <label className="custom-file">
                                                      <input type="file" id="file2" accept='image/*' onChange={(e) => this.changePriveImage(e.target)} className="custom-file-input" multiple />
                                                      <span className="custom-file-control"></span>
                                                   </label>
                                                </div>
                                                <div className="form-group">
                                                   <div className="mt-2">
                                                      {helpfullImages ?
                                                         <div className="row">
                                                            {helpfullImages.map((item, index) => {
                                                               return (
                                                                  <div className="col-sm-2 text-center mt-2" key={"n_i" + index}>
                                                                     <FadeIn item_height={50} duration={50} easing={'ease-out'}>
                                                                        {onload => (
                                                                           <img alt={"Item Image"} onError={(e) => { e.target.onerror = null; e.target.src = '' }} title="click" src={item.image} onLoad={onload} style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '145px', borderRadius: '5px' }} />
                                                                        )}
                                                                     </FadeIn>
                                                                     <br />
                                                                     <a href='#' onClick={(e) => this.remove_image(e, index)}>Remove</a>
                                                                  </div>
                                                               )
                                                            })}
                                                         </div>
                                                         : <></>}
                                                   </div>
                                                </div>
                                                <div className="form-group">
                                                   <button type="submit" onClick={() => this.save_new_rating()} className="btn btn-success mr-3">Save Review</button>
                                                   <button type="button" onClick={() => this.rate_this_product(false)} className="btn btn-danger">Cancel</button>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </>
                                    : ''}
                              </div>
                           </div>
                        </div>
                     </section>
                  </>
                  :
                  <div className="text-center">
                     {
                        <Oval arialLabel="loading-indicator" height={150} color="#d98688" secondaryColor="white" strokeWidth={5} strokeWidthSecondary={1} wrapperStyle={{ marginTop: "2%", display: "inline-block" }} />
                     }
                  </div>
               }
               {pro_isLoaded == true ?
                  <section className="product-items-slider section-padding">
                     <div className="container-xl">
                        <div className="section-header">
                           <h5 className="heading-design-h5">More like this {/* <span className="badge badge-primary">20% OFF</span> */}
                              {/* <Link className="float-right text-secondary" to="#">View All</Link> */}
                           </h5>
                        </div>
                        <OwlCarousel className="owl-theme owl-carousel owl-carousel-featured" navContainerClass="owl-controls clickable" dots={false} responsive={{ 0: { items: 2 }, 600: { items: 3 }, 1000: { items: 4 } }} responsiveClass={true} navElement="div" nav={true} autoplay={false} rewind={false} loop={true} touchDrag={true} pullDrag={false} lazyLoad={true} mouseDrag={false}>
                           {products.slice(0, 10).map(product => {
                              let price = extract_price_detail(product.price_detail);
                              return (
                                 <div className="item" key={"pro_" + product.id}>
                                    <div className="product">
                                       <Link to={"/product/" + product.product_slug + "/" + product.id + "?variant=" + price[0]['product_price_id']}>
                                          <div className="product-header">
                                             <>
                                                {price[0]['unit_mrp'] != price[0]['unit_price'] ?
                                                   <span className={"badge badge-success offerText_" + product.id}>
                                                      Upto {price[0].disc_type == 'flat' ?
                                                         price[0].unit_discount + ' ₹ ' : price[0].discount_percent + ' % '}
                                                      OFF</span> : <></>}
                                                <FadeIn easing={'ease-out'}>
                                                   {onload => (
                                                      <img className="img-fluid" src={product.img_paths} alt={product.p_name} onLoad={onload} />
                                                   )}
                                                </FadeIn>
                                                {/* <span className="veg text-success mdi mdi-circle"></span> */}
                                             </>
                                          </div>
                                       </Link>
                                       <ProductContaner product={product} price_detail={price} />
                                    </div>
                                 </div>
                              )
                           })}
                        </OwlCarousel>
                     </div>
                  </section>
                  : ''}
            </div>
            {/* <Footer /> */}
         </>
      );
   }
}
export default Product_details;
