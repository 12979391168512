import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Modal, ModalBody, ModalHeader, CardText } from 'reactstrap';
import { fetch_status_string, fetch_status_color, fetch_payment_mode_string } from '../components/General_data.js';
import { get_order_details } from '../components/Fetch_Order.js';
import Moment from 'react-moment';
class OrderTrackMdl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      data_status: false,
      model_status: false,
      loading: false,
      order_details: [],
      tracking_list: [],
      shipment: [],
    };
  }
  log_order_details(order_id, inoice_number) {
    get_order_details(order_id, inoice_number)
      .then(
        (resolve) => {
          if (resolve['status'] == 1) {
            this.setState({
              order_details: resolve['order'],
              tracking_list: resolve.tracking_list || [],
              shipment: resolve.shipment || [],
              model_status: true,
              isLoading: false
            });
          }
        },
        (reject) => {
          console.log(reject)
        }
      )
  }

  componentWillReceiveProps(nextProps) {
    let order_ids = nextProps.order_id;
    let inoice_number = nextProps.inoice_number;
    let model_status = nextProps.model_status;
    if (order_ids && inoice_number && model_status === true) {
      this.log_order_details(order_ids, inoice_number);
    }
    else {
      this.setState({ model_status: false, data_status: false })
    }
  }
  componentDidMount() {
  }

  render() {
    const { isLoading, data_status, order_details, tracking_list } = this.state;
    return (
      <>
        <NotificationContainer />
        <Modal size="lg" fade={false} isOpen={this.state.model_status} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.props.parentCallback()}>X</button>}>
            <CardText className="modal-title">
              Order Tracking {order_details.invoiceNumber || ''}
            </CardText>
          </ModalHeader>
          <ModalBody className="px-2 py-2">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    {isLoading == true ?
                      'Please Wait'
                      :
                      <div className="row">
                        <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                          <div className="card">
                            <div className='card-body'>
                              <div className='row'>
                                <div className="col-sm-6">
                                  <div>
                                    <label className="control-label">Order No :</label>
                                    <span>{order_details.invoiceNumber}</span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div>
                                    <label className="control-label">Order Date :</label>
                                    <span><Moment format="Do MMM YYYY">{order_details.order_date}</Moment></span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div>
                                    <label className="control-label">Order Status :</label>
                                    <span className={"badge " + fetch_status_color(order_details.order_status)}>{fetch_status_string(order_details.order_status)}</span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div>
                                    <label className="control-label">Total Amount :</label>
                                    <span>Rs.{order_details.final_amount}</span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div>
                                    <label className="control-label">Courier Name :</label>
                                    <span>{this.state.shipment.courier_name || '---'}</span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div>
                                    <label className="control-label">AWB Code :</label>
                                    <span>{this.state.shipment.awb_code || '---'}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12 mt-2">
                          <div className="table-responsive">
                            <table className="table mb-0 text-center">
                              <thead>
                                <tr>
                                  <th>Sr No</th>
                                  <th>Date</th>
                                  <th>Status</th>
                                  <th>Description</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  tracking_list.map((list, index) => {
                                    return (
                                      <tr key={"row" + index}>
                                        <td>{index + 1}</td>
                                        <td><Moment format="Do MMM YYYY hh:mm A">{list.created_at}</Moment></td>
                                        <td>{list.order_status_text}</td>
                                        <td>{list.description}</td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal >
      </>
    );
  }
}
export default OrderTrackMdl;
