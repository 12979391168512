import React, { Component } from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
class Notfound extends Component {

    /* componentDidMount() {
        navigator.geolocation.getCurrentPosition(function(position) {
          console.log("Latitude is :", position.coords.latitude);
          console.log("Longitude is :", position.coords.longitude);
        });
      } */
    render() {
        return (
            <div>
                {/* <Header /> */}
                <div className="page-container">
                    <section className="account-page section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="text-center">
                                        <div className="error-template">
                                            <h1>
                                                Oops!</h1>
                                            <h2>
                                                404 Not Found</h2>
                                            <div className="error-details">
                                                Sorry, an error has occured, Requested page not found!
                                            </div>
                                            <div className="error-actions">
                                                <a href="/home" className="btn btn-primary btn-lg">
                                                    <span className="glyphicon glyphicon-home" />
                                                    Take Me Home </a> &nbsp;<a href="/contact" className="btn btn-info btn-lg"><span className="glyphicon glyphicon-envelope" /> Contact Support </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
export default Notfound;
